<script>
import Chat from '../components/Chat.vue';

export default {
  components: { Chat },
};
</script>

<template>
  <div id="chat">
    <Chat />
  </div>

</template>
