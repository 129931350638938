<script>
export default {
  data() {
    return {
      titleText: "В чому проблема",
      sections: [
        {
          text: "Декларативність виконання законодавства в сфері протидії та запобігання домашньому насильству",
          description: 'Попри наявність мережі центрів і організацій для протидії домашньому насильству, кількість звернень зростає. Також спостерігається тенденція повторних звернень, що свідчить про нерозв\'язаність проблеми та рецидиви.',
          backgroundColor: "bg-white",
          textColor: "text-gray-500",
        },
        {
          text: "",
          alt: "Підтримка",
          backgroundColor: "bg-[#b20000]",
          textColor: "text-[#fbfbfb]",
          src: require("@/assets/about/mutch-1.svg"),
        },
        {
          text: "Відсутність реальної статистики вчинення домашнього насильства",
          description: 'Статистика насильства зростає, проте постраждалі рідко звертаються до поліції через недовіру та відсутність реальної допомоги. Останнім часом вони все частіше звертаються до громадських організацій.',
          backgroundColor: "bg-white",
          textColor: "text-gray-500",
        },
        {
          text: "",
          alt: "Підтримка",
          backgroundColor: "bg-[#b20000]",
          textColor: "text-[#fbfbfb]",
          src: require("@/assets/about/mutch-2.svg"),
        },
        {
          text: "Недостатня кількість притулків для забезпечення потреб області",
          description: 'В області є 2 незаповнені притулки. В Україні планується створення хоча б одного притулку в кожному обласному центрі та великих містах, з розрахунком одне місце на 30-35 тис. населення. Мінімальна місткість — 6 місць, максимальна — 20, оптимальна — 12-15 осіб.',
          backgroundColor: "bg-white",
          textColor: "text-gray-500",
        },
        {
          text: "",
          alt: "Менталітет та стереотипи",
          backgroundColor: "bg-[#b20000]",
          textColor: "text-[#fbfbfb]",
          src: require("@/assets/about/mutch-3.svg"),
        },
        {
          text: `Логістичні та адміністративні перешкоди для ефективного функціонування притулків в області`,
          description: 'В області необхідно створити мережу притулків, зручно розташованих у кожному районі, з максимальним часом доїзду 30-40 хвилин.',
          backgroundColor: "bg-white",
          textColor: "text-gray-500",
        },
        {
          text: "",
          alt: "Не мовчіть про домашнє насильство",
          backgroundColor: "bg-[#b20000]",
          textColor: "text-[#fbfbfb]",
          src: require("@/assets/about/mutch-4.svg"),
        },
        {
          text: "Подолання наслідків замість викорінення причин",
          description: 'Центри реабілітації допомагають дітям без батьків, проте вони часто переймають насильницькі моделі поведінки від батьків. Потрібно зосередитися на ефективній протидії домашньому насильству, щоб не формувати шкідливі патерни у таких дітей.',
          backgroundColor: "bg-white",
          textColor: "text-gray-500",
        },
      ],
      boxStyles: "flex-grow-0 flex-shrink-0 h-[394px] md:h-[434px] relative overflow-hidden",
      textContainerStyles: "flex flex-col justify-center items-center w-full h-full absolute gap-1 md:p-6",
      textStyles: "text-xl md:text-4xl font-bold text-left",
    };
  },
};
</script>

<template>
  <div
    class="flex flex-col justify-start items-center gap-2 md:gap-8"
    id="problems"
  >
    <div class="flex flex-col justify-start items-center self-stretch relative gap-2">
      <p class="text-[40px] font-bold text-center text-[#36393f]">
        {{ titleText }}
      </p>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 justify-start items-start flex-grow-0 flex-shrink-0  relative md:gap-10 md:px-[50px]">
      <div
        v-for="(section, index) in sections"
        :key="index"
        :class="[boxStyles, section.backgroundColor]"
        class="section"
      >
        <div
          v-if="section.backgroundColor === 'bg-white'"
          :class="textContainerStyles"
        >
          <p
            :class="[textStyles, section.textColor]"
            class="title"
          >
            {{ section.text }}
          </p>
          <p class="hover md:opacity-0 md:hidden py-4">{{ section.description }}</p>
        </div>

        <p
          v-else
          :class="[textStyles, section.textColor]"
        >
          <img
            v-if="section.src"
            :src="section.src"
            height="434"
            width="434"
            :alt="section.text"
          >
          <span
            v-for="(line, lineIndex) in section.text.split(' ')"
            :key="lineIndex"
          >{{ line }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<style>
.section:hover {
  cursor: pointer;
  transition: all 0.2s ease;
  /* transform: scale(1.01); */
  border-radius: 3px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.section:hover .title {
  font-size: 18px;
  line-height: 1.5rem;
}

.section:hover .hover {
  display: block;
  /* padding: 10px 0; */
  opacity: 1;
}
</style>
