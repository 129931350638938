
<script >
import Capitalize from '../../lib/capitalize'
import Communities from '../registration/community.vue';
// import Messanger from '../appeal/_messanger.vue';
import Assistance from '../appeal/_assistance.vue';
import DateTime from '../appeal/_datatime.vue';
import ModalInfo from '../ModalInfo.vue';
import CildrenAges from '../appeal/components/_children_ages.vue';

export default {
  components: { Communities, Assistance, ModalInfo, CildrenAges, DateTime },
  data() {
    return {
      name: '',
      last_name: '',
      community_id: '',
      region_id: '',
      regions: [],
      phone: '',
      communiction: '',
      assistans_type: '',
      description: '',
      children_help: false,
      children_count: 0,
      children_ages: [],
      // date_communication: '',
      creator: '',
      errNumberPhone: false,
      status_id: 1,
      message: '',
      logo: require('@/assets/error.svg'),
      event: false,
      isAnonymous: false,
      isCallCenter: true
    }
  },
  methods: {
    async submit() {
      // Создаем объект данных
      this.data = {
        creator: this.creator,
        date_communication: this.date_communication,
        name: this.name,
        last_name: this.last_name,
        community_id: this.communityId,
        region_id: this.region_id,
        phone: this.formatPhoneNumber(this.phone),
        communiction: this.communiction || 'Телефон',
        assistans_type: this.assistans_type,
        description: this.description,
        children_help: this.children_help,
        children_count: this.children_count,
        children_ages: this.children_help ? this.children_ages : [],
        status_id: this.status_id,
      };
      console.log(this.data);

      // Маппинг полей для отображения

      const fieldNamesMap = {
        creator: 'ПІБ працівника кол центру',
        date_communication: 'Дата/час',
        name: 'Ім\'я',
        last_name: 'Прізвище',
        community_id: 'Територіальна громада',
        phone: 'Телефон',
        // communiction: 'Спосіб зв\'язку',
        assistans_type: 'Запит на реагування',
        description: 'Опис проблеми',
        children_count: 'Кількість дітей',
        children_ages: 'Вік дітей',
      };

      if (this.isAnonymous) {
        this.data.name = 'Анонімний';
        this.data.creator = 'Анонімний';
        delete this.data.last_name;
        delete fieldNamesMap.name;
        delete fieldNamesMap.last_name;
      }
      if (!this.children_help) {
        delete this.data.children_count;
        delete this.data.children_ages;
        delete fieldNamesMap.children_count;
        delete fieldNamesMap.children_ages;
      }
      if (!this.isCallCenter) {
        delete fieldNamesMap.date_communication;
        delete fieldNamesMap.creator;
      }
      if (this.isCallCenter && this.isAnonymous) {
        this.data.creator = this.creator;
      }

      // Проверка на заполненность полей
      const missingFields = Object.entries(this.data)
        .filter(([key, value]) => (value === undefined || value === '') && fieldNamesMap[key] !== undefined)
        .map(([key]) => fieldNamesMap[key]);

      if (missingFields.length) {
        this.message = `<span class="red">Помилка.</span> Не заповнені наступні поля:<br> ${missingFields.join(', ')}.`;
        this.logo = require('@/assets/error.svg');
        this.showModal();
        return false;
      }

      if (this.children_count < 0 || this.children_count > 15) {
        this.message = '<span class="red">Помилка.</span> Кількість дітей від 0 до 15';
        this.logo = require('@/assets/error.svg');
        this.showModal();
        return;
      }

      // Проверка номера телефона
      this.onValidatePhoneNumber(this.phone);
      if (this.errNumberPhone) {
        this.message = '<span class="red">Помилка.</span> Невірний номер телефону';
        this.logo = require('@/assets/error.svg');
        this.showModal();
        return;
      }

      // Отправка данных
      await this.addAppeal(this.data);
    },
    async addAppeal(data) {
      const response = await fetch(`/api/appeals`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      try {
        if (response.ok) {
          this.appeal = await response.json();
          this.logo = require('@/assets/check.svg');
          this.message = 'Ваше повідомлення надіслано\n';
          this.event = true;
          this.showModal();
        } else {
          this.appeal = await response.json();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getRegions() {
      const response = await fetch(`/api/communities/regions/active`);
      try {
        if (response.ok) {
          this.regions = await response.json();
        }
      } catch (err) {
        this.message = `${err}`;
        this.logo = require('@/assets/error.svg');
        this.showModal();
      }
    },
    filterInput(input) {
      let filteredInput = '';
      const regex = new RegExp(/^[а-яА-ЯёЁіїґєЄ'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredInput += char;
        }
      }
      this.name = Capitalize(filteredInput);
    },

    filterName(input, keyName) {
      let filteredName = '';
      const regex = new RegExp(/^[а-яА-ЯёЁіїґєЄ'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredName += char;
        }
      }
      this[keyName] = filteredName.toUpperCase();
    },

    filterText(input) {
      let filteredText = '';
      const regex = new RegExp(/^[!?.,а-яА-ЯёЁіїґє'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredText += char;
        }
      }
      this.description = Capitalize(filteredText);
    },

    formatPhoneNumber(phone) {

      const cleanedPhone = phone.replace(/[^\d]/g, '');
      return cleanedPhone;
    },

    onValidatePhoneNumber(phoneNumber) {
      // Обновленное регулярное выражение для проверки полного формата
      // const regex = /^38\(0[3-9]\d\)\d{3}-\d{2}-\d{2}$/;
      const regex = /^38\((039|067|068|096|097|098|050|066|095|099|063|073|077|093|091|092|089|094)\)\d{3}-\d{2}-\d{2}$/;


      // Проверяем строгое соответствие формату
      if (regex.test(phoneNumber)) {
        this.errNumberPhone = false; // Номер корректный
      } else {
        this.errNumberPhone = true; // Номер некорректный
      }

      return this.errNumberPhone;
    },

    onMessangerSelected(value) {

      this.communiction = value;
    },
    onAssistanceSelected(value) {

      this.assistans_type = value;
    },
    onCommunitySelected(value) {

      this.region_id = value;
    },
    onAgeChecked(value) {
      this.children_ages = value;
    },
    onDateTimeSelected(value) {

      this.date_communication = value;
    },
    showModal() {
      this.$refs.modalInfo.onOpen();
    },
    hideModal(event) {
      if (event) {
        this.clearData();
      }
    },
    clearForm() {
      this.name = '';
      this.last_name = '';
      this.phone = '';
      this.communication = null;
      this.assistance_type = null;
      this.description = '';
      this.children_help = false;
      this.children_count = false;
      this.date_communication = null;
    },
    clearData() {
      window.location.reload();
    },
    hideListCommunity() {
      this.$refs.community.onClose();
    },
    autoResize() {
      const textarea = this.$refs.textarea;
      textarea.style.height = "auto"; // Сбросить высоту, чтобы пересчитать ее
      textarea.style.height = textarea.scrollHeight + "px"; // Установить новую высоту
    }
  },
  watch: {
  },
  mounted() {
    const href = new URL(window.location.href);
    this.getRegions();
    if (href.pathname === '/call-center/') this.isCallCenter = true;
    else this.isCallCenter = false;
  }
}
</script>

<template>
  <div class="main px-4 py-12 lg:px-8">
    <div
      class="absolute inset-x-0 top-[-10rem] -z-10 overflow-hidden blur-3xl sm:top-[-20rem]"
      aria-hidden="true"
    >
    </div>
    <div class="warning mx-auto max-w-2xl">
      <div class="flex items-center">
        <router-link to="/">
          <img
            src="../../assets/about/logo.svg"
            alt="ENOUGH"
            height="56"
            width="75"
          >
        </router-link>
        <h2 class="ml-3 md:ml-8 self-center text-3xl font-bold text-[#6B7280] sm:text-4xl tracking-widest">Конфіденційна допомога</h2>
      </div>

      <p class="mt-8 leading-8 text-justify"><span class="tracking-wide font-bold ">ВАЖЛИВО!</span> Перед заповненням форми, прочитайте коротку інформацію до неї:</p>
      <ul class="text-justify">
        <li class=" pt-4">1. Ми пропонуємо всебічну <span class="flex-grow-0 flex-shrink-0 text-base font-bold text-left text-gray-500">АНОНІМНУ</span> допомогу онлайн у разі термінової необхідності. Ім'я ви можете змінити. Онлайн-зустріч буде відбуватися без ввімкненої камери, або шляхом телефонної розмови у вайбер/вотсап/телеграм або ZOOM.</li>
        <li class=" pt-4">2. Телефон ГО Інша Жінка<a
            class="px-2 font-bold text-[#B20000]"
            href="tel:+380501497063"
          >+38(050)149-70-63</a>(можна писати на<a
            class="pl-2 font-bold text-[#B20000]"
            href="viber://chat?number=380501497063"
            title="Viber"
          >Вайбер</a>). У нас поки що немає цілодобової лінії підтримки, тому, якщо вам не відповіли на дзвінок, ми обов'язково зв'яжемося з вами, очікуйте.</li>
        <li class=" py-4">3. Наразі ми тестуємо цю платформу для того, аби відповідні служби реагували на ваше звернення швидше, а ми контролювали, наскільки ефективною є допомога вам. Тому все, що ви вкажете у формі, буде надіслано відповідним фахівцям, які будуть зв'язуватись з вами додатково. Просимо відповідати на дзвінки з незнайомих номерів, у разі необхідності.</li>
      </ul>
    </div>
    <form
      action="#"
      method="POST"
      class="mx-auto max-w-2xl mt-4 md:mt-0"
    >
      <div class="grid grid-cols-1 gap-x-8 gap-y-1 sm:grid-cols-2">
        <div
          v-if="isCallCenter"
          class="col-span-2 md:col-span-1 relative z-0 w-full mt-4 mb-5 group"
        >
          <input
            type="text"
            name="creator"
            v-model="creator"
            @keyup="filterName(creator, 'creator')"
            id="floating_first_name"
            class="block py-2.5 pl-2 w-full opacity-80 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
            placeholder=" "
          />
          <label
            for="creator"
            class="peer-focus:font-medium opacity-70 text-sm absolute duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#B20000]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          ></label>
        </div>

        <div
          v-if="isCallCenter"
          class="col-span-2 md:col-span-1 relative z-0 w-full mt-4 mb-5 group"
        >
          <DateTime
            @selected="onDateTimeSelected"
            placeholder=""
          />
        </div>
        <div class="col-span-2 md:col-span-1 relative z-0 w-full mt-4 mb-5 group">
          <input
            type="text"
            v-model="name"
            @keyup="filterName(name, 'name')"
            id="name"
            class="block py-2.5 pl-2 w-full opacity-80 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
            placeholder=" "
            :required="isAnonymous"
            :disabled="isAnonymous"
          />
          <label
            for="name"
            class="peer-focus:font-medium opacity-70 text-sm absolute duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#B20000]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Ім`я*</label>
        </div>
        <div class="col-span-2 md:col-span-1 relative z-0 w-full mt-4 mb-5 group">
          <input
            type="text"
            id="last_name"
            v-model="last_name"
            @keyup="filterName(last_name, 'last_name')"
            class="block py-2.5 pl-2 w-full opacity-80 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
            placeholder=" "
            :required="isAnonymous"
            :disabled="isAnonymous"
          />
          <label
            for="last_name"
            class="peer-focus:font-medium opacity-70 text-sm absolute duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#B20000]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Прізвище*</label>
        </div>
        <div class="col-span-2 mb-4">
          <div class="flex items-center gap-3">
            <input
              id="isAnonim"
              type="checkbox"
              @change="isAnonymous = !isAnonymous;name=''; last_name = ''"
              class=" cursor-pointer h-5 w-5 rounded border-[#B20000] text-[#B20000] focus:ring-white"
            >
            <label
              for="isAnonim"
              class=" cursor-pointer block leading-6 text-sm opacity-60 text-[16px]"
            >Анонімне звернення</label>
          </div>
        </div>

        <div class="col-span-2 md:col-span-2 relative z-0 w-full mt-4 mb-5 group">
          <select
            id="region"
            v-if="!region_id"
            class="block py-2.5 pl-2 w-full text-[14px] opacity-80 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
          >
            <option selected>Область</option>
            <option
              v-for="{ region, region_id } in regions"
              :key="region_id"
              :value="region_id"
            >{{ region }}</option>
          </select>
          <select
            id="region"
            v-else
            v-model="region_id"
            class="block py-2.5 pl-2 w-full opacity-80 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
          >
            <option selected>Область</option>
            <option
              v-for="{ region, region_id } in regions"
              :key="region_id"
              :value="region_id"
            >{{ region }}</option>
          </select>
          <label
            for="region"
            class="peer-focus:font-medium opacity-70 text-sm absolute duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#B20000]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Область</label>
        </div>

        <Communities
          v-model="communityId"
          @selected="onCommunitySelected"
        />

        <div class="col-span-2 relative z-0 w-full mb-5 group">
          <input
            type="tel"
            v-mask="'38(###)###-##-##'"
            placeholder=" "
            required
            v-model="phone"
            @blur="onValidatePhoneNumber(phone)"
            id="floating_telephone"
            :class="errNumberPhone ?'errNumberPhone':''"
            class="block py-2.5 pl-2 w-full opacity-80 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
          />
          <p
            v-if="errNumberPhone"
            class="errNumberPhone text-[12px] transition-all duration-200"
          >Помилка в наборі телефона*</p>
          <label
            for="floating_telephone"
            class="peer-focus:font-medium opacity-70 text-sm absolute duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#B20000] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Телефон*</label>
        </div>

        <Assistance
          @checked="onAssistanceSelected"
          title="Вибір запиту на реагування"
        />

        <div class="col-span-2 mt-8">
          <legend class="block">
            <div class="after:content-['*'] after:ml-0.5 after:text-[#B20000] block opacity-70">
              Чи стосується звернення/інцидент неповнолітньої дитини?
            </div>
          </legend>
          <div class="border-t-2 border-gray-300 px-0 py-1.5 mt-2 bg-white">
            <div class="flex items-center gap-3 mb-4">
              <input
                id="no"
                name="children_help"
                v-model="children_help"
                type="radio"
                :value="false"
                class=" cursor-pointer h-5 w-5 rounded border-[#B20000] text-[#B20000] focus:ring-white"
              >
              <label
                for="no"
                class=" cursor-pointer block leading-6 text-sm"
              >Ні</label>
            </div>
            <div class="flex items-center gap-3 mb-4">
              <input
                id="yes"
                name="children_help"
                v-model="children_help"
                type="radio"
                :value="true"
                class="h-5 w-5 cursor-pointer  rounded border-[#B20000] text-[#B20000] focus:ring-white"
              >
              <label
                for="yes"
                class="cursor-pointer block leading-6 text-sm"
              >Так</label>
            </div>
          </div>
          <div v-if="children_help">
            <div class="col-span-1 relative z-0 w-full mt-4 mb-5 group">
              <input
                type="number"
                min="0"
                max="15"
                v-model="children_count"
                id="children_count"
                class="block py-2.5 pl-2 w-full opacity-80 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
                placeholder=" "
              />
              <label
                for="children_count"
                class="peer-focus:font-medium opacity-70 text-sm absolute duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#B20000]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >Кількість дітей*</label>
            </div>
            <CildrenAges
              v-model="children_ages"
              @checked="onAgeChecked"
            />
          </div>
        </div>

        <div class="col-span-2">
          <div class="mt-2.5">
            <textarea
              rows="1"
              v-model="description"
              required
              @keyup="filterText(description)"
              @input="autoResize"
              placeholder="Довідкова важлива інформація"
              class="block w-full border-0 border-gray-300 px-0 py-2 leading-6"
              ref="textarea"
            />
            <p class="flex-grow-0 flex-shrink-0 !opacity-60 text-sm text-left text-[#6b7280]">
              (короткий опис проблеми, важливі дані, які треба знати фахівцям, що будуть займатись заявкою)
            </p>

          </div>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-1">
        <div class="mt-8 col-span-1 md:col-span-1 ">
          <button
            type="button"
            @click="clearData"
            class="w-full hover:underline py-2.5 text-left mb-2 opacity-80"
          >Очистити форму</button>
        </div>
        <div class="mt-8 col-span-2 md:col-span-2 ">
          <button
            type="submit"
            @click.prevent="submit"
            class="btn flex w-full justify-center rounded-md p-3 md:p-2 leading-6
"
          >ТИСНИ ТУТ, БО #ДОСИТЬ</button>
        </div>
      </div>
    </form>
    <ModalInfo
      ref="modalInfo"
      :infoText="message"
      @close="hideModal(event)"
      :logo="logo"
    />
  </div>
</template>

<style scoped>
* {
  font-family: 'Liberation Sans', sans-serif;
  font-size: 16px;
  color: #6b7280;
}
.errNumberPhone {
  color: #b20000 !important;
}
p,
ul > li,
h2,
legend > span,
span {
  opacity: 1;
  color: #6b7280;
}
button {
  font-size: 16px;
}
#community {
  opacity: 0.8 !important;
  color: #6b7280 !important;
  font-size: 16px !important;
}
textarea {
  opacity: 0.8;
  color: #6b7280;
  border-bottom-width: 2px !important;
  border-color: rgb(209 213 219/1);
  overflow-y: hidden; /* Скрыть вертикальную полосу прокрутки */
  resize: none; /* Отключить возможность ручного изменения размера */
}
textarea:focus {
  box-shadow: none;
  border-color: #b20000;
}
.main {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}
.warning {
  /* border: 1px solid #d7d3d3; */
  border-radius: 8px;
}
.btn:hover {
  color: #b20000;
  border: 2px solid #b20000;
  background: #fbfbfb;
  transition: all 0.5s ease-in-out;
}
.btn {
  color: #fbfbfb;
  border: 2px solid #b20000;
  background: #b20000;
  transition: all 0.5s ease-in-out;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>