<script>
import MonthNavigator from "./MonthNavigator.vue";
import { jwtDecode } from 'jwt-decode';
import fetchWithAuth from '@/lib/fetchWithAuth';
import { DateFormate, TimeFormate } from '@/lib/dateTime.js';
import formatPhoneNumber from '@/lib/formatterPhone.js';

export default {
  components: { MonthNavigator },
  data() {
    const token = sessionStorage.getItem('token');
    const decodedToken = jwtDecode(token);
    return {
      urgentAppeals: [],
      expiredAppeals: [],
      userRole: decodedToken.roles[0].name,
      userId: decodedToken.id,
      regionId: '',
      dateRange: {
        start: '',
        end: '',
      },
    };
  },
  methods: {
    onMonthChanged(range) {
      this.dateRange = range;
      const token = sessionStorage.getItem('token');
      const decodedToken = jwtDecode(token);
      this.regionId = decodedToken.regionId;
      if (this.userRole === 'USER') {
        this.getUrgentAppealsHistoryByUser(this.userId, this.dateRange.start, this.dateRange.end);
        this.getExpiredAppealsHistoryByUser(this.userId, this.dateRange.start, this.dateRange.end);
      } else {
        this.getUrgentAppeals(this.dateRange.start, this.dateRange.end);
        this.getExpiredAppeals(this.dateRange.start, this.dateRange.end);
      }

    },
    dateFormate(date) {
      if (date) return DateFormate(date);
    },
    timeFormate(date) {
      if (date) return TimeFormate(date);
    },
    formatPhone(phone) {
      if (phone) return formatPhoneNumber(phone);
    },
    getUrgentAppeals(startDate, endDate) {

      fetchWithAuth(`/api/appeals/urgent/${startDate}/${endDate}`)
        .then(response => response.json())
        .then(data => {
          this.urgentAppeals = data;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    getUrgentAppealsHistoryByUser(userId, startDate, endDate) {

      fetchWithAuth(`/api/appeal-history/urgent/${userId}/${startDate}/${endDate}`)
        .then(response => response.json())
        .then(data => {
          this.urgentAppeals = data;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },

    getExpiredAppeals(startDate, endDate) {

      fetchWithAuth(`/api/appeals/expired/${startDate}/${endDate}`)
        .then(response => response.json())
        .then(data => {
          this.expiredAppeals = data;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },

    getExpiredAppealsHistoryByUser(userId, startDate, endDate) {

      fetchWithAuth(`/api/appeal-history/expired/${userId}/${startDate}/${endDate}`)
        .then(response => response.json())
        .then(data => {
          this.expiredAppeals = data;
          console.log(this.expiredAppeals);

        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },

  },
  async mounted() {

  },
}
</script>
<template>
  <div class="min-h-[60dvh]">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 py-2">
      <div class="col-span-2 md:col-span-1 justify-start items-start">
        <div class="flex justify-between items-center py-2">
          <p class="text-xl font-bold text-left text-[#252525]">Екстренні</p>
        </div>
        <div class="flex flex-col justify-start items-start h-[478px] overflow-auto self-stretch p-8 rounded-lg bg-[#fbfbfb] border border-[#252525]/[0.04]">
          <div
            v-for="item of urgentAppeals"
            :key="item.id"
            class="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 my-1 p-3 rounded-lg bg-neutral-100"
          >
            <div class="flex justify-between items-start self-stretch flex-grow-0 flex-shrink-0">
              <div class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-1">
                <p
                  v-if="userRole === 'USER'"
                  class="self-stretch flex-grow-0 flex-shrink-0 text-xs font-bold text-left text-[#252525]"
                >
                  {{ item.appeal.name }} {{ item.appeal.last_name }}
                </p>
                <p
                  v-else
                  class="self-stretch flex-grow-0 flex-shrink-0 text-xs font-bold text-left text-[#252525]"
                >
                  {{ item.name }} {{ item.last_name }}
                </p>
                <p
                  v-if="userRole === 'USER'"
                  class="self-stretch flex-grow-0 flex-shrink-0 text-[10px] text-left text-[#36f]"
                >
                  {{ formatPhone(item.appeal.phone) }}
                </p>
                <p
                  v-else
                  class="self-stretch flex-grow-0 flex-shrink-0 text-[10px] text-left text-[#36f]"
                >
                  {{ formatPhone(item.phone) }}
                </p>
              </div>
              <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-1">
                <p class="flex-grow-0 flex-shrink-0 text-[10px] text-right text-[#252525]/50">
                  {{ dateFormate(item.createdAt) }}
                </p>
                <div class="self-stretch flex-grow-0 flex-shrink-0 w-[0.5px] bg-[#252525]/50"></div>
                <p class="flex-grow-0 flex-shrink-0 text-[10px] text-right text-[#252525]/50">{{ timeFormate(item.createdAt) }}</p>
              </div>
            </div>
            <p
              v-if="userRole === 'USER'"
              class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#252525]"
            >
              {{ item.AssistanceTypes.description }}
            </p>
            <p
              v-else
              class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#252525]"
            >
              {{ item.assistans_type }}
            </p>
          </div>
        </div>
      </div>

      <div class="col-span-2 md:col-span-1 justify-start items-start">
        <div class="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative py-2">
          <p class="flex-grow-0 flex-shrink-0 text-xl font-bold text-left text-[#252525]">Нагадування</p>
          <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative">
            <MonthNavigator @month-changed="onMonthChanged" />
          </div>
        </div>
        <div class="flex flex-col justify-start h-[478px] overflow-auto items-start self-stretch p-8 rounded-lg bg-[#fbfbfb] border border-[#252525]/[0.04]">
          <div
            v-for="item of expiredAppeals"
            :key="item.id"
            class="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 my-1 p-3 rounded-lg bg-neutral-100"
          >
            <div class="flex justify-between items-start self-stretch flex-grow-0 flex-shrink-0">
              <div class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-1">
                <p
                  v-if="userRole === 'USER'"
                  class="self-stretch flex-grow-0 flex-shrink-0 text-xs font-bold text-left text-[#252525]"
                >
                  {{ item.appeal.name }} {{ item.appeal.last_name }}
                </p>
                <p
                  v-else
                  class="self-stretch flex-grow-0 flex-shrink-0 text-xs font-bold text-left text-[#252525]"
                >
                  {{ item.name }} {{ item.last_name }}
                </p>
                <p
                  v-if="userRole === 'USER'"
                  class="self-stretch flex-grow-0 flex-shrink-0 text-[10px] text-left text-[#36f]"
                >
                  {{ formatPhone(item.appeal.phone) }}
                </p>
                <p
                  v-else
                  class="self-stretch flex-grow-0 flex-shrink-0 text-[10px] text-left text-[#36f]"
                >
                  {{ formatPhone(item.phone) }}
                </p>
              </div>
              <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-1">
                <p class="flex-grow-0 flex-shrink-0 text-[10px] text-right text-[#252525]/50">
                  {{ dateFormate(item.createdAt) }}
                </p>
                <div class="self-stretch flex-grow-0 flex-shrink-0 w-[0.5px] bg-[#252525]/50"></div>
                <p class="flex-grow-0 flex-shrink-0 text-[10px] text-right text-[#252525]/50">{{ timeFormate(item.createdAt) }}</p>
              </div>
            </div>
            <div class="flex justify-between items-center flex-grow-0 flex-shrink-0 w-full">
              <p
                v-if="userRole === 'USER'"
                class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#252525]"
              >
                {{ item.AssistanceTypes.description }}
              </p>
              <p
                v-else
                class="flex-wrap w-[93%] flex-grow-0 flex-shrink-0 text-xs text-left text-[#252525]"
              >
                {{ item.assistans_type }}
              </p>
              <img
                class="pl-2"
                src="../assets/admin-menu/pencil-square.svg"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

,<style scoped>
* {
  font-family: 'Liberation', sans-serif;
}
</style>