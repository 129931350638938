<script>
export default {
  data() {
    return {
      supportButton: {
        link: '/support'
      },
      helpItems: [
        {
          image: require("../../assets/about/help-1.svg"),
          description: "Пропонуємо серії конфіденційних зустрічей для допомоги в громадах області. Тільки практичні поради та знання як діяти правильно в складних життєвих обставинах",
        },
        {
          image: require("../../assets/about/help-2.svg"),
          description: "Співпрацюємо у реалізації спільних ініціатив та проєктів з органами поліції, соціального захисту, громадським сектором, міжнародними фондами, депутатами, місцевою, регіональною владою",
        },
        {
          image: require("../../assets/about/help-3.svg"),
          description: "Здійснюємо громадський контроль за роботою структур, що задіяні в сфері запобігання та протидії насильству та оцінювання показників їх ефективності",
        },
        {
          image: require("../../assets/about/help-4.svg"),
          description: "Ти знаходишся на платформі, де можна отримати психологічну, юридичну та іншу допомогу в один клік абсолютно анонімно",
        },
        {
          image: require("../../assets/about/help-1.svg"),
          description: "Організовуємо групову терапію для постраждалих та безкоштовні послуги дитячого психолога",
        },
        {
          image: require("../../assets/about/help-2.svg"),
          description: "Співпрацюємо з власниками готелів для можливості розміщення постраждалих у кризових ситуаціях",
        },
        {
          image: require("../../assets/about/help-3.svg"),
          description: "Забезпечуємо безкоштовне проживання постраждалих від насильства",
        },
        {
          image: require("../../assets/about/help-4.svg"),
          description: "Проводимо професійну переорієнтацію, допомагаємо з новою роботою для фінансової незалежності постраждалих",
        },
        {
          image: require("../../assets/about/help-1.svg"),
          description: "Допомогаємо одягом, взуттям, побутовими речами у критичних ситуаціях",
        },
        {
          image: require("../../assets/about/help-2.svg"),
          description: "Наш пріоритет — встановлення тренду на нульову толерантність до насильства",
        },
      ]
    };
  }
}
</script>

<template>
  <div
    class="grid grid-cols-1 md:grid-cols-2 gap-20 py-8"
    id="help"
  >
    <div class="m-auto max-w-full p-8">
      <div class="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2">
        <p class="flex-grow-0 flex-shrink-0 text-[40px] font-bold text-center text-[#36393f] pb-4">
          Наша допомога
        </p>
        <p class="flex-grow-0 flex-shrink-0 text-lg text-left text-[#36393f] pb-8">
          На цій платформі ти можеш звернутися за допомогою, отримати безкоштовну консультацію,
          зареєструватися для участі в заходах та разом з нами розпочати змінювати своє життя.
        </p>
      </div>
      <div class="md:max-w-[230px] flex justify-center items-center flex-grow-0">
        <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative">
          <router-link
            to="/help-form"
            class="btn-primery flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-9 py-4 rounded-[100px]"
          >
            Отримати допомогу
          </router-link>
        </div>
      </div>
    </div>
    <div class="effect">
      <div class="effect-content overflow-auto flex flex-col justify-start items-center gap-8 md:max-h-[740px]">
        <div
          v-for="item in helpItems"
          :key="item.description"
          class="flex flex-wrap md:flex-nowrap justify-center md:justify-start items-center flex-grow-0 flex-shrink-0 relative gap-4 p-4"
        >
          <img
            :src="item.image"
            class="rounded-full"
          />
          <p class="flex-grow text-lg text-center md:text-left text-gray-500">
            {{ item.description  }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media (min-width: 768px) and (max-width: 1920px) {
  .effect {
    position: relative;
    overflow: hidden; /* Скрываем градиенты за пределами блока */
  }

  .effect::before,
  .effect::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 120px; /* Высота градиента */
    width: 100%;
    pointer-events: none; /* Отключаем взаимодействие */
    z-index: 10;
  }

  .effect::before {
    top: 0;
    background: linear-gradient(
      to bottom,
      rgba(251, 251, 251, 1),
      rgba(255, 255, 255, 0.3)
    );
  }

  .effect::after {
    bottom: 0;
    background: linear-gradient(
      to top,
      rgba(251, 251, 251, 1),
      rgba(255, 255, 255, 0.3)
    );
  }

  .effect-content {
    position: relative;
    z-index: 1;
    padding: 20px;
    background-color: transparent;
  }
}
</style>