
<script >
import Capitalize from '../../lib/capitalize'
import Communities from '../appeal/_community.vue';
// import Messanger from '../appeal/components/_messanger.vue';
import Assistance from '../appeal/_assistance.vue';
import CildrenAges from '../appeal/components/_children_ages.vue';
import ModalInfoVue from '../ModalInfo.vue';
import refresh from '@/lib/refreshTokin';
import fetchWithAuth from '@/lib/fetchWithAuth';
import { jwtDecode } from 'jwt-decode';

export default {
  components: { Communities, Assistance, ModalInfoVue, CildrenAges },
  data() {
    return {
      id: '',
      appeal: [],
      name: '',
      last_name: '',
      age: null,
      community_id: '',
      regions: [],
      region_id: '',
      phone: '',
      communiction: '',
      assistans_type: '',
      description: '',
      children_help: false,
      children_count: 0,
      children_ages: [],
      date_communication: '',
      creator: '',
      editor: '',
      errNumberPhone: false,
      status_id: 1,
      logo: '',
      message: '',
      disabled: false,
      child_help: '',
      isAnonymous: false,
      isDisabled: true
    }
  },
  methods: {
    async getAppeal(id) {
      try {
        const response = await fetchWithAuth(`/api/appeals/${id}`, {
          method: 'GET',
        });
        if (response.ok) {
          this.appeal = await response.json();
          this.child_help = this.appeal.children_help ? 'Так' : 'Ні';
          this.region_id = this.appeal.community.region_id;
          this.$nextTick(() => {
            this.$refs.communityName.name = this.appeal.community.name;
            this.$refs.communityName.region_id = this.appeal.community.region_id;
            this.$refs.communityName.community_id = this.appeal.community.community_id;
            if (this.$refs.childrenAges) {
              this.$refs.childrenAges.$attrs.value.map(age => {
                this.$refs.childrenAges.children_ages.push(Object.keys(age)[0]);
              })
            }
            this.$refs.assistanceName.assistans_type = this.appeal.assistans_type.split('\n');
          });
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
        location.assign('/login');
      }
    },
    async updateAppeal(id, data) {

      data.editor = jwtDecode(sessionStorage.getItem('token')).name;
      const response = await fetchWithAuth(`/api/appeals/${id}`, {
        method: 'PUT',
        body: JSON.stringify(data),
      });
      try {
        if (response.ok) {
          this.appeal = await response.json();
          this.logo = require('@/assets/check.svg')
          this.message = 'Замовлення збережено';
          this.showModal();
        } else {
          this.appeal = await response.json();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getRegions() {
      const response = await fetch(`/api/communities/regions/active`);
      try {
        if (response.ok) {
          this.regions = await response.json();
        }
      } catch (err) {
        this.message = `${err}`;
        this.logo = require('@/assets/error.svg');
        this.showModal();
      }
    },
    async submit() {

      this.data = {
        name: this.appeal.name,
        last_name: this.appeal.last_name,
        age: this.appeal.age,
        community_id: this.appeal.communityId,
        region_id: this.appeal.region_id,
        phone: this.formatPhoneNumber(this.appeal.phone),
        communiction: this.appeal.communiction,
        assistans_type: this.appeal.assistans_type,
        description: this.appeal.description,
        children_help: this.appeal.children_help,
        children_count: this.appeal.children_count,
        children_ages: this.appeal.children_ages,
        date_communication: this.appeal.date_communication,
        status_id: this.appeal.status_id,
      };

      this.data = Object.fromEntries(
        Object.entries(this.data).filter(([, value]) => value !== '' && value !== null && value !== undefined)
      );

      this.onValidatePhoneNumber(this.appeal.phone);
      if (this.errNumberPhone) {
        this.message = '<span class="red">Помилка.</span> Невірний номер телефону';
        this.logo = require('@/assets/error.svg');
        this.showModal();
        return;
      }
      this.updateAppeal(this.id, this.data);
    },

    filterInput(input) {
      let filteredInput = '';
      const regex = new RegExp(/^[а-яА-ЯёЁіїґєЄ'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredInput += char;
        }
      }
      this.appeal.name = filteredInput.toUpperCase();
    },

    filterLastName(input) {
      let filteredName = '';
      const regex = new RegExp(/^[а-яА-ЯёЁіїґєЄ'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredName += char;
        }
      }
      this.appeal.last_name = filteredName.toUpperCase();
    },

    filterText(input) {
      let filteredText = '';
      const regex = new RegExp(/^[!?.,а-яА-ЯёЁіїґє'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredText += char;
        }
      }
      this.appeal.description = Capitalize(filteredText);
    },

    formatPhoneNumber(phone) {

      const cleanedPhone = phone.replace(/[^\d]/g, '');
      return cleanedPhone;
    },

    onValidatePhoneNumber(phoneNumber) {
      // Обновленное регулярное выражение для проверки полного формата
      // const regex = /^38\(0[3-9]\d\)\d{3}-\d{2}-\d{2}$/;
      const regex = /^38\((039|067|068|096|097|098|050|066|095|099|063|073|077|093|091|092|089|094)\)\d{3}-\d{2}-\d{2}$/;

      // Проверяем строгое соответствие формату
      if (regex.test(phoneNumber)) {
        this.errNumberPhone = false; // Номер корректный
      } else {
        this.errNumberPhone = true; // Номер некорректный
      }

      return this.errNumberPhone;
    },

    onValidateAge(age) {
      if (age < 18 || age > 90) {
        this.age = null;
        this.$refs.age.classList.add('err');
      } else {
        this.age = age;
        this.$refs.age.classList.remove('err');
      }
    },
    onAssistanceSelected(value) {

      this.appeal.assistans_type = value;
    },
    onCommunitySelected(region_id, name, adminCenter, region,) {
      this.appeal.region_id = region_id;
      this.name = name;
      this.adminCenter = adminCenter;
      this.region = region;
    },
    onDateTimeSelected(value) {

      this.appeal.date_communication = value;
    },
    showModal() {
      this.$refs.modalInfo.onOpen();
    },
    hideModal() {
      if (this.errNumberPhone) return;
      this.$router.push('/admin/appeals');
    },
    hideListCommunity() {
      this.$refs.community.onClose();
    },
    onAgeChecked(value) {
      console.log(value);
      this.appeal.children_ages = value;
    },
    handleChildrenHelpChange() {
      this.appeal.children_help;
      this.child_help = this.appeal.children_help ? 'Так' : 'Ні';
      if (~this.child_help) this.appeal.children_count = 0;
    }
  },
  watch: {
  },
  mounted() {
    refresh().then(newToken => {
      sessionStorage.setItem("token", newToken);
      const segments = window.location.href.split('/');
      this.id = segments.pop();
      this.getAppeal(this.id);
      this.getRegions()
    })

  }
}
</script>

<template>
  <div class="px-0 lg:px-4">
    <ModalInfoVue
      ref="modalInfo"
      :infoText="message"
      :logo="logo"
      @close="hideModal"
    />

    <div class="">
      <form
        action="#"
        method="POST"
        class="mx-auto mt-3"
      >
        <div class="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-3">
          <div class="">
            <label class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Ім'я
              </span>
            </label>
            <div class="mt-2.5">
              <input
                type="text"
                v-model="appeal.name"
                @keyup="filterInput(appeal.name)"
                placeholder="Ім'я"
                required
                :disabled="appeal.status_id !=1 || isAnonymous ? true : false"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label class="block">
              <span class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Призвіще
              </span>
            </label>
            <div class="mt-2.5">
              <input
                ref="last_name"
                type="text"
                v-model="appeal.last_name"
                @keyup="filterLastName(appeal.last_name)"
                placeholder="Призвіще"
                :disabled="appeal.status_id !=1 || isAnonymous? true : false"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label
              for="isAnonim"
              class="block"
            >
              <span class="block text-sm font-medium text-slate-700">
                Анонімне</span></label>
            <div class="mt-2.5">
              <input
                id="isAnonim"
                :checked="(appeal.name !=='Анонімний' || appeal.name !== null) &&  appeal.last_name !== null? false: true"
                type="checkbox"
                @change="isAnonymous = !isAnonymous; appeal.name =''; appeal.last_name = ''"
                class="cursor-pointer rounded h-5 w-5 border-gray-400 focus:none"
                disabled
              >
            </div>
          </div>

          <div class="">
            <label class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Область
              </span>
            </label>
            <div class="mt-2.5">
              <select
                id="region"
                :disabled="isDisabled"
                v-if="!region_id"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
              >
                <option
                  v-for="{ region, region_id } in regions"
                  :key="region_id"
                  :selected="appeal.region_id === region_id"
                  :value="appeal.region_id"
                >{{ region }}</option>
              </select>
              <select
                id="region"
                v-else
                v-model="region_id"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
              >
                <option selected>Область</option>
                <option
                  v-for="{ region, region_id } in regions"
                  :key="region_id"
                  :value="region_id"
                >{{ region }}</option>
              </select>
            </div>
          </div>

          <div class="">
            <Communities
              ref="communityName"
              v-model="appeal.communityId"
              :readonly="appeal.status_id !=1 ? true : false"
              :disabled="isDisabled"
              @selected="onCommunitySelected"
            />
          </div>
          <div class="">
            <label class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Телефон
              </span>
            </label>
            <div class="mt-2.5">
              <input
                v-mask="'38(###)###-##-##'"
                placeholder="Телефон"
                v-model="appeal.phone"
                @blur="onValidatePhoneNumber(appeal.phone)"
                required
                :disabled="appeal.status_id !=1 ? true : false"
                type="tel"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
                :class="errNumberPhone ?'border-2 border-rose-500 text-red-600':''"
              />
              <p
                v-if="errNumberPhone"
                class="text-red-500 text-[12px] transition-all duration-200"
              >Помилка в наборі телефона*</p>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-3">
          <div class="col-span-1 mt-4">
            <legend class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                З чим потрібна допомога
              </span>
            </legend>
            <Assistance
              ref="assistanceName"
              @checked="onAssistanceSelected"
              titleClass="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-black"
              mainClass=" bg-transparent border-none"
              checkClass="custom-checkbox cursor-pointer h-5 w-5 rounded border-gray-400 focus:none"
              :readonly="appeal.status_id !=1 ? true : false"
              :disabled="isDisabled"
            />
          </div>

          <div class="col-span-1 mt-4">
            <legend class="block">
              <div class="after:content-['*'] after:ml-0.5 text-sm after:text-[#B20000] block opacity-70">
                Допомога дітям
              </div>
            </legend>
            <div class="px-0 py-1.5 mt-2">
              <div class="flex items-center gap-3 mb-4">
                <input
                  id="no"
                  name="children_help"
                  v-model="appeal.children_help"
                  type="radio"
                  @change="!appeal.children_help ? [appeal.children_count =0, appeal.children_ages =[]] : appeal.children_count"
                  :value="false"
                  class="cursor-pointer rounded h-5 w-5 border-gray-400 focus:none"
                  :readonly="appeal.status_id !=1 ? true : false"
                  :disabled="isDisabled"
                >
                <label
                  for="no"
                  class=" cursor-pointer block leading-6 text-sm"
                >Ні</label>
              </div>
              <div class="flex items-center gap-3 mb-4">
                <input
                  id="yes"
                  name="children_help"
                  v-model="appeal.children_help"
                  type="radio"
                  :value="true"
                  class="h-5 w-5 cursor-pointer  rounded"
                  :readonly="appeal.status_id !=1 ? true : false"
                  :disabled="isDisabled"
                >
                <label
                  for="yes"
                  class="cursor-pointer block leading-6 text-sm"
                >Так</label>
              </div>
            </div>
            <div v-if="appeal.children_help">
              <div class="col-span-1 relative z-0 w-full mt-4 mb-5 group">
                <input
                  type="number"
                  min="0"
                  max="15"
                  v-model="appeal.children_count"
                  id="children_count"
                  class="block py-2.5 pl-2 w-full  rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400"
                  placeholder="Кількість дітей"
                  :readonly="appeal.status_id !=1 ? true : false"
                  :disabled="appeal.status_id !=1 ? true : false"
                />
                <legend
                  for="children_count"
                  class=" absolute duration-300 transform -translate-y-6 scale-75 top-0.5 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >Кількість дітей*</legend>
              </div>
              <CildrenAges
                ref="childrenAges"
                v-model="appeal.children_ages"
                @checked="onAgeChecked"
                mainClass=" bg-transparent border-none"
                checkClass="custom-checkbox cursor-pointer h-5 w-5 rounded border-gray-400 focus:none"
              />
            </div>
          </div>
          <div class="col-span-1 mt-4">
            <label class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Опис проблеми
              </span>
            </label>
            <div class="mt-2.5">
              <textarea
                rows="12"
                v-model="appeal.description"
                @keyup="filterText(appeal.description)"
                placeholder=""
                :disabled="appeal.status_id !=1 ? true : false"
                :readonly="appeal.status_id !=1 ? true : false"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
      </form>

      <div
        v-if="appeal.status_id ==1"
        class="flex justify-end py-4"
      >
        <button
          class="flex justify-center items-center flex-grow-0 flex-shrink-0 h-12 relative gap-2.5 px-9 py-4 rounded-[100px] bg-[#b20000] font-bold text-center text-[#fbfbfb]"
          @click.prevent="submit"
        >Зберегти</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  font-family: 'Liberation', sans-serif;
}
[type='checkbox']:checked,
[type='radio']:checked {
  background-image: url('../../assets/admin-menu/check-yes.svg');
}

[type='checkbox']:focus,
[type='radio']:focus {
  outline: none;
  box-shadow: none;
  background-color: white;
}
.warning {
  /* border: 1px solid #d7d3d3; */
  padding: 20px 10px;
  border-radius: 8px;
}
</style>