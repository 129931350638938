<script>
// import jwtDecode from 'jwt-decode';
import { mapState } from 'vuex';
import { jwtDecode } from 'jwt-decode'; // Используйте корректный импорт

export default {
  name: 'Sidebar',
  data() {
    return {
      isAdmin: false,
      isUser: false,
      menu: [],
    };
  },
  computed: {
    ...mapState(['sideBarOpen']),
  },
  methods: {
    sideBareStatus() {
      this.$store.commit('toggleSidebar');
    },
    setupMenu() {
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error('Токен не найден');
        return;
      }

      try {
        const { roles } = jwtDecode(token);
        // console.log('Роли из токена:', roles);

        if (!roles || !Array.isArray(roles)) {
          console.error('Роли отсутствуют или имеют некорректный формат');
          return;
        }

        this.isAdmin = roles.some(role => role.name === 'ADMIN');
        this.isUser = roles.some(role => role.name === 'USER');

        const allMenu = [
          { title: 'Статистика', admin: true, src2: require('../assets/admin-menu/house-fill.svg'), src: require('../assets/admin-menu/house.svg'), link: '/admin/home' },
          { title: 'Звернення', admin: true, src2: require('../assets/admin-menu/book-fill.svg'), src: require('../assets/admin-menu/book.svg'), link: '/admin/appeals' },
          { title: 'Звернення', user: true, src2: require('../assets/admin-menu/book-fill.svg'), src: require('../assets/admin-menu/book.svg'), link: '/admin/appeals-history' },
          { title: 'Календар', admin: true, user: true, src2: require('../assets/admin-menu/calendar-fill.svg'), src: require('../assets/admin-menu/calendar.svg'), link: '/admin/calendar' },
          { title: 'Чат', admin: true, user: true, src2: require('../assets/admin-menu/chat-fill.svg'), src: require('../assets/admin-menu/chat.svg'), link: '/admin/chat' },
          { title: 'Реєстр по регіонах', admin: false, user: false, src: '', link: '/admin/appeals/region' },
          { title: 'Громади', admin: false, user: false, src: '', link: '/admin/communities' },
          { title: 'Користувачі', user: true, src2: require('../assets/admin-menu/people-fill.svg'), src: require('../assets/admin-menu/people.svg'), link: '/admin/public-users' },
          { title: 'Користувачі', admin: true, src2: require('../assets/admin-menu/people-fill.svg'), src: require('../assets/admin-menu/people.svg'), link: '/admin/settings/users' },
          { title: 'Сповіщення', admin: true, user: true, src2: require('../assets/admin-menu/bell-fill.svg'), src: require('../assets/admin-menu/bell.svg'), link: '/admin/notification' },
          { title: 'Громади', admin: true, src2: require('../assets/admin-menu/location-fill.svg'), src: require('../assets/admin-menu/location.svg'), link: '/admin/settings/communities-all' },
          { title: 'Logfile', admin: true, src2: require('../assets/admin-menu/gear-fill.svg'), src: require('../assets/admin-menu/gear.svg'), link: '/admin/log' },
        ];

        this.menu = allMenu.filter(
          item => (item.admin && this.isAdmin) || (item.user && this.isUser)
        );

        // console.log(this.isAdmin, this.isUser);

        // console.log('Сформированное меню:', this.menu);
      } catch (error) {
        console.error('Ошибка при декодировании токена:', error);
      }
    },
  },
  mounted() {
    this.setupMenu();
  },
};
</script>

<template>
  <div
    class="md:w-1/3 lg:max-w-48 fixed md:top-0 md:left-0 h-screen lg:block z-50 bg-white w-[100dvw]"
    :class="sideBarOpen ? '' : 'hidden'"
    id="main-nav"
  >
    <div class="flex py-[20px] items-center">
      <img
        class="mx-auto"
        src="../assets/about/logo.svg"
        alt="Enough"
      />
    </div>
    <div class="side overflow-y-auto pl-3 py-3">
      <div
        v-for="item in menu"
        :key="item.link"
        class="menu-item"
        :class="{ 'active': $route.path === item.link }"
        @click="sideBareStatus()"
      >
        <router-link
          v-if="item.admin || item.user"
          :to="item.link"
        >
          <div
            class="w-full flex justify-start my-2 items-center h-10 pl-4 rounded-lg cursor-pointer"
            :class="$route.path === item.link?'active-item-menu':''"
          >
            <img
              v-if="item.src"
              :src="$route.path === item.link ? item.src2 : item.src"
              alt=""
            >
            <span
              class="pl-3"
              :class="$route.path === item.link?'text-[#B20000] bg-transparent':'text-gray-700 md:hover:text-[#B20000]'"
            >{{ item.title }}</span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  font-family: 'Liberation', sans-serif;
}
.side {
  max-height: calc(100dvh - 130px);
}

.active-item-menu {
  background-image: url('../assets/admin-menu/bg-active-menu.png');
  background-position-y: center;
  background-position-x: -5px;
  background-repeat: no-repeat;
  height: 75px;
}
@media (min-width: 315px) and (max-width: 767px) {
  .active-item-menu {
    height: unset;
    background-image: none;
  }
}
</style>