<script>
import refresh from '../../lib/refreshTokin';
import fetchWithAuth from '@/lib/fetchWithAuth';
import { DateFormate, TimeFormate } from '@/lib/dateTime.js'
import formatPhoneNumber from '@/lib/formatterPhone.js';

export default {
  data() {
    return {
      users: [
      ],
      user: {},
      titles: [
        '№',
        'Ім\'я',
        // 'Роль',
        'Телефон',
        'Email',
        'Посада',
        'Функціонал',
        'Область',
        'Актв',
        'Останній вхід'
      ]
    }
  },
  methods: {
    async getUsers() {
      try {
        const response = await fetchWithAuth(`/api/users/all`, {
          method: 'GET'
        });
        if (response.ok) {
          this.users = await response.json();
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
        location.assign('/login');
      }
    },
    async onActivate(id, isActive) {
      const newToken = await refresh();
      sessionStorage.setItem("token", newToken);

      const result = confirm('Ви впевнені що хочите змінити статус користувача?');
      if (!result) {
        this.isActive = !!this.isActive;
        await this.getUsers();
        return;
      }
      try {
        const response = await fetchWithAuth(`/api/users/${id}`, {
          method: 'PUT',
          body: JSON.stringify({ isActive: isActive }),
        });
        if (response.ok) this.appeals = await response.json();
        else console.log(await response.json());

      } catch (err) {
        console.log(err);
      }

    },
    dateFormate(date) {
      if (date) return DateFormate(date);
    },
    timeFormate(date) {
      if (date) return TimeFormate(date);
    },
    formatPhone(phone) {
      if (phone) return formatPhoneNumber(phone);
    },
    async exportExsel() {
      try {
        const response = await fetch('/api/users/csv/export', {
          method: 'GET',
        });

        if (!response.ok) {
          throw new Error('Ошибка при загрузке файла');
        }

        const blob = await response.blob(); // Получаем данные в виде Blob
        const url = window.URL.createObjectURL(blob); // Создаем временный URL

        // Создаем ссылку и симулируем клик
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'users.xlsx'); // Имя файла
        document.body.appendChild(link);
        link.click(); // Запускаем скачивание
        link.remove(); // Удаляем ссылку
      } catch (error) {
        console.error('Ошибка при скачивании файла:', error.message);
      }
    }
  },
  mounted() {
    refresh().then(newToken => {
      sessionStorage.setItem("token", newToken);
      this.getUsers();
    });
  }
}
</script>

<template>
  <div class="min-h-[75dvh] pb-2">
    <div class="flex justify-end iten-center">
      <button
        @click="exportExsel"
        class="p-3 max-w-[54px]"
      >
        <img
          src="../../assets/admin-menu/download.svg"
          alt=""
          title="Вивантаження корисувачів"
          class=" w-[26px] h-[26px]"
        >
      </button>
    </div>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-[12px] text-left rtl:text-right text-gray-500">
        <thead class="text-xs font-bold text-[#252525] bg-[#e9ecf1] h-[65px]">
          <tr>
            <th
              v-for="(item, id) in titles"
              :key="id"
              class="px-2 py-1"
              :class="id == 7 || id == 9 ? 'text-right  max-w-[55px]':id == 2?'md:w-[120px]':''"
            >
              {{ item }}
            </th>
            <th class="px-3">
              <img
                src="../../assets/admin-menu/pencil-square.svg"
                class="mx-auto"
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in users"
            :key="user.id"
            class="cursor-pointer border-b hover:bg-gray-50"
            :class="user.isActive ? 'bg-white' : 'bg-rose-50'"
          >
            <td class="p-1">
              {{ user.id }}
            </td>
            <td class="p-1">
              {{ user.name }}
            </td>
            <!-- <td class="text-[12px] text-center px-2 py-1">
              <p
                v-for="role in user.roles"
                :key="role.id"
              >
                {{ role.name}}
              </p>
            </td> -->
            <td class="p-1">
              <a
                class="text-[#36f] hover:underline"
                :href="`tel:+${user.phone}`"
              >{{ formatPhone(user.phone) }}</a>
            </td>
            <td class="p-1">
              <a
                class="text-[#36f] hover:underline"
                :href="`mailto:${user.email}`"
              >{{ user.email }}</a>
            </td>
            <td class="p-1">
              <a class="px-2 py-1">{{ user.responsability }}</a>
            </td>
            <td class="p-1 min-w-[160px] max-w-[250px]">
              <p
                v-for="(item, index) in user.assistance_id"
                :key="index"
              >
                {{item.description}}
              </p>
            </td>
            <td class="text-right px-2 py-1">
              <p>{{ user.community ? user.community.region :''}}</p>
              <p>{{ user.community ? user.community.adminCenter :''}}</p>
            </td>
            <td class="text-center p-1">
              <input
                type="checkbox"
                :checked="user.isActive"
                v-model="user.isActive"
                class="cursor-pointer rounded  border-gray-400 focus:none"
                @change="onActivate(user.id, user.isActive)"
              >
            </td>
            <td class="p-1 text-right text-xs">
              <div class=" max-w-[55px]">
                {{ dateFormate(user.updatedAt) }} {{ timeFormate(user.updatedAt) }}
              </div>
            </td>
            <td class="px-3 py-1">
              <router-link
                to="#"
                class="flex justify-center items-center"
              >
                <img
                  src="../../assets/admin-menu/pencil-square.svg"
                  alt=""
                  srcset=""
                >
              </router-link>
            </td>
          </tr>

        </tbody>
      </table>
    </div>

  </div>
</template>

<style scoped>
* {
  font-family: 'Liberation', sans-serif;
}
[type='checkbox']:checked {
  background-image: url('../../assets/admin-menu/check-yes.svg');
}
.wrapper-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}
td > a > img,
th > img {
  max-width: none;
}
</style>