<script>
export default {
  data() {
    return {
      contacts: [
        {
          number: '102',
          title: 'Екстрений виклик поліції',
          description: '(цілодобово з мобільного безкоштовно)',
        },
        {
          number: '15-47',
          title: 'Гаряча лінія для постраждалих від домашнього насильства в Україні',
          description: '(цілодобово з мобільного безкоштовно)',
        },
        {
          number: '116-123',
          title: 'Національна "гаряча" лінія з попередження домашнього насильства, торгівлі людьми та ґендерної дискримінації',
          description: '(цілодобово з мобільного безкоштовно)',
        },
        {
          number: '0 800 213 103',
          title: 'Контакт-центр системи безоплатної правової допомоги',
          description: '(цілодобово з мобільного безкоштовно)',
        },
        {
          number: '0 800 500 335 / 386 (з мобільного)',
          title: 'Національна гаряча лінія з попередження домашнього насилля',
        },
        {
          number: 'Розірви коло',
          title: 'Найбільший в Україні ресурс по темі насильства',
        }
      ]
    };
  }
};
</script>
<template>
  <div
    class="flex flex-col items-center px-4 md:px-12 gap-9 my-8"
    id="contacts"
  >
    <div class="flex flex-col items-center text-center gap-2">
      <p class="text-3xl md:text-4xl font-bold text-[#36393f]">
        Загальнонаціональні контакти
      </p>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-1/2 lg:grid-cols-2 gap-8 w-full max-w-[1512px]">
      <div
        v-for="(contact, index) in contacts"
        :key="index"
        class="flex flex-wrap md:flex-nowrap items-center gap-6"
      >
        <p class="w-full md:w-0 md:min-w-[300px] md:text-right text-3xl font-bold text-[#b20000] md:border-none border-2 border-[#fbfbfb] border-b-[#b20000]">{{ contact.number }}</p>
        <div class="md:border-2 border-[#b20000]  md:h-full"></div>
        <div class="flex flex-col gap-2">
          <p class="text-xl md:text-2xl font-bold text-[#36393f]">{{ contact.title }}</p>
          <p class="text-md text-gray-500">{{ contact.description }}</p>
          <button
            v-if="index === 5"
            class="btn-default md:max-w-[320px] flex justify-center items-center flex-grow-0 
            flex-shrink-0 relative gap-2.5 px-9 py-4 rounded-[100px]"
          >
            Перейти на сайт
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<style>
/* Здесь можно добавить дополнительные стили */
</style>