<script>

import Chart from 'chart.js';
import fetchWithAuth from '@/lib/fetchWithAuth';
import { DateFormate, TimeFormate } from '@/lib/dateTime.js';
import formatPhoneNumber from '@/lib/formatterPhone.js';
import MonthNavigator from "../components/MonthNavigator.vue";
// import modifiedLabel from '@/lib/modify.js';

export default {
  name: 'DashboardHome',
  components: { MonthNavigator },

  data() {
    return {
      user: {},
      userName: '',
      appealsLast: [],
      appeals: [],
      totals: '',
      totalsByMonth: '',
      averageTime: '',
      avgTimeByRegionId: [],
      totalsByRegion: [],
      assistanceType: [],
      totalsAssistanceById: [],
      data: [],
      new_appeal: '',
      completed: '',
      region: [],
      in_work: '',
      buyersData: {
        type: 'line',
        data: {
          labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          datasets: [{
            backgroundColor: "rgba(99,179,237,0.4)",
            strokeColor: "#63b3ed",
            pointColor: "#fff",
            pointStrokeColor: "#63b3ed",
            data: [203, 156, 99, 251, 305, 247, 256]
          },
          {
            backgroundColor: "rgba(198,198,198,0.4)",
            strokeColor: "#f7fafc",
            pointColor: "#fff",
            pointStrokeColor: "#f7fafc",
            data: [86, 97, 144, 114, 94, 108, 156]
          }]
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                display: false
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          }
        }
      },
      appealData: {
        type: 'bar',
        data: {
          labels: [],
          datasets: [{
            backgroundColor: [
              '#E9ECF1',
              '#E9ECF1',
              '#E9ECF1',
              '#E9ECF1',
              '#E9ECF1',
              '#E9ECF1',
              '#E9ECF1',
              // 'rgb(237, 11, 11)',
              // 'rgb(237, 205, 86)',
              // 'rgb(55, 205, 86)',
              // '#8fc1eb',
              // '#03f87ac9',
            ],
            strokeColor: "#63b3ed",
            pointColor: "#fff",
            pointStrokeColor: "#63b3ed",
            minBarLength: 0,
            data: []
          },]
        },
        options: {
          title: {
            display: true,
            text: 'По кількості запитів на реагування',
            position: 'top',
            fontSize: 16,
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                display: true
              },
              ticks: {
                display: true
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          },
          events: ['click'],
        }

      },

      averageTimes: {
        type: 'bar',
        data: {
          labels: [],
          datasets: [{
            backgroundColor: [
              '#E9ECF1',
              '#E9ECF1',
              '#E9ECF1',
              '#E9ECF1',
              '#E9ECF1',
              '#E9ECF1',
              '#E9ECF1',
              // 'rgb(237, 11, 11)',
              // 'rgb(237, 205, 86)',
              // 'rgb(55, 205, 86)',
              // '#8fc1eb',
              // '#03f87ac9',
            ],
            strokeColor: "#63b3ed",
            pointColor: "#fff",
            pointStrokeColor: "#63b3ed",
            minBarLength: 0,
            data: []
          },]
        },
        options: {
          title: {
            display: true,
            text: 'По часу виконання',
            position: 'top',
            fontSize: 16,
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                display: true
              },
              ticks: {
                display: true
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          },
          events: ['click']
        }

      },

      assistanceData: {
        type: 'doughnut',
        data: {
          datasets: [{
            labels: [],
            data: [],
            backgroundColor: [
              'rgb(255, 149, 51)',
              'rgb(237, 11, 11)',
              'rgb(237, 205, 86)',
              'rgb(18,197, 33)',
              'rgb(51, 102,255)',
              'rgb(54, 162, 235)',
              'rgb(245, 236, 236)'
            ],
            hoverOffset: 4,
            borderRadius: 10 // Скругление углов
          }]
        },
        options: {
          title: {
            display: true,
            text: 'По запитам на реагування',
            position: 'top',
            fontSize: 16,
          },
          cutoutPercentage: 70,
          legend: {
            display: true,
            position: 'right',
            alignt: 'left',
            lineWidth: 12,
            align: 'middle',
          },
          scales: {
            x: {
              ticks: {
                display: true
              },
              yAxes: [{
                gridLines: {
                  display: false
                },
                ticks: {
                  display: false
                }
              }],
              xAxes: [{
                gridLines: {
                  display: false
                },
              }]
            },
          },
          events: ['click']
        },
      },
      dateRange: {
        start: '',
        end: '',
      },
    }
  },
  async mounted() {
  },
  methods:
  {

    getCookieValue(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },

    formatPhone(phone) {
      if (phone) return formatPhoneNumber(phone);
    },
    async getAppealHyistoryByStatus() {
      try {

        const response = await fetchWithAuth(`/api/appeal-history/totals?startDate=&endDate=`, {
          method: 'GET',
        });
        if (response.ok) {
          this.appeals = await response.json();
          this.totals = this.appeals.totals;
          this.new_appeal = this.appeals.new;
          this.completed = this.appeals.completed;
          this.in_work = this.appeals.in_work;

        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    async getAppealHyistoryByStatusByMonth(filter) {
      try {

        const response = await fetchWithAuth(`/api/appeal-history/totals?startDate=${filter.startDate}&endDate=${filter.endDate}`, {
          method: 'GET',
        });
        if (response.ok) {
          this.appeals = await response.json();
          this.totalsByMonth = this.appeals.totals;
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    async getAvgTimeByRegionId(id, filter) {
      try {

        const response = await fetchWithAuth(`/api/appeal-history/avg/region/${id}?startDate=${filter.startDate}&endDate=${filter.endDate}`, {
          method: 'GET',
        });
        if (response.ok) {
          return this.averageTime = await response.json();

        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    async getTotalsAssistanceById(id, filter) {
      try {

        const response = await fetchWithAuth(`/api/appeal-history/totals/assistance/${id}?startDate=${filter.startDate}&endDate=${filter.endDate}`, {
          method: 'GET',
        });
        if (response.ok) {
          return this.totalsAssistanceById = await response.json();

        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    async getAssistance() {
      try {

        const response = await fetchWithAuth(`/api/assistance`, {
          method: 'GET',
        });
        if (response.ok) {
          let totalsAssistance = [];
          let assistanceTypeDescription = [];
          this.assistanceType = await response.json();
          for (const assistance of this.assistanceType) {
            const el = await this.getTotalsAssistanceById(assistance.id, { startDate: this.dateRange.start, endDate: this.dateRange.end });
            if (el["AssistanceTypes.description"]) {
              totalsAssistance.push(el);
              assistanceTypeDescription.push(el["AssistanceTypes.description"]);
            }
          }

          // const lbl = assistanceTypeDescription;

          const lbl = assistanceTypeDescription.map(
            item => `${this.truncateString(item, 17)} ...`
          );

          const totals = totalsAssistance.map(item => item.totals);
          this.$set(this.assistanceData.data, 'labels', lbl);
          this.$set(this.assistanceData.data.datasets[0], 'data', totals);

        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
        location.assign('/login')
      }
    },
    async getAppeals() {
      try {

        const response = await fetchWithAuth(`/api/appeals/last`, {
          method: 'GET',
        });
        if (response.ok) {
          this.appealsLast = await response.json();
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
        location.assign('/login')
      }
    },
    async getTotalsHistoryByRegion(id, filter) {
      try {
        const response = await fetchWithAuth(`/api/appeal-history/totals/region/${id}?startDate=${filter.startDate}&endDate=${filter.endDate}`, {
          method: 'GET',
        });
        if (response.ok) {
          this.totalsByRegion = await response.json();
          return this.totalsByRegion;
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);

      }
    },
    async getActiveRegion() {
      try {
        const response = await fetchWithAuth(`/api/communities/region/active`, {
          method: 'POST',
        });

        if (response.ok) {
          const res = await response.json();
          let totals = [];
          this.avgTimeByRegionId = [];
          this.region = [];

          for (const item of res) {
            this.region.push(item.region);

            const region = await this.getTotalsHistoryByRegion(item.region_id, { startDate: this.dateRange.start, endDate: this.dateRange.end });
            const times = await this.getAvgTimeByRegionId(item.region_id, { startDate: this.dateRange.start, endDate: this.dateRange.end });

            if (times) this.avgTimeByRegionId.push(times);
            if (region) totals.push(region.totals);
          }

          const avgTime = this.avgTimeByRegionId.map(time => Math.round(time.averageTime / 24 / 3600));

          // Обновляем данные графика
          this.$set(this.appealData.data, 'labels', this.region);
          this.$set(this.appealData.data.datasets[0], 'data', totals);

          this.$set(this.averageTimes.data, 'labels', this.region);
          this.$set(this.averageTimes.data.datasets[0], 'data', avgTime);

        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
        location.assign('/login');
      }
    },

    onDateFormate(date) {
      return DateFormate(date);
    },
    onTimeFormate(time) {
      return TimeFormate(time);
    },
    truncateString(str, length) {
      return str.slice(0, length);
    },
    async onMonthChanged(range) {
      this.dateRange = range;
      try {
        // все асинхронные запросы параллельно
        await Promise.all([
          // this.getAppealsByStatuses(),
          this.getAppealHyistoryByStatus(),
          this.getAppeals(),
          this.getActiveRegion(),
          // this.onMonthChanged(this.dateRange),
          this.getAssistance(),
          this.getAppealHyistoryByStatusByMonth({ startDate: this.dateRange.start, endDate: this.dateRange.end }),
        ]);


        // как все данные загружены, создаем графики
        new Chart(document.getElementById('totals-regions'), this.appealData);
        new Chart(document.getElementById('assistance-chart'), this.assistanceData);
        new Chart(document.getElementById('average-chart'), this.averageTimes);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }

    },
  }
}
</script>
<template>
  <div id="home">
    <div class="flex justify-end">
      <MonthNavigator @month-changed="onMonthChanged" />
    </div>
    <div class="flex flex-wrap -mx-3">

      <div class="w-full xl:w-1/2 px-3">
        <p class="text-xl font-semibold mb-4">По питанях</p>

        <div class="relative w-full bg-white border rounded-lg p-4 mb-8 xl:mb-0">
          <canvas
            id="assistance-chart"
            width="600"
            height="400"
          ></canvas>
          <div class="hidden md:block absolute top-[44%] md:left-[32%] lg:left-[28%] 2xl:left-[34%]">
            <p class="text-[32px] text-[#2E3A59] w-[50px] text-center">{{ totalsByMonth !=0 ? totalsByMonth :'' }}</p>
          </div>
          <div
            v-show="totalsByMonth !=0"
            class="hidden md:block absolute top-[54%] md:left-[27%] lg:left-[22%] 2xl:left-[30%]"
          >
            <p class="text-[16px] text-[#25252580] opacity-90 w-[120px] text-center">за {{new Date(this.dateRange.start).toLocaleString("uk-UA", { month: "long" }) }}</p>
          </div>
        </div>
      </div>

      <div class="w-full xl:w-1/2 px-3">
        <p class="text-xl font-semibold mb-4">По регіонах</p>

        <div class="w-full bg-white border rounded-lg p-4 mb-8 xl:mb-0">
          <canvas
            id="totals-regions"
            width="600"
            height="400"
          ></canvas>
        </div>
      </div>

      <div class="w-full xl:w-1/2 px-3 my-4">
        <p class="text-xl font-semibold mb-4">По середньому часу (днях)</p>

        <div class="w-full bg-white border rounded-lg p-4 mb-8 xl:mb-0">
          <canvas
            id="average-chart"
            width="600"
            height="400"
          ></canvas>
        </div>
      </div>

      <div class="w-full xl:w-1/2 px-3">
        <p class="text-xl font-semibold my-4">Останні</p>
        <div class="flex flex-col justify-start items-start self-stretch p-8 rounded-lg bg-[#fbfbfb] border border-[#252525]/[0.04]">
          <div
            v-for="item of appealsLast"
            :key="item.id"
            class="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 my-1 px-3 py-3.5 rounded-lg bg-neutral-100"
          >
            <div class="flex justify-between items-start self-stretch flex-grow-0 flex-shrink-0">
              <div class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-1">
                <p class="self-stretch flex-grow-0 flex-shrink-0 text-xs font-bold text-left text-[#252525]">
                  {{ item.name }} {{ item.last_name }}
                </p>
                <p class="self-stretch flex-grow-0 flex-shrink-0 text-[10px] text-left text-[#36f]">
                  {{ formatPhone(item.phone) }}
                </p>
              </div>
              <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-1">
                <p class="flex-grow-0 flex-shrink-0 text-[10px] text-right text-[#252525]/50">
                  {{ onDateFormate(item.createdAt) }}
                </p>
                <div class="self-stretch flex-grow-0 flex-shrink-0 w-[0.5px] bg-[#252525]/50"></div>
                <p class="flex-grow-0 flex-shrink-0 text-[10px] text-right text-[#252525]/50">{{ onTimeFormate(item.createdAt) }}</p>
              </div>
            </div>
            <p class="flex-grow-0 flex-shrink-0 text-xs text-left text-[#252525]">
              {{ item.assistans_type }}
            </p>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<style scoped>
* {
  font-family: 'Liberation', sans-serif;
}
</style>
