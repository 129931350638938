
<script>
import AppealsList from '../components/appeal/AppealsList.vue'
export default {
  name: 'Appeal',
  components: { AppealsList }
}
</script>

<template>
  <div class="overflow-auto">
    <AppealsList />
  </div>
</template>