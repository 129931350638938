<script>
export default {
  name: "AboutProject",
  data() {
    return {
      title: "Скажи",
      hashtag: "#ДОСИТЬ!",
      description: "Онлайн-платформа для жінок, які страждають від домашнього, гендерно зумовленого насильства та є в зоні ризику.",
      helpTitle: "Тобі потрібна допомога?",
      helpDescription: "Залишай своє анонімне звернення в один клік",
      buttonText: "Тисни тут",
      helpLink: "/help" // Укажите нужный маршрут для ссылки
    };
  }
};
</script>

<template>
  <div
    class="py-4 mt-1"
    id="about"
  >
    <div class="grid grid-cols-3 gap-2">
      <div class="grid col-span-3 md:col-span-1">
        <div class="m-auto max-w-full">
          <p class="relative text-[#b20000] text-[73px] md:text-[108px] text-center uppercase top-[18px] md:top-[22px]">скажи</p>
          <p class="relative bold-itaslic rounded-br-[52px] bg-[#b20000] px-[40px] py-[4px] md:px-[53px] bottom-[18px] md:bottom-[22px] text-[#fbfbfb] text-[54px] md:text-[76px] uppercase">#досить!</p>
        </div>
      </div>
      <div class="grid col-span-3 md:col-span-1">
        <img
          src="../../assets/about/stop-woomen.png"
          width="375"
          height="auto"
          class="block mx-auto"
          alt=""
        >
      </div>
      <div class="grid col-span-3 md:col-span-1 justify-start items-start self-center flex-grow-0 flex-shrink-0 relative gap-8 px-4">
        <p class="flex-grow-0 flex-shrink-0 text-[24px] md:text-4xl text-center md:text-left">
          <span class="flex-grow-0 flex-shrink-0 text-[24px] md:text-4xl font-bold italic text-center md:text-left text-[#b20000]">#</span>
          <span class="flex-grow-0 flex-shrink-0 text-[24px] md:text-4xl font-bold italic text-center md:text-left uppercase text-[#b20000]">Досить</span>
          <span class="flex-grow-0 flex-shrink-0 text-[24px] md:text-4xl font-bold italic text-center md:text-left text-[#b20000]">
          </span>
          <span class="flex-grow-0 flex-shrink-0 w-[485px] text-[24px] md:text-4xl font-bold text-left text-[#36393f]">– важлива платформа швидкої допомоги від </span><span class="flex-grow-0 flex-shrink-0 w-[485px] text-4xl font-bold italic text-left uppercase text-[#b20000]">#іншажінка</span><span class="flex-grow-0 flex-shrink-0 w-[485px] md:text-4xl font-bold text-center md:text-left text-[#36393f]"> для тих, хто стикається з домашнім та гендерно-зумовленим насильством або в зоні
            ризику</span>
        </p>
        <div class="flex justify-center md:justify-start items-start flex-grow-0 flex-shrink-0 relative">
          <router-link
            to="/help-form"
            class="btn-primery flex justify-center items-center flex-grow-0 
            flex-shrink-0 relative gap-2.5 px-9 py-4 rounded-[100px]"
          >
            Отримати допомогу
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  font-family: 'Liberaton Sans', sans-serif;
  color: #36393f;
  background-color: #fbfbfb;
}
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.bold-itaslic {
  font-weight: bold;
  font-style: italic;
}
</style>
