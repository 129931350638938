
<script>
import Capitalize from '@/lib/capitalize.js';
import { API } from '../../config/config.js';
import { jwtDecode } from 'jwt-decode';
// import refresh from '../lib/refreshTokin';
import fetchWithAuth from '@/lib/fetchWithAuth';
import ModalInfo from './ModalInfo.vue';


export default {
  components: {
    ModalInfo
  },
  data() {
    return {
      text: '',
      time: null,
      switch: true,
      socket: null,
      disconect: false,
      user: '',
      countMessage: '',
      stats: [],
      users: [],
      senderId: '',
      senderName: '',
      roomId: '',
      userId: '',
      regionId: '',
      userName: '',
      messages: [],
      logo: require('../assets/error.svg'),
      errMsg: '',
      userIdToBgColor: {
        1: 'bg-red-500',
        2: 'bg-blue-500',
        3: 'bg-green-500',
        4: 'bg-yellow-500',
        5: 'bg-purple-500',
        6: 'bg-pink-500',
        7: 'bg-indigo-500',
        8: 'bg-teal-500',
        9: 'bg-orange-500',
        10: 'bg-gray-500',
        11: 'bg-lime-500',
        12: 'bg-fuchsia-500',
        13: 'bg-cyan-500',
        14: 'bg-amber-500',
        15: 'bg-rose-500',
        16: 'bg-violet-500',
        17: 'bg-emerald-500',
        18: 'bg-sky-500',
        19: 'bg-slate-500',
        20: 'bg-stone-500'
      }

    };
  },
  methods: {

    async initSocket(userId, regionId, userName) {
      try {
        if (process.env.NODE_ENV === 'production') {
          this.socket = new WebSocket(`wss://${API.HOST}/ws/`);
          // this.socket = new WebSocket(`ws://127.0.0.1:${API.SOCKET_PORT}`);
        } else {
          this.socket = new WebSocket(`ws://${API.HOST}:${API.SOCKET_PORT}`);
        }


        this.socket.addEventListener('open', async () => {
          const res = await fetchWithAuth(`/api/users/online/${userId}`, {
            method: 'POST'
          });
          if (res.ok) await this.getUsers(regionId);
          this.socket.send(
            JSON.stringify({
              type: 'register',
              userId: userId,
              userName: userName
            })
          );
        });

        this.socket.addEventListener('close', async () => {
          const res = await fetchWithAuth(`/api/users/offline/${userId}`, {
            method: 'POST'
          });
          if (res.ok) await this.getUsers(regionId);
          this.disconect = true;
        });

        this.socket.addEventListener('message', ({ data }) => {
          try {
            const parsedData = JSON.parse(data);
            this.handleMessage(parsedData);
          } catch (error) {
            console.error('Invalid JSON:', data);
          }
        });

        this.socket.addEventListener('error', (error) => {
          console.error('WebSocket error:', error);
        });
      } catch (err) {
        console.error('Initialization error:', err);
      }
    },

    async getUsers(region_id) {
      try {
        const response = await fetchWithAuth(`/api/users/region/${region_id}`);
        if (response.ok) {
          this.users = await response.json();
        }
      } catch (err) {
        console.error('Error fetching users:', err);
      }
    },

    async getMessages() {
      try {
        const response = await fetchWithAuth(`/api/chats/${this.userId}/${this.roomId}`,
          {
            method: 'POST',
          });
        if (response.ok) {
          this.messages = await response.json();
          this.onScroll();
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            window.location.assign('/admin/denied');
          } else {
            console.error(res);
          }
        }
      } catch (err) {
        console.error('Error fetching messages:', err);
      }
    },
    async getUsersCountMsg(id) {
      try {
        const response = await fetchWithAuth(`/api/chats/count/date/${id}`);
        if (response.ok) {
          return await response.json();
        }
      } catch (err) {
        console.error('Error fetching users:', err);
      }
    },
    async writeMessage(data) {
      try {
        const response = await fetchWithAuth(`/api/chats`, {
          method: 'POST',
          body: JSON.stringify(data),
        });
        if (response.ok) {
          this.message = await response.json();
        }
      } catch (err) {

        if (err.message == "Failed to execute 'json' on 'Response': Unexpected end of JSON input") {
          this.errMsg = 'Обмеження на розмір повідомлень: 255 символів';
          this.event = false;
          this.showModal();
        } else {
          console.error('Error writing message:', err.message);
        }
      }
    },
    showModal() {
      this.$refs.modalInfo.onOpen();
    },
    hideModal() {
    },
    async sendMessage(text) {
      if (!text) return;

      const data = {
        text: text,
        senderId: this.senderId,
        senderName: this.senderName,
        toUserId: this.selectedUser,
        roomId: this.roomId
      };

      await this.writeMessage(data);
      await this.getMessages();

      this.text = '';

      await this.setFocus();
      this.onScroll();

      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(
          JSON.stringify({
            type: 'broadcast',
            text: text,
            toUserId: this.selectedUser
          })
        );
      }
    },

    async handleMessage(data) {
      if (data.type === 'message' && data.senderId !== this.userId) {
        await this.getMessages();
        this.playSound();
      }
    },

    async setFocus() {
      this.$refs.input?.focus();
    },

    onScroll() {
      const el = document.getElementsByClassName('msg-block');
      if (el.length > 0) {
        const lastElement = el[el.length - 1];
        lastElement.scrollIntoView({ behavior: 'smooth' });
      }
    },

    onSelectUser(id) {
      this.selectedUser = id;
      this.getUsers(this.regionId).then(() => {
      }).catch(err => {
        console.error(err);
      })
      this.initSocket(this.userId, this.regionId, this.userName);
    },

    onCapitalize(text) {
      return Capitalize(text);
    },
    formatTime(updatedAt, timeZone = 'UTC') {
      if (!updatedAt) return ''; // Return an empty string if no value is provided

      try {
        const date = new Date(updatedAt); // Convert to Date object

        // Format time to 12-hour clock with AM/PM and time zone
        return date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true, // Use 12-hour clock
          timeZone, // Specify the time zone
        });
      } catch (error) {
        console.error('Invalid date provided:', updatedAt, error);
        return 'Invalid Date';
      }
    },
    isNewDay(currentDate, previousDate) {
      if (!previousDate) return true; // Если предыдущей даты нет, это первый элемент
      const current = new Date(currentDate).toDateString(); // Приводим к строке даты
      const previous = new Date(previousDate).toDateString();
      return current !== previous; // Сравниваем, изменился ли день
    },
    formatDate(date, timeZone) {
      const options = { day: 'numeric', month: 'long', year: 'numeric', timeZone };
      return new Date(date).toLocaleDateString('uk-Ua', options);
    },
    playSound() {
      const audio = this.$refs.newMessageSound; // Получить ссылку на элемент
      if (audio) {
        audio.play().catch((error) => {
          console.error("Ошибка при воспроизведении звука:", error);
        });
      }
    },
    enableAudio() {
      const audio = this.$refs.newMessageSound;
      if (audio) {
        audio.play().catch(() => {
          console.log('Аудио активировано после первого взаимодействия пользователя.');
        });
      }
      window.removeEventListener('click', this.enableAudio); // Убираем обработчик
    },
  },
  async mounted() {
    window.addEventListener('click', this.enableAudio);
    // Инициализация наблюдателя
    const observer = new MutationObserver(() => {
      this.onScroll(); // Прокрутка при добавлении новых элементов
    });

    // Настройка наблюдения за изменениями в дочерних элементах
    observer.observe(this.$el, { childList: true, subtree: true });

    await this.setFocus();


    const token = sessionStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      this.userId = decoded.id;
      this.senderId = this.userId;
      this.senderName = decoded.name;
      this.userName = this.senderName;
      this.regionId = decoded.regionId;
      this.roomId = this.regionId;
      await this.getUsers(this.regionId);
    }

    for (const user of this.users) {
      this.stats[user.id] = await this.getUsersCountMsg(user.id);
    }
    await this.getMessages();

    await this.initSocket(this.userId, this.regionId, this.userName);

  },
  watch: {
    isCloseConnection() {
      if (this.isDisconnect) {
        console.log(this.isDisconnect);

        this.initSocket(this.userId, this.regionId, this.userName);
      }
    }
  },
};

</script>

<template>
  <div class="grid grid-cols-3 gap-x-2 gap-y-2 rounded-br-lg bg-[#fbfbfb] pl-3.5 pb-1.5 pt-3 mt-2">
    <div class="col-span-3 md:col-span-1 cursor-pointer min-w-[315px] z-10">
      <div class="flex justify-between w-full">
        <div class="hidden md:block relative mb-3 max-w-[280px]">
          <input
            type="search"
            name="serch"
            placeholder="Пошук..."
            class="bg-[#f4f1f1] h-10 max-w-[280px] xl:w-96 px-5 text-gray-600 rounded-[60px] border-[#252525]/[0.01] text-sm focus:outline-none"
            style="filter: drop-shadow(0px 2px 10px rgba(0,0,0,0.25));opacity: 0.5;"
          >
          <button
            type="submit"
            class="absolute right-0 top-0 mt-3 mr-4"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="flex-grow-0 flex-shrink-0 w-6 h-6 relative bottom-[3px]"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                opacity="0.3"
                clip-path="url(#clip0_2249_617)"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.6611 15.6633C15.8004 15.5239 15.9658 15.4132 16.1479 15.3377C16.33 15.2622 16.5252 15.2234 16.7223 15.2234C16.9195 15.2234 17.1146 15.2622 17.2967 15.3377C17.4788 15.4132 17.6443 15.5239 17.7836 15.6633L23.5586 21.4383C23.84 21.7196 23.9982 22.1011 23.9984 22.499C23.9985 22.8969 23.8406 23.2786 23.5593 23.5601C23.2781 23.8415 22.8965 23.9997 22.4986 23.9999C22.1007 24 21.719 23.8421 21.4376 23.5608L15.6626 17.7858C15.5231 17.6465 15.4125 17.4811 15.337 17.299C15.2615 17.1169 15.2227 16.9217 15.2227 16.7246C15.2227 16.5274 15.2615 16.3323 15.337 16.1502C15.4125 15.9681 15.5231 15.8026 15.6626 15.6633H15.6611Z"
                  fill="#252525"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.75 18C10.8334 18 11.9062 17.7866 12.9071 17.372C13.9081 16.9574 14.8175 16.3497 15.5836 15.5836C16.3497 14.8175 16.9574 13.9081 17.372 12.9071C17.7866 11.9062 18 10.8334 18 9.75C18 8.66659 17.7866 7.5938 17.372 6.59286C16.9574 5.59193 16.3497 4.68245 15.5836 3.91637C14.8175 3.15029 13.9081 2.5426 12.9071 2.12799C11.9062 1.71339 10.8334 1.5 9.75 1.5C7.56196 1.5 5.46354 2.36919 3.91637 3.91637C2.36919 5.46354 1.5 7.56196 1.5 9.75C1.5 11.938 2.36919 14.0365 3.91637 15.5836C5.46354 17.1308 7.56196 18 9.75 18V18ZM19.5 9.75C19.5 12.3359 18.4728 14.8158 16.6443 16.6443C14.8158 18.4728 12.3359 19.5 9.75 19.5C7.16414 19.5 4.68419 18.4728 2.85571 16.6443C1.02723 14.8158 0 12.3359 0 9.75C0 7.16414 1.02723 4.68419 2.85571 2.85571C4.68419 1.02723 7.16414 0 9.75 0C12.3359 0 14.8158 1.02723 16.6443 2.85571C18.4728 4.68419 19.5 7.16414 19.5 9.75V9.75Z"
                  fill="#252525"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_2249_617">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <div
          v-for="user of users"
          :key="user.id"
          v-show="selectedUser === user.id"
          class="flex justify-between items-center tooltip"
          @click="onSelectUser(0)"
          data-tooltip="Видалити отримувача"
        >
          <p :class="selectedUser === user.id ? `w-[30px] h-[30px] text-[16px]  rounded-full text-center text-white pt-[4px] ${userIdToBgColor[selectedUser]}` : ''">
            {{ (user.name.charAt(0) + user.name.charAt(1)).toUpperCase() }}
          </p>
          <p class="pl-1.5 text-[10px] text-gray-500 opacity-45">{{ user.name }}</p>
        </div>
      </div>
      <div
        v-for="(user,index) of users"
        :key="user.id"
        v-show="userId !== user.id"
      >
        <div
          :class="selectedUser === user.id ? 'selected' : ''"
          :style="selectedUser === user.id ? 'box-shadow: 0px 4px 24px 0 rgba(24,24,24,0.2)':''"
        >
          <div
            @click="onSelectUser(user.id)"
            data-tooltip="Обрати отримувача"
            class="tooltip flex flex-row-reverse justify-between px-3 py-2.5 rounded-tl-lg rounded-bl-lg bg-neutral-100 border-t-0 border-r-0 border-b-0 border-l-4"
            :class="selectedUser === user.id ? 'border-[#12c521] bg-neutral-50' : (index +2) % 2 ==0 ?'border-[#ff9533]':'border-[#b20000]'"
          >
            <div class="flex-grow-0 flex-shrink-0">
              <div class="flex flex-col justify-start items-start gap-1">
                <p
                  v-if="stats[user.id]"
                  class="flex-grow-0 flex-shrink-0 text-xs text-left text-black/50"
                >{{ formatTime(stats[user.id].lastTimeDate, 'Europe/Kiev') === 'Invalid Date' ? '-------------' : formatTime(stats[user.id].lastTimeDate, 'Europe/Kiev')}}</p>
                <div class="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative overflow-hidden gap-2.5 py-1 rounded-[30px] bg-[#252525]">
                  <p
                    v-if="stats[user.id]"
                    class="flex-grow-0 flex-shrink-0 text-xs text-left text-neutral-100"
                  >{{ stats[user.id].count || '0' }}</p>
                </div>
              </div>
            </div>
            <div class="flex flex-nowrap justify-start flex-row-reverse">
              <div class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-2 ml-3">
                <p class="self-stretch flex-grow-0 flex-shrink-0 text-sm font-bold text-left text-black">
                  {{onCapitalize(user.name)}}
                </p>
                <p class="self-stretch flex-grow-0 flex-shrink-0 opacity-50 text-xs text-left text-[#252525]">
                  {{ user.responsability }}
                </p>
              </div>
              <div class="relative">
                <p
                  class="w-[40px] h-[40px] text-[18px] rounded-full text-center text-white pt-[7px]"
                  :class="userIdToBgColor[user.id]"
                >
                  {{ (user.name.charAt(0) + user.name.charAt(1)).toUpperCase() }}
                </p>
                <div
                  class="absolute block w-[12px] h-[12px] rounded-full top-[28px] left-[28px] border border-neutral-50"
                  :class="user.isOnline ? ' bg-[#12c521] green':'bg-red-600'"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block col-span-3 md:col-span-2 chat-room relative overflow-auto rounded-br-lg bg-[#fbfbfb]">
      <div class="wrapper">
        <div
          class="chat-area side-start h-[48dvh]"
          ref="answer"
        >
          <div
            v-for="(msg, index) in messages"
            :key="msg.id"
            :class="userId === msg.senderId ? 'flex-col self-end w-full' : 'flex-col self-start w-full'"
          >
            <!-- Проверяем, отличается ли дата от предыдущего сообщения -->
            <template v-if="isNewDay(msg.createdAt, messages[index - 1]?.createdAt)">
              <div class="flex justify-center my-2 text-[10px] text-gray-500 opacity-45">
                ------------------ {{ formatDate(msg.createdAt, 'Europe/Kiev') }} -------------------
              </div>
            </template>

            <div class="flex-col min-h-[58px] py-1">
              <div :class="userId === msg.senderId ? 'flex flex-row-reverse':'flex flex-row' ">
                <div class="from self-center px-2">
                  <div v-if="userId === msg.senderId">
                    <p class="w-[30px] h-[30px] text-xl rounded-full bg-[#b20000] text-center text-white pt-[2px]">Я</p>
                  </div>
                  <div v-else>
                    <div v-if="user.avatar">
                      <img
                        src=""
                        alt=""
                      >
                    </div>
                    <div v-else>
                      <p
                        class="w-[30px] h-[30px] text-[16px] rounded-full text-center text-white pt-[4px]"
                        :class="userIdToBgColor[msg.senderId]"
                      >
                        {{ (msg.senderName.charAt(0) + msg.senderName.charAt(1)).toUpperCase() }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="msg-block flex px-5 py-2.5 rounded-lg border max-w-[380px]"
                  :class="userId === msg.senderId ? 'bg-[#36f] border border-[#252525]/10 text-xs text-left text-[#fbfbfb]' : 'border-[#252525]/10 opacity-50 text-xs text-[#252525]'"
                >{{ msg.text }}
                </div>
              </div>
              <div
                class="time flex opacity-50 text-[10px] text-[#252525]"
                :class="userId === msg.senderId ? 'justify-end pr-[48px]' : 'justify-start pl-[50px]'"
              >{{ formatTime(msg.createdAt, 'Europe/Kiev') }}</div>
            </div>
          </div>

        </div>
        <div class="relative mt-[20px]">
          <textarea
            ref="input"
            rows="1"
            v-model="text"
            class="pl-5 pr-16 py-3.5 rounded-lg bg-neutral-100 border border-[#36f] w-full text-xs text-left text-[#c8c0c0]"
            placeholder=""
          ></textarea>
          <button
            class="absolute z-50 bottom-[10px] right-[2px]"
            id="submit"
            @click.prevent="sendMessage(text)"
          ></button>
        </div>
      </div>
    </div>
    <ModalInfo
      ref="modalInfo"
      :infoText="errMsg"
      @close="hideModal(event, action)"
      :logo="logo"
    />
    <audio
      ref="newMessageSound"
      src="/sounds/sound-2.mp3"
      preload="auto"
    ></audio>
  </div>

</template>

<style>
* {
  font-family: 'Liberation', sans-serif;
}
.chat-room {
  display: flex;
  flex-direction: column;
  min-width: 576px;
  background-color: #e6ecec;
  padding: 0 15px 0 15px;
  border-radius: 5px;
}
.wrapper {
  overflow: hidden;
}
.chat-area {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.side-start {
  align-items: self-start;
}
.side-end {
  align-items: self-end;
}
.avatar {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  align-self: center;
  /* margin-bottom: 5px; */
}
.input-message {
  display: flex;
  /* align-items: flex-end; */
  width: 96%;
  margin: 10px 3px 10px;
  height: 35px;
  padding: 5px 10px;
}
.answer {
  border-radius: 10px;
  background-color: #d0e2ef;
  padding: 8px 13px;
  margin: 0.2rem 0.2rem 0.2rem 0.5rem;
  text-align: left;
}
.personal {
  border-radius: 10px;
  background-color: #c6f5f4;
  padding: 8px 13px;
  margin: 0.2rem 0.2rem 0.2rem 0.5rem;
  text-align: left;
}
.personal::before {
  content: '';
  background-color: #c6f5f4;
  display: block;
  height: 10px;
  width: 10px;
  position: relative;
  top: 22px;
  left: -15px;
  transform: skew(-32deg);
}
.answer::before {
  content: '';
  background-color: #d0e2ef;
  display: block;
  height: 10px;
  width: 10px;
  position: relative;
  display: inline-block;
  top: 22px;
  left: -15px;
  transform: skew(-32deg);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
textarea {
  padding: 5px 15px 5px 15px;
}
#submit {
  height: 40px;
  width: 40px;
  align-self: center;
  border-radius: 5px;
  background-image: url('../assets/telega.png');
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
}
.time {
  font-size: 11px;
  background-color: transparent;
  position: relative;
  top: 3px;
}

.from {
  color: #347eb3;
}

@media (min-width: 325px) and (max-width: 576px) {
  .chat-room {
    min-width: 375px;
    max-width: 576px;
  }
}
</style>