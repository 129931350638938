<script>
export default {
  data() {
    return {
      currentDate: new Date(), // Текущая дата
    };
  },
  computed: {
    currentYear() {
      return this.currentDate.getFullYear();
    },
    currentMonth() {
      return this.currentDate.getMonth();
    },
    currentMonthName() {
      return (
        this.currentDate
          .toLocaleString('uk-UA', { month: 'long' })
          .charAt(0)
          .toUpperCase() +
        this.currentDate.toLocaleString('uk-UA', { month: 'long' }).slice(1)
      );
    },
  },
  methods: {
    navigateMonth(direction) {
      const newDate = new Date(this.currentDate.setMonth(this.currentMonth + direction));
      this.currentDate = newDate;
      this.$emit('month-changed', this.getMonthRange(newDate));
    },
    getMonthRange(date) {
      const start = new Date(date.getFullYear(), date.getMonth(), 2);
      const end = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      return {
        start: start.toISOString().slice(0, 10), // Формат YYYY-MM-DD
        end: end.toISOString().slice(0, 10),     // Формат YYYY-MM-DD
      };
    },
  },
  mounted() {
    this.$emit('month-changed', this.getMonthRange(this.currentDate));
  },
};
</script>

<template>
  <div class="date-picker">
    <button
      class="p-2"
      @click="navigateMonth(-1)"
    ><img
        src="../assets/admin-menu/chevron-l.svg"
        alt=""
        height="10"
        width="6"
      ></button>
    <span class="text-[16px] text-[#2e3a59] px-2">{{ currentMonthName }} {{ currentYear }}</span>
    <button
      class="p-2"
      @click="navigateMonth(1)"
    ><img
        src="../assets/admin-menu/chevron-r.svg"
        alt=""
        height="10"
        width="6"
      ></button>
  </div>
</template>



<style scoped>
* {
  font-family: 'Liberation', sans-serif;
}
.date-picker {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
