<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        "arrows": false,
        "dots": false,
        "infinite": true,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "autoplay": true,
        "speed": 8000,
        "autoplaySpeed": 1000,
        "pauseOnHover": true,
        "pause": 3000,
        "fade": false,
        "cssEase": "linear",
        "responsive": [{
          "breakpoint": 480,
          "settings": {
            "slidesToShow": 1 / 4,
          }
        }]
      }, slides: [
        {
          image: require('../../assets/about/slide-1.png'),
          id: 1
        }, {
          image: require('../../assets/about/slide-2.png'),
          id: 2
        }, {
          image: require('../../assets/about/slide-3.png'),
          id: 3
        }
      ],

    }
  }, methods: {

  }
}
</script>
<template>
  <div
    class="flex-grow-0 flex-shrink-0 py-8"
    id="partners"
  >
    <div class="flex flex-col justify-start items-center  gap-8">
      <div class="flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2">
        <p class="self-stretch flex-grow-0 flex-shrink-0 text-[40px] font-bold text-center text-[#36393f]">
          Партнери
        </p>
        <p class="self-stretch flex-grow-0 flex-shrink-0 text-lg text-center text-[#36393f]">
          <span class="self-stretch flex-grow-0 flex-shrink-0 text-lg text-center text-[#36393f]">Ми вдячні тим, хто працює разом з нами кожного</span><br /><span class="self-stretch flex-grow-0 flex-shrink-0 w-[1512px] text-lg text-center text-[#36393f]">дня над подоланням насильства</span>
        </p>
      </div>
    </div>
    <VueSlickCarousel v-bind="settings">
      <div
        v-for="slide in slides"
        :key="slide.id"
        class="py-4 w-[100dvh]"
      >
        <img
          :src="slide.image"
          :alt="slide.caption"
        />
      </div>
    </VueSlickCarousel>
  </div>
</template>

<style scoped>
html,
body {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Liberaton Sans', sans-serif;
}
</style>
