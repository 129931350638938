<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/uk';

export default {
  props: {
    title: { dataType: String },
    placeholder: { dataType: String },
    disabled: { dataType: Boolean, default: false },
    readonly: { dataType: Boolean, default: false },
  },
  components: { DatePicker },
  data() {
    return {
      date: null,
    };
  },
  methods: {
    onSelected(date) {
      this.$emit('selected', date);
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  mounted() {
    // window.addEventListener('DOMContentLoaded', () => {
    //   const el = document.querySelector('input.mx-input');
    //   const monthMap = {
    //     'січ': 'січень',
    //     'лют': 'лютий',
    //     'бер': 'березень',
    //     'квіт': 'квітень',
    //     'трав': 'травень',
    //     'черв': 'червень',
    //     'лип': 'липень',
    //     'серп': 'серпень',
    //     'вер': 'вересень',
    //     'жовт': 'жовтень',
    //     'лист': 'листопад',
    //     'груд': 'грудень'
    //   };

    //   el.addEventListener('click', () => {
    //     const monthElement = document.querySelector('.mx-btn.mx-btn-text.mx-btn-current-month');
    //     let monthName = monthElement?.innerHTML.trim();

    //     if (monthMap[monthName]) {
    //       monthElement.innerHTML = monthMap[monthName];
    //     }
    //     console.log('monthName', monthName);
    //   });
    // });
  }

};
</script>

<style>
.today {
  background-color: rgb(178 0 0 / 0.5);
}
.mx-icon-calendar {
  cursor: pointer;
  color: rgb(178 0 0 / 1);
}
td.cell.today,
.mx-calendar-content .cell.active {
  background-color: rgb(178 0 0 / 0.5);
  border-radius: 50%;
  color: beige;
}

.mx-input {
  text-align: left;
  border: none;
  border-bottom: #d1d5db !important;
  min-height: 40px;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  width: 100%;
  color: rgb(107 114 128 / 0.6) !important;
  background-color: #fff;
  text-align: center;
  font-weight: bold;
  padding-bottom: 1rem !important;
}
.mx-input:focus {
  border-color: rgb(178 0 0 / 1);
}
.mx-input::placeholder {
  opacity: 0.8;
}
.mx-icon-calendar {
  right: unset;
  left: 0;
  top: 38%;
}
.mx-time-column .mx-time-item.active {
  background-color: rgb(178 0 0 /0.5);
  color: #fff;
  /* background-color: transparent; */
  font-weight: 700;
  border-radius: 50%;
  width: 35px;
  margin: 0 auto;
}
.mx-btn-text {
  color: rgb(178 0 0 / 1);
}
.mx-btn-text:hover {
  color: rgb(17 24 39 / 0.6);
}
/* [multiple],
[type='date'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'],
input:where(:not([type])), */
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  /* font-size: 1rem; */
  line-height: 1.5rem;
  --tw-shadow: 0 0 transparent;
}
</style>
<template>
  <div
    :disabled="disabled"
    class="col-span-2 pt-0 mt-[0.175rem]"
  >
    <date-picker
      class="w-full border-0 border-b-2 border-gray-300 opacity-80 text-[#6b7280]"
      v-model="date"
      type="datetime"
      format="DD.MM.YYYY | HH:mm"
      @input="onSelected(date)"
      :placeholder="placeholder"
      :default-value="new Date().setHours(8, 0, 0, 0)"
      :disabled-date="notBeforeToday"
    ></date-picker>
  </div>
</template>

