<script>

import Pagination from '../Pagination.vue';
import createLink from '@/lib/createLink.js';
import googleCalendarLink from '@/lib/googleCalendarLink';
import { DateFormate, TimeFormate } from '@/lib/dateTime.js';
import refresh from '@/lib/refreshTokin';
import processingTime from '@/lib/processingTime';
import fetchWithAuth from '@/lib/fetchWithAuth';
import { jwtDecode } from 'jwt-decode';
import formatPhoneNumber from '@/lib/formatterPhone.js';

export default {
  components: { Pagination },
  data() {
    return {
      appeals: [
      ],
      refreshToken: '',
      timeWorks: '',
      fromDate: '',
      toDate: '',
      community: '',
      per_page: 15,
      titles: [
        'ID',
        'Дата надходження',
        'Заявник',
        'Тип допомоги',
        'Локація',
        // 'Допомога дитині',
        'Статус',
        'Термін виконання'
      ]
    }
  },
  methods: {
    async getAppeals(page = 1, pageSize = this.per_page) {
      try {
        const userId = jwtDecode(sessionStorage.getItem('token')).id;
        const response = await fetchWithAuth(`/api/appeal-history/user/${userId}?from=${this.fromDate}&to=${this.toDate}&community=${this.community}&page=${page}&pageSize=${pageSize}`, {
          method: 'GET',
        });
        if (response.ok) {
          const result = await response.json();
          this.appeals = result.data;
          this.records = result.total;
          this.pages = this.records % this.per_page === 0 ? parseInt(this.records / this.per_page) : parseInt(this.records / this.per_page) + 1;
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
        location.assign('/login')
      }
    },
    processing(ms) {
      return this.timeWorks = processingTime(ms)
    },

    onCuurenPageClick(value) {
      this.currentPage = value;
      this.getAppeals(value);
    },
    onDateFormate(date) {
      return DateFormate(date);
    },
    onTimeFormate(time) {
      return TimeFormate(time);
    },

    setLink(str, phone, className) {
      return createLink(str, phone, className)
    },
    formatPhone(phone) {
      if (phone) return formatPhoneNumber(phone);
    },
    setBadge(status_id) {
      const statuses = ['new', 'forward', 'work', 'finish'];
      let badges = '';
      statuses.forEach((status, key) => {
        if (key === status_id - 1) {
          badges = `badge-${status}`
        }
      });
      return badges;
    },
    googleLink(eventTitle, eventLocation, eventDescription, startDateTime, endDateTime) {

      const start = startDateTime.replace(/-|:|\.\d+/g, '');
      const end = endDateTime.replace(/-|:|\.\d+/g, '');
      return googleCalendarLink(eventTitle, eventLocation, eventDescription, start, end);
    }
  },
  mounted() {
    refresh().then(newToken => {
      sessionStorage.setItem("token", newToken);
      this.getAppeals();
    })

  },
  watch: {
  }
}

</script>
<template>
  <div>
    <div class="py-2 min-h-[70dvh]">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-[12px] text-left rtl:text-right text-gray-500">
          <thead class="text-xs font-bold text-[#252525] bg-[#e9ecf1] h-[65px]">
            <tr class="">
              <th
                v-for="item of titles"
                :key="item.id"
                scope="col"
                class="px-3 py-4"
              >
                {{ item }}
              </th>
              <th class="flex justify-center items-center px-3 py-5">
                <img src="../../assets/admin-menu/pencil-square.svg">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in appeals"
              :key="item.id"
              class="cursor-pointer bg-white border-b hover:bg-gray-50"
            >
              <td class="px-2 py-1">
                {{ item.id }}
              </td>
              <td class="px-2 py-1 text-[10px] text-left text-[#252525]/50">
                <p>{{ onDateFormate(item.createdAt) }}</p>
                <p>{{ onTimeFormate(item.createdAt) }}</p>
              </td>

              <td class="px-2 py-1">
                <p class="font-semibold">{{ item.appeal.name }}</p>
                <a
                  class="text-[#36f] hover:underline"
                  title="Набрати номер"
                  :href="`tel:+${item.appeal.phone}`"
                >{{ formatPhone(item.appeal.phone) }}</a>
              </td>
              <td class="px-2 py-1">
                {{ item.AssistanceTypes.description }}
              </td>

              <td class="px-2 py-1">
                {{ item.appeal.community.name}}
              </td>
              <!-- <td class="px-2 py-1">
                <div class="flex justify-center">
                  <img
                    v-if="item.appeal.children_help"
                    src="../../assets/admin-menu/check-yes.svg"
                    alt=""
                  >
                  <img
                    v-else
                    src="../../assets/admin-menu/check-non.svg"
                    alt=""
                  >
                </div>
              </td> -->
              <td class="px-2 py-1">
                <p :class="setBadge(item.appealStatus.id)">
                  {{ item.appealStatus ? item.appealStatus.title : 'No status' }}</p>
              </td>
              <td class="px-2 py-1">
                {{processing(item.prosessing_time)}}
              </td>
              <td class="flex justify-center items-center px-2 py-2.5 text-right">
                <router-link
                  :to="`appeal-history/edit/${item.id}`"
                  class=""
                >
                  <img
                    src="../../assets/admin-menu/pencil-square.svg"
                    alt=""
                  >
                </router-link>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
    <Pagination
      ref="pagination"
      :pages="pages"
      @click="onCuurenPageClick"
    />
  </div>
</template>

<style>
* {
  font-family: 'Liberation', sans-serif;
}
.wrapper-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}
</style>