<script>
export default {
  data() {
    return {
      items: [
        {
          title: 'Ми не знаємо точну кількість людей, які страждають від домашнього насильства',
          text: 'Цифри статистики, які подвоюються (особливо у ковідні роки), свідчать про те, що насильство стає ще більш масовим явищем. Скільки ще страждає від сексуального, економічного, психологічного, фізичного насильства? Скільки тих, хто терпить масового насильства, відсутній? Чи є відповідь на це питання?'
        },
        {
          title: 'Толерування насильства недопустиме',
          text: 'Скільки людей вам допоможуть на вулиці, коли вас будуть бити? Скільки таких, хто буде судити вас, якщо вас згвалтували, та шукати підступні наміри?'
        },
        {
          title: 'Імітація боротьби з насильством',
          text: 'Скільки часу ми будемо втрачати ще, читаючі шокуючі заголовки новин, імітуючи боротьбу з насильством такою величезною машиною, яка погано виконує свої обов\'язки?',
        },
        {
          title: 'Влада не завжди на боці постраждалих',
          text: 'Скільки часу ми будемо вважати, що про насильство соромно казати? Скільки ще ми будемо боятися осуду? Скільки ми будемо терпіти?'
        }
      ]
    }
  }
}
</script>
<template>
  <div class="flex-grow-0 flex-shrink-0 py-8">
    <div class="flex flex-col justify-start items-center gap-8">
      <div class="flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2">
        <p class="self-stretch flex-grow-0 flex-shrink-0 text-[24px] md:text-[40px] font-bold text-center text-[#36393f]">
          <span class="self-stretch flex-grow-0 flex-shrink-0 text-[24px] md:text-[40px] font-bold text-center text-[#36393f]">
            Скільки тих, хто звертався за допомогою,
            <br />але замість неї отримав папірці?</span>
        </p>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-4 gap-10 px-2 md:px-[50px]">
        <div
          v-for="item in items"
          :key="item.title"
          class="section md:h-[410px] flex-grow-0 flex-shrink-0 relative overflow-hidden bg-white px-4"
        >
          <img
            class="absolute right-0 top-0"
            src="../../assets/about/rectangle-1.png"
          />

          <p class="title max-w-[270px] absolute bottom-0 text-xl font-bold text-center text-gray-500 pb-2">
            {{ item.title  }}
          </p>
          <div class="hover">
            <p class="text-xl font-bold text-gray-500 p-2">{{ item.title }}</p>
            <p class="p-2">{{ item.text }}</p>
          </div>
        </div>
      </div>
      <router-link
        to="/help-form"
        class="btn-primery flex justify-center items-center flex-grow-0 
      flex-shrink-0 relative gap-2.5 px-9 py-4 rounded-[100px]"
      >
        Скажи #ДОСИТЬ! тут
      </router-link>
    </div>
  </div>
</template>

<style scoped>
* {
  font-family: 'Liberaton Sans', sans-serif;
}
.section {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  border-radius: 3px;
}
.section:hover {
  cursor: pointer;
  /* transition: all 0.5s ease-in-out; */
  opacity: 1;
  border-radius: 3px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.section:hover p.title {
  max-width: 270px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.section img {
  position: absolute;
  transform: scale(1);
  right: 15px;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.section:hover img {
  position: absolute;
  transform: scale(1.25);
  right: 15px;
  opacity: 0.1;
  transition: all 0.5s ease-in-out;
}
.section .hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}
.section:hover .hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: all 0.5s ease-in-out;
  opacity: 1;
  line-height: 22px;
}
@media (min-width: 315px) and (max-width: 767px) {
  .section img {
    position: absolute;
    right: 0;
    opacity: 0.15;
  }
  .section .hover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    opacity: 1;
    line-height: 22px;
  }
  .section p.title {
    opacity: 0;
  }
  .section {
    height: 340px;
  }
}
</style>