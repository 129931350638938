<script>
export default {
  data() {
    return {
      isActive: 0,
      sections: [
        { title: "Всі", isHighlighted: true },
        { title: "Зустрічі з жінками в ТГ" },
        { title: "Наші новини" },
        { title: "Допомога постраждалим" },
        { title: "Навчання" },
        { title: "Допомога речами" },
      ],
      articles: [
        {
          title: "Скажи #ДОСИТЬ! Зустріч у Градизькій громаді",
          imageSrc: [
            require("../../assets/about/report-1.png"),
            require("../../assets/about/report-2.jpeg"),
            require("../../assets/about/report-3.png"),
            require("../../assets/about/report-4.png"),
            require("../../assets/about/report-5.png"),
            require("../../assets/about/report-6.png"),
          ],
        },
        {
          title: "Хочемо поділитися нашими успіхами",
          imageSrc: [
            require("../../assets/about/report-2.jpeg"),
            require("../../assets/about/report-2.jpeg"),
            require("../../assets/about/report-7.png"),
            require("../../assets/about/report-1.png"),
            require("../../assets/about/report-3.png"),
            require("../../assets/about/report-4.png"),
          ],
        },
        {
          title: "Ділюсь успіхами проєкту",
          imageSrc: [
            require("../../assets/about/report-2.jpeg"),
            require("../../assets/about/report-3.png"),
            require("../../assets/about/report-4.png"),
            require("../../assets/about/report-5.png"),
            require("../../assets/about/report-6.png"),
            require("../../assets/about/report-7.png"),
          ],
        },
      ],
    };
  }, methods: {
    onClick(index) {
      this.isActive = index;
    }
  }
};
</script>

<template>
  <div
    class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 gap-9 py-8"
    id="reports"
  >
    <div class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2 py-8">
      <p class="flex-grow-0 flex-shrink-0 text-[40px] font-bold text-center text-[#36393f]">
        Звітуємо
      </p>
      <p class="flex-grow-0 flex-shrink-0 text-lg text-center text-[#36393f]">
        Результати нашої діяльності <br />в цифрах та не тільки
      </p>
    </div>
    <div class="flex flex-wrap relative md:after:absolute after:md:bottom-0 after:md:left-[30px] after:md:h-[2px] after:md:w-[96%] after:md:bg-[#b20000]">
      <div
        v-for="(section, index) in sections"
        :key="index"
        :class="isActive === index ? 'bg-[#b20000] text-[#fbfbfb]' : ''"
        class="flex cursor-pointer justify-center items-center mr-1 overflow-hidden px-6 py-2 rounded-[90px]"
        @click="onClick(index)"
      >
        <p class="text-2xl font-bold">{{ section.title }}</p>
      </div>
    </div>

    <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-10">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-10">
        <div
          v-for="(article, index) in articles"
          :key="index"
          class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative overflow-hidden gap-5"
        >
          <img :src="article.imageSrc[isActive]" />
          <div class="flex flex-wrap md:flex-nowrap justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
            <p class="flex-wrap text-xl font-bold text-left text-[#6B7280] pb-2">
              {{ article.title }}
            </p>
            <button class="btn-default flex justify-center items-center flex-grow-0 
          flex-shrink-0 relative gap-2.5 px-2 md:px-9 md:py-4 rounded-[100px]">
              Читати далі
            </button>
          </div>
        </div>
      </div>
    </div>
    <button class="btn-primery flex justify-center items-center flex-grow-0 
    flex-shrink-0 relative gap-2.5 px-9 py-4 rounded-[100px]">
      Переглянути більше
    </button>
  </div>
</template>

<style scoped>
img {
  width: 100%;
}
</style>
