<script>
import MobileMenu from './MobileMenu.vue';
export default {
  components: {
    MobileMenu
  },
  name: "Header",
  data() {
    return {
      logo: require("../../assets/about/location.png"), // Путь к логотипу
      location: "Полтавська обл.",
      menuItems: [
        { text: "Про проект", link: "#about" },
        { text: "В чому проблема", link: "#problems" },
        { text: "Наша допомога", link: "#help" },
        { text: "Заходи", link: "#activities" },
        { text: "Звітуємо", link: "#reports" },
        { text: "Наша команда", link: "#team" },
        { text: "Партнери", link: "#partners" },
        { text: "Медіа", link: "#media" },
        { text: "Контакти", link: "#contacts" }
      ],
      socialLinks: [
        { icon: "facebook", url: "https://facebook.com", src: require("../../assets/about/facebook.svg") },
        { icon: "instagram", url: "https://instagram.com", src: require("../../assets/about/insta.svg") },
        { icon: "youtube", url: "https://youtube.com", src: require("../../assets/about/youtube.svg") }
      ],
      supportButton: {
        text: "Підтримати нас",
        link: "/support"
      },
      contact: "+38 (050) 149 70 63"
    };
  },
  methods: {
    openModal() {
      this.$refs.modalMenuFooter.onOpen()
    }
  }
};
</script>

<template>
  <footer
    class="bg-[#fbfbfb] py-4"
    style="box-shadow: 0px -2px 4px 0 rgba(24,24,24,0.05);"
  >
    <div class="container mx-auto">
      <MobileMenu
        :menuItems="menuItems"
        ref="modalMenuFooter"
      />
      <div class="flex justify-between items-center pb-2">
        <!-- Логотип и локация -->
        <div class="w-[48px] md:w-[89px] h-auto flex items-center space-x-4">
          <!-- Логотип -->
          <img
            src="../../assets/about/logo.svg"
            alt="Логотип"
            height="75"
            width="89"
          >
        </div>
        <!-- Социальные ссылки, кнопка и контакт -->
        <div class="flex items-center space-x-4">
          <div class="flex items-center space-x-2"> <!-- Локация -->
            <img
              src="../../assets/about/location.png"
              height="24"
              alt=""
              class=""
            >
            <span class="text-[14px] text-gray-600">{{ location }}</span>
          </div>
          <div class="hidden md:flex space-x-3">
            <a
              v-for="(social, index) in socialLinks"
              :key="index"
              :href="social.url"
              target="_blank"
              class="text-gray-600 hover:text-gray-800"
            >
              <img
                :src="social.src"
                :alt="social.icon"
              >
            </a>
          </div>
          <div class="hidden md:flex justify-center items-center flex-grow-0 flex-shrink-0 relative">
            <router-link
              to="#"
              class="btn-primery flex justify-center items-center flex-grow-0 
              flex-shrink-0 relative gap-2.5 px-9 py-4 rounded-[100px]"
            >
              {{ supportButton.text }}
            </router-link>
          </div>
          <!-- Контактный телефон -->
          <a
            href="/call-center"
            class="hidden md:flex"
          >
            <span><img
                src="../../assets/about/phone.png"
                alt=""
              ></span>
            <span>
              <a class="text-gray-600 text-[14px] bold">{{ contact }}</a>
            </span>
          </a>
        </div>
        <button
          class="md:hidden cursor-pointer"
          @click="openModal"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.75 17.25C3.75 17.0511 3.82902 16.8603 3.96967 16.7197C4.11032 16.579 4.30109 16.5 4.5 16.5H19.5C19.6989 16.5 19.8897 16.579 20.0303 16.7197C20.171 16.8603 20.25 17.0511 20.25 17.25C20.25 17.4489 20.171 17.6397 20.0303 17.7803C19.8897 17.921 19.6989 18 19.5 18H4.5C4.30109 18 4.11032 17.921 3.96967 17.7803C3.82902 17.6397 3.75 17.4489 3.75 17.25ZM3.75 11.25C3.75 11.0511 3.82902 10.8603 3.96967 10.7197C4.11032 10.579 4.30109 10.5 4.5 10.5H19.5C19.6989 10.5 19.8897 10.579 20.0303 10.7197C20.171 10.8603 20.25 11.0511 20.25 11.25C20.25 11.4489 20.171 11.6397 20.0303 11.7803C19.8897 11.921 19.6989 12 19.5 12H4.5C4.30109 12 4.11032 11.921 3.96967 11.7803C3.82902 11.6397 3.75 11.4489 3.75 11.25ZM3.75 5.25C3.75 5.05109 3.82902 4.86032 3.96967 4.71967C4.11032 4.57902 4.30109 4.5 4.5 4.5H19.5C19.6989 4.5 19.8897 4.57902 20.0303 4.71967C20.171 4.86032 20.25 5.05109 20.25 5.25C20.25 5.44891 20.171 5.63968 20.0303 5.78033C19.8897 5.92098 19.6989 6 19.5 6H4.5C4.30109 6 4.11032 5.92098 3.96967 5.78033C3.82902 5.63968 3.75 5.44891 3.75 5.25Z"
              fill="#252525"
            ></path>
          </svg>
        </button>
      </div>
      <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 h-[18px] relative gap-[68px]">
        <p class="flex-grow-0 flex-shrink-0 opacity-60 text-sm text-left text-gray-500">
          © 2024 divergentwoman.com
        </p>
      </div>
      <nav class="hidden md:flex justify-between items-center text-gray-700 w-full">
      </nav>
    </div>
  </footer>
</template>


<style scoped>
.container {
  font-family: 'Liberaton Sans', sans-serif;
  max-width: 1412px;
  margin: 0 auto;
  padding: 0 10px;
}
.bold {
  font-weight: bold;
}
i {
  font-size: 24px;
}
</style>
