<script>

import Pagination from '../Pagination.vue';
import createLink from '@/lib/createLink.js';
import googleCalendarLink from '@/lib/googleCalendarLink';
import { DateFormate, TimeFormate } from '@/lib/dateTime.js';
import refresh from '@/lib/refreshTokin';
import processingTime from '@/lib/processingTime';
import fetchWithAuth from '@/lib/fetchWithAuth';
import { PER_PAGE } from '../../../config/config.js';
import formatPhoneNumber from '@/lib/formatterPhone.js';
const per_page = parseInt(PER_PAGE.APPEAL);



export default {
  components: { Pagination },
  data() {
    return {
      appeals: [
      ],
      records: '',
      pages: null,
      currentPage: '',
      refreshToken: '',
      timeWorks: '',
      titles: [
        '№',
        'Дата надходження',
        'Заявник',
        'Тип допомоги',
        'Місце перебування',
        'Допомога дитині',
        'Статус',
        'Термін виконання'
      ]
    }
  },
  methods: {
    async getAppeals(page = 1) {
      try {

        const response = await fetchWithAuth(`/api/appeals/pages=${page}?region_id=`, {
          method: 'GET',
        });
        if (response.ok) {
          const result = await response.json();
          this.appeals = result.rows;
          this.records = result.count;
          this.pages = this.records % per_page === 0 ? parseInt(this.records / per_page) : parseInt(this.records / per_page) + 1;

        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
        location.assign('/login')
      }
    },
    processing(ms) {
      return processingTime(ms)
    },

    onCuurenPageClick(value) {
      this.currentPage = value;
      this.getAppeals(value)
    },
    onDateFormate(date) {
      return DateFormate(date);
    },
    onTimeFormate(time) {
      return TimeFormate(time);
    },

    setLink(str, phone, className) {
      return createLink(str, phone, className)
    },
    googleLink(eventTitle, eventLocation, eventDescription, startDateTime, endDateTime) {

      const start = startDateTime.replace(/-|:|\.\d+/g, '');
      const end = endDateTime.replace(/-|:|\.\d+/g, '');
      return googleCalendarLink(eventTitle, eventLocation, eventDescription, start, end);
    },
    onOpenById(id) {
      this.$router.push(`/admin/appeals-history/appeal/${id}`)
    },
    onOpenForm() {
      this.$router.push('/admin/appeal-add');
    },
    formatPhone(phone) {
      if (phone) return formatPhoneNumber(phone);
    },
    setBadge(status_id) {
      const statuses = ['new', 'forward', 'work', 'finish'];
      let badges = '';
      statuses.forEach((status, key) => {
        if (key === status_id - 1) {
          badges = `badge-${status}`
        }
      });
      return badges;
    }
  },
  mounted() {
    refresh().then(newToken => {
      sessionStorage.setItem("token", newToken);
      this.getAppeals();
    })
  },
  watch: {

  }
}

</script>
<template>
  <div>
    <div class="min-h-[71dvh] pb-2">
      <div class="flex justify-start py-9">
        <button
          class="flex justify-center items-center flex-grow-0 flex-shrink-0 h-12 relative gap-2.5 px-9 py-4 rounded-[100px] bg-[#b20000] font-bold text-center text-[#fbfbfb]"
          @click="onOpenForm"
        >Додати</button>
      </div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-[12px] text-left text-gray-500">
          <thead class="text-xs font-bold text-[#252525] bg-[#e9ecf1] h-[65px]">
            <tr class="">
              <th
                v-for="item of titles"
                :key="item.id"
                scope="col"
                class="px-3 py-2"
              >
                {{ item }}
              </th>
              <th class="flex justify-center items-center  py-2 px-3 h-[65px]">
                <img src="../../assets/admin-menu/pencil-square.svg">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in appeals"
              :key="item.id"
              class="cursor-pointer border-b hover:bg-gray-50 bg-white"
            >
              <td class="px-2 py-1 text-center">
                {{ item.id }}
              </td>
              <td class="px-2 py-1 text-[10px] text-left text-[#252525]/50">
                <p>{{ onDateFormate(item.createdAt) }}</p>
                <p>{{ onTimeFormate(item.createdAt) }}</p>
              </td>
              <td class="px-2 py-1">
                <p class="font-semibold">{{ item.name }}</p>
                <a
                  class="text-[#36f] hover:underline"
                  :href="`tel:+${item.phone}`"
                >{{ formatPhone(item.phone) }}</a>
              </td>
              <td class="px-2 py-1 max-w-[280px] min-w-[250px]">
                {{ item.assistans_type }}
              </td>

              <td class="px-2 py-1">
                <div
                  v-for="community of item.community"
                  :key="community"
                >
                  <p>{{ community }}</p>
                </div>

              </td>
              <td class="px-2 py-1 max-w-[88px]">
                <div class="flex justify-center">
                  <img
                    v-if="item.children_help"
                    src="../../assets/admin-menu/check-yes.svg"
                    alt=""
                  >
                  <img
                    v-else
                    src="../../assets/admin-menu/check-non.svg"
                    alt=""
                  >
                </div>
              </td>
              <td class="px-2 py-1">
                <p :class="setBadge(item.status_id)">
                  {{ item.status ? item.status.title : 'No status' }}</p>
              </td>
              <td class="px-2 py-1 text-left max-w-[95px]">
                {{processing(item.prosessing_time)}}
              </td>
              <td class="min-w-[92px] px-2 py-1">
                <!-- :to="`/admin/appeal/edit/${item.id}`" -->
                <router-link
                  class="flex justify-center items-center"
                  :to="item.status_id === 4 ? `/admin/appeals/report/${item.id}`:`/admin/appeal/edit/${item.id}`"
                >
                  <img
                    src="../../assets/admin-menu/pencil-square.svg"
                    alt=""
                  >
                </router-link>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
    <Pagination
      ref="pagination"
      :pages="pages"
      @click="onCuurenPageClick"
    />
  </div>
</template>

<style scoped>
* {
  font-family: 'Liberation', sans-serif;
}
.wrapper-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}
</style>