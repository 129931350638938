<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  components: {
    VueSlickCarousel
  },

  data() {
    return {
      settings: {
        "arrows": false,
        "infinite": true,
        "slidesToShow": 3 / 1.9,
        "slidesToScroll": 1,
        "autoplay": false,
        "speed": 3000,
        "autoplaySpeed": 1000,
        "pauseOnHover": true,
        "fade": false,
        "cssEase": "linear",
        "responsive": [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          }]
      },
      slides: [
        {
          id: 1,
          name: 'Каріна Булавіна',
          image: require('../../assets/about/team-1.png'),
          title: 'Сертифікований коуч ICF, тренерка, магістр державного управління, керівниця з 15 річним досвідом на різних рівнях державної влади, професійна спеціалістка з управління персоналом. Працює в індивідуальному та командному коучинговому форматі. Допомагає відкрити власний потенціал, визначити правильні цілі та шляхи їх досягнення.',
        },
        {
          id: 2,
          name: 'Галина Артюх',
          image: require('../../assets/about/team-2.png'),
          title: 'Інструктор з танцювальної йоги Проводить тілесні практики (йога, цигун, аутентичний рух і т.д.) Працуює для того, щоб кожна жінка відчувала свою цінність незалежно від її життєвих обставин',
        },
        {
          id: 3,
          name: 'Наталія Недосвіт',
          image: require('../../assets/about/team-3.png'),
          title: 'Психолог, коуч. Сертифікована в річний супервізійний програмі, програмі в роботі зі змінами, роботі з дітьми з інвалідністю. Автор особистих практик Працює в реабілітаційному центрі з залежними людьми в форматі груп і особистих консультацій.',
        },
      ]
    }

  }, methods: {
    next() {
      this.$refs.carousel.next()
    },
    prev() {
      this.$refs.carousel.prev();
    },
  },
  mounted() {
    // const el = document.querySelector('.slick-arrow.slick-next');
    // el.style.display = 'none';
  },
};
</script>
<template>
  <div
    id="team"
    class="block gap-9 md:mb-8"
  >
    <div class="md:flex md:flex-col items-center gap-2 text-center mb-6">
      <p class="text-3xl font-bold text-gray-800">Наша команда</p>
      <p class="text-lg max-w-xl text-gray-800">
        Ми постійно долучаємо нових спеціалістів до співпраці. Кожен може бути корисним на своєму фронті. Наш фронт – ментальне здоров'я нашої нації!
      </p>
    </div>
    <div class="relative">
      <VueSlickCarousel
        ref="carousel"
        v-bind="settings"
      >
        <div
          v-for="slide in slides"
          :key="slide.id"
          class=""
        >
          <div class="flex flex-col md:flex-row">
            <div class="flex justify-center items-center">
              <img
                :src="slide.image"
                :alt="slide.name"
                class="rounded-full max-w-[100px] md:max-w-[620px] "
              />
              <p class="md:hidden text-2xl font-bold text-left text-[#36393f] p-2">
                {{ slide.name }}
              </p>
            </div>
            <div class="p-4">
              <p class="hidden md:block text-2xl font-bold text-left text-[#36393f] pb-2">
                {{ slide.name }}
              </p>
              <p class="flex-grow-0 flex-shrink-0 md:w-[645px] leading-6 md:leading-5 text-lg text-left text-gray-500 p-4">
                {{ slide.title }}
              </p>
            </div>
          </div>
        </div>

      </VueSlickCarousel>
      <div class="absolute w-[100dvw] flex justify-between top-[8%] md:top-[33%]">
        <button
          @click="next"
          class=""
        ><svg
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="w-[40px] md:w-[90px] h-[40px] md:h-[90px] relative left-1"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              d="M62.8025 16.8862L35.3975 45L62.8025 73.1137C63.2932 73.616 63.5679 74.2903 63.5679 74.9925C63.5679 75.6946 63.2932 76.3689 62.8025 76.8712C62.5642 77.1144 62.2798 77.3076 61.9659 77.4396C61.6519 77.5715 61.3149 77.6394 60.9744 77.6394C60.6339 77.6394 60.2968 77.5715 59.9829 77.4396C59.669 77.3076 59.3845 77.1144 59.1462 76.8712L29.9862 46.9631C29.4743 46.4379 29.1878 45.7334 29.1878 45C29.1878 44.2665 29.4743 43.5621 29.9862 43.0368L59.1406 13.1287C59.3791 12.8838 59.6642 12.6891 59.9792 12.5562C60.2941 12.4233 60.6325 12.3548 60.9744 12.3548C61.3162 12.3548 61.6546 12.4233 61.9696 12.5562C62.2845 12.6891 62.5696 12.8838 62.8081 13.1287C63.2988 13.631 63.5735 14.3053 63.5735 15.0075C63.5735 15.7096 63.2988 16.3839 62.8081 16.8862H62.8025Z"
              fill="#36393F"
            ></path>
          </svg></button>
        <button
          @click="prev"
          class=""
        ><svg
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class=" w-[40px] md:w-[90px] h-[40px] md:h-[90px] relative right-1"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              d="M27.1975 73.1138L54.6025 45L27.1975 16.8863C26.7068 16.384 26.4321 15.7097 26.4321 15.0075C26.4321 14.3054 26.7068 13.6311 27.1975 13.1288C27.4358 12.8856 27.7202 12.6924 28.0341 12.5604C28.3481 12.4285 28.6851 12.3606 29.0256 12.3606C29.3661 12.3606 29.7032 12.4285 30.0171 12.5604C30.331 12.6924 30.6155 12.8856 30.8538 13.1288L60.0138 43.0369C60.5257 43.5621 60.8122 44.2666 60.8122 45C60.8122 45.7335 60.5257 46.4379 60.0138 46.9632L30.8594 76.8713C30.6209 77.1162 30.3358 77.3109 30.0208 77.4438C29.7059 77.5767 29.3675 77.6452 29.0256 77.6452C28.6838 77.6452 28.3454 77.5767 28.0304 77.4438C27.7155 77.3109 27.4304 77.1162 27.1919 76.8713C26.7012 76.369 26.4265 75.6947 26.4265 74.9925C26.4265 74.2904 26.7012 73.6161 27.1919 73.1138H27.1975Z"
              fill="#36393F"
            ></path>
          </svg></button>
      </div>
    </div>
    <div class="py-4">
      <button class="btn-primery block mx-auto px-9 max-w-[315px] py-4 rounded-[100px] bg-[#b20000]">
        Підтримати нас
      </button>
    </div>
  </div>
</template>

<style scoped>
.slick-slider {
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 25%,
    rgba(0, 0, 0, 1) 75%,
    rgba(0, 0, 0, 0) 100%
  );
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 25%,
    rgba(0, 0, 0, 1) 75%,
    rgba(0, 0, 0, 0) 100%
  );
}

@media (min-width: 315px) and (max-width: 767px) {
  .slick-slider {
    mask-image: none;
  }
}
</style>