
<script>
import AppeaHistorylsList from '../components/appealHistory/ListByUser.vue'
export default {
  name: 'AppealsHistorylsList',
  components: { AppeaHistorylsList }
}
</script>

<template>
  <div class="overflow-auto">
    <AppeaHistorylsList />
  </div>
</template>