<script>

import fetchWithAuth from '@/lib/fetchWithAuth';
import { DateFormate, TimeFormate } from '@/lib/dateTime';
import { jwtDecode } from 'jwt-decode';
import ModalInfoVue from '../ModalInfo.vue';
import formatPhoneNumber from '@/lib/formatterPhone.js';
// import CildrenAges from '../appeal/components/_children_ages.vue';

export default {
  components: { ModalInfoVue },
  data() {
    return {
      appealId: '',
      appealStatusId: '',
      result: [],
      users: [],
      statuses: [],
      children_help: '',
      disabled: false,
      message: '',
      logo: '',
      action: '',
      event: '',
    }
  },
  methods: {
    async getAppeal(id) {
      try {
        const response = await fetchWithAuth(`/api/appeal-history/${id}`, {
          method: 'GET'
        });
        if (response.ok) {
          this.result = await response.json();
          this.children_help = this.result.appeal.children_help;

          this.appealStatusId = this.result.appealStatus.id;
          const id = jwtDecode(sessionStorage.getItem('token')).id;

          if (this.appealStatusId === 4 || id != this.result.userId) this.disabled = true;
          await this.getUsers(this.result.appeal.community.region_id)
          await this.getStatus();
        } else {
          const res = await response.json();
          if (res.statusCode === 403)
            this.$router.push('/admin/denied');
          if (res.statusCode === 404)
            this.$router.push('/admin/not-found');
        }
      } catch (err) {
        console.log(err);

      }
    },
    async getUsers(id) {
      try {
        const response = await fetchWithAuth(`/api/users/region/${id}`, {
          method: 'GET'
        });
        if (response.ok) {
          this.users = await response.json();
        } else {
          const res = await response.json();
          if (res.statusCode === 403)
            this.$router.push('/admin/denied');
          if (res.statusCode === 404)
            this.$router.push('/admin/not-found');
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getStatus() {
      try {
        const response = await fetchWithAuth(`/api/appeal-status`, {
          method: 'GET'
        });
        if (response.ok) {
          this.statuses = await response.json();
        } else {
          const res = await response.json();
          if (res.statusCode === 403)
            this.$router.push('/admin/denied');
          if (res.statusCode === 404)
            this.$router.push('/admin/not-found');
        }
      } catch (err) {
        console.log(err);
      }
    },
    async updateAppeal(id) {
      const data = {
        userId: this.result.userId,
        appealStatusId: this.result.appealStatus.id,
        comment: this.result.comment
      };
      try {
        const response = await fetchWithAuth(`/api/appeal-history/${id}`, {
          method: 'PATCH',
          body: JSON.stringify(data),
        });
        if (response.ok) {
          // alert('Запис успішно оновлен')
          this.message = 'Запис успішно оновлен';
          this.logo = require('../../assets/check.svg');
          this.event = true;
          this.showModal();
        } else {
          const res = await response.json();
          // alert(res.message);
          this.message = res.message;
          this.logo = require('../../assets/error.svg');
          this.event = false;
          this.showModal();
        }
      } catch (err) {
        console.log(err);
      }
    },
    toDate(date) {
      return DateFormate(date);
    },
    toTime(date) {
      return TimeFormate(date);
    },
    formatPhone(phone) {
      if (phone) return formatPhoneNumber(phone);
    },
    auto_grow(ref) {
      const element = this.$refs[ref];
      if (element) {
        element.style.height = "5px";
        element.style.height = (element.scrollHeight) + "px";
      }

    },
    onChageUser(id) {
      const user_id = jwtDecode(sessionStorage.getItem('token')).id
      if (user_id != id) {
        this.result.appealStatus.id = 2
      } else {
        this.result.appealStatus.id = this.appealStatusId;
      }
    },
    showModal() {
      this.$refs.modalInfo.onOpen();
    },
    hideModal(e) {
      if (e)
        this.$router.push('/admin/appeals-history')
    },
    ageMap(key) {
      if (key === '0_3') return 'До 3 років';
      else if (key === '3_7') return '3-7 років';
      else if (key === '7_14') return '7-14 років';
      else return '14-17 років';
    }
  },
  async mounted() {

    this.appealId = location.pathname.split('/').pop();
    this.getAppeal(this.appealId).then(() => {
      this.auto_grow('autoGrowTextArea');
      this.auto_grow('autoGrowTextReport');
    });

    window.addEventListener('resize', () => {
      this.auto_grow('autoGrowTextArea');
      this.auto_grow('autoGrowTextReport');
    });
    window.addEventListener('input', () => {
      this.auto_grow('autoGrowTextArea');
      this.auto_grow('autoGrowTextReport');
    })
  }
}
</script>

<template>
  <div class=" border-gray-900/10 pb-4">

    <div class="mt-2 mx-auto max-w-full grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">

      <div class="sm:col-span-3">
        <label
          for="first-name"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Імʼя заявника</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            id="first-name"
            v-if="result.appeal"
            v-model="result.appeal.name"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div class="sm:col-span-3">
        <label
          for="last-name"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Призвіще</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            id="last-name"
            v-if="result.appeal"
            v-model="result.appeal.last_name"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div class="sm:col-span-3">
        <label
          for="phone"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Телефон</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            v-if="result.appeal"
            :value="formatPhone(result.appeal.phone)"
            id="phone"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div class="sm:col-span-3">
        <label
          for="assistance"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Допомога</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            id="assistance"
            v-if="result.AssistanceTypes"
            v-model="result.AssistanceTypes.description"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div
        v-if="children_help"
        class="sm:col-span-3"
      >
        <label
          for="assistance"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Кількісь дітей</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            id="assistance"
            v-if="result.appeal"
            v-model="result.appeal.children_count"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div
        v-if="children_help"
        class="sm:col-span-3"
      >
        <label
          for="assistance"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Вік дітей</label>
        <div
          v-for="ages of result.appeal.children_ages"
          :key=ages
          class=""
        >
          <p
            v-for="(age,key) in ages"
            :key="key"
            class="block w-full text-right px-[8px] mt-[8px] rounded-md border-0 py-1.5 bg-[#eeeded] text-[#aeaeae] shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
          >
            {{ ageMap(key) }}</p>
        </div>
      </div>

      <div class="sm:col-span-2">
        <label
          for="community_name"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Громада</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            v-if="result.appeal"
            v-model="result.appeal.community.name"
            id="community_name"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div class="sm:col-span-2">
        <label
          for="adminCenter"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Адмін центр</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            v-if="result.appeal"
            v-model="result.appeal.community.adminCenter"
            id="adminCenter"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div class="sm:col-span-2">
        <label
          for="region"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Область</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            v-if="result.appeal"
            v-model="result.appeal.community.region"
            id="region"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div class="sm:col-span-full">
        <label
          for="description"
          class="mb-2 block text-sm font-medium leading-6 text-gray-900"
        > Опис проблеми</label>
        <textarea
          v-if="result.appeal"
          readonly
          disabled
          ref="autoGrowTextArea"
          v-model="result.appeal.description"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
        ></textarea>
      </div>

      <div class="sm:col-span-3">
        <label
          for="country"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Перенаправити</label>
        <div class="mt-2">
          <select
            id="user"
            v-if="result"
            @change="onChageUser(result.userId)"
            v-model="result.userId"
            :disabled="disabled"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
          >
            <option
              v-for="user of users"
              :key="user.id"
              :value="user.id"
            >{{ user.name }}</option>
          </select>
        </div>
      </div>
      <div class="sm:col-span-3">
        <label
          for="status"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Змінити статус</label>
        <div class="mt-2">
          <select
            id="status"
            :disabled="disabled"
            v-if="result.appealStatus"
            v-model="result.appealStatus.id"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
          >
            <option
              v-for="status of statuses"
              :key="status.id"
              :disabled="status.id < result.appealStatus.id || status.id ===2"
              :value="status.id"
            >{{ status.title }}</option>
          </select>
        </div>
      </div>

      <div class="sm:col-span-full">
        <label class="mb-2 block text-sm font-medium leading-6 text-gray-900">Важлива інформація:</label>
        <textarea
          placeholder="Важлива інформація"
          :disabled="disabled"
          v-model="result.comment"
          ref="autoGrowTextReport"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
        ></textarea>
      </div>
    </div>
    <div
      class="flex justify-end pt-4"
      v-if="!disabled"
    >
      <button
        class="flex justify-center items-center flex-grow-0 flex-shrink-0 h-12 relative gap-2.5 px-9 py-4 rounded-[100px] bg-[#b20000] font-bold text-center text-[#fbfbfb]"
        @click="updateAppeal(result.id)"
      >Зберегти</button>
    </div>
    <ModalInfoVue
      ref="modalInfo"
      :infoText="message"
      @close="hideModal(event, action)"
      :logo="logo"
    />
  </div>
</template>
<style scoped>
textarea {
  resize: none;
  overflow: hidden;
  padding: 10px;
}
label {
  opacity: 0.8;
  font-family: 'Liberation', sans-serif;
  font-size: 14px;
  color: #6b7280;
}
</style>

