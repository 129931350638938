<script>
import File from '../components/File.vue';

export default {
  components: { File },
};

</script>

<template>
  <div id="users">
    <File />
  </div>

</template>