
<script>
import { jwtDecode } from 'jwt-decode';
import ModalInfo from './ModalInfo.vue';

// import { mapActions } from 'vuex'

export default {
  components: { ModalInfo },
  name: 'LoginForm',
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
      userName: '',
      message: '',
      logo: '',
      event: false,
      action: ''
    };
  },
  methods: {
    // ...mapActions(['setUser']),
    async login() {

      try {
        const response = await fetch('/api/auth/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: this.email, password: this.password }),
          credentials: 'include'
        });

        if (response.ok) {
          const data = await response.json();
          sessionStorage.setItem('token', data.token);
          const roles = jwtDecode(data.token).roles;
          roles.forEach(role => {
            if (role.name === 'USER') location.assign('/admin/appeals-history');
            else location.assign('/admin/home');
            // console.log(role.name);

          })
        } else {
          const errorData = await response.json();
          if (errorData.message === 'Invalid email or access denied') {
            errorData.message = 'Невірний email, або доступ заборонений';
          }
          // alert('Error: ' + errorData.message);
          this.event = false;
          this.message = errorData.message;
          this.logo = require('../assets/error.svg');
          this.showModal();
        }
      } catch (err) {
        // alert('Login failed');
        this.event = false;
        this.message = err;
        this.logo = require('../assets/error.svg');
        this.showModal();
      }
    },
    showModal() {
      this.$refs.modalInfo.onOpen();
    },
    hideModal() {
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  }
};
</script>

<template>

  <div class="flex min-h-full flex-1 flex-col justify-center px-6 pt-[25%] md:pt-[15%] lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm self-center">
      <div class="flex justify-center items-center">
        <router-link to="/">
          <img
            src="../assets/about/logo.svg"
            alt="ENOUGH"
            height="56"
            width="66"
          >
        </router-link>
        <h2 class="pl-4 text-3xl font-bold text-[#6B7280] sm:text-4xl tracking-widest">Вхід</h2>
      </div>
    </div>
    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form @submit.prevent="login">
        <div class="relative z-0 w-full mb-6 group">
          <input
            id="email"
            v-model="email"
            type="email"
            autocomplete="email"
            required=""
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
            placeholder=" "
          />
          <label
            for="floating_email"
            class="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#B20000] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Email</label>
        </div>
        <div class="relative z-0 w-full mb-6 group">
          <input
            id="password"
            :type="showPassword ? 'text' : 'password'"
            v-model="password"
            autocomplete="current-password"
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
            placeholder=" "
            required
          />
          <label
            for="password"
            class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#B20000]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Пароль</label>
          <button
            type="button"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            @click="togglePasswordVisibility"
          >
            <img
              v-if="showPassword"
              src="../assets/close-eye.svg"
              alt=""
            >
            <img
              v-else
              src="../assets/open_eye.svg"
              alt=""
            >
          </button>
        </div>

        <div class="mt-8">
          <button
            type="submit"
            class="btn flex w-full justify-center rounded-md bg-[#B20000] px-3 p-3 md:p-1.5 text-md font-semibold leading-8"
          >Увійти</button>
        </div>
      </form>
      <span class="flex my-4 justify-center text-gray-400 text-sm">Ви ще не реєструвались?
        <router-link
          class="pl-2 underline hover:no-underline"
          to="/register"
        >Тисніть тут.</router-link>
      </span>
    </div>
    <ModalInfo
      ref="modalInfo"
      :infoText="message"
      @close="hideModal(event, action)"
      :logo="logo"
    />
  </div>
</template>

<style scoped>
* {
  font-family: 'Liberation Sans' sans-serif;
}
.btn:hover {
  color: #b20000;
  border: 2px solid #b20000;
  background: #fbfbfb;
  transition: all 0.5s ease-in-out;
}
.btn {
  color: #fbfbfb;
  border: 2px solid #b20000;
  background: #b20000;
  transition: all 0.5s ease-in-out;
}
</style>


