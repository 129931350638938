<script>
import Calendar from '../components/calendar/Calendar.vue';

export default {
  components: { Calendar },
};
</script>

<template>
  <div id="calendar">
    <Calendar />
  </div>

</template>