
<script>
import ListByAppealId from '../components/appealHistory/ListByAppealId.vue'
export default {
  name: 'AppealsHistorylsList',
  components: { ListByAppealId }
}
</script>

<template>
  <div class="overflow-auto">
    <ListByAppealId />
  </div>
</template>