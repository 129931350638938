<script >

import refresh from '@/lib/refreshTokin';
import fetchWithAuth from '@/lib/fetchWithAuth';
import { DateFormate, TimeFormate } from '@/lib/dateTime';
import formatPhoneNumber from '@/lib/formatterPhone.js';

export default {
  components: {},
  data() {
    return {
      id: '',
      appeal: [],
      reports: [],
      dateTime: ''
    }
  },
  methods: {
    async getAppeal(id) {
      try {
        const response = await fetchWithAuth(`/api/appeals/report/${id}`, {
          method: 'GET',
        });
        if (response.ok) {
          this.id = id;
          this.appeal = await response.json();

          this.getDate(this.appeal.createdAt);
          return this.reports = this.appeal.reports || []; // Возвращаем массив report
        } else {
          const res = await response.json();
          if (res.statusCode === 403) this.$router.push('/admin/denied');
          else if (res.statusCode === 500) this.$router.push('/admin/not-found');
        }
      } catch (err) {
        console.log(err);
        location.assign('/login');
      }
    },
    getDate(date) {
      this.dateTime = `${DateFormate(date)} ${TimeFormate(date)}`
    },
    formatPhone(phone) {
      if (phone) return formatPhoneNumber(phone);
    }
  },
  watch: {
  },
  mounted() {
    refresh().then(newToken => {
      sessionStorage.setItem("token", newToken);
      const segments = window.location.href.split('/');
      this.id = segments.pop();
      this.getAppeal(this.id);
    })
  }
}
</script>

<template>
  <div class=" bg-white shadow-md mx-auto overflow-auto flex flex-col justify-between">

    <div class="p-4 md:py-4 md:pl-20 md:pr-8">
      <p><strong>Імʼя:</strong> {{ appeal.name }}</p>
      <p><strong>Призвіще:</strong> {{ appeal.last_name }}</p>
      <!-- <p><strong>Вік:</strong> {{ appeal.age }}</p> -->
      <p><strong>Телефон:</strong> {{ formatPhone(appeal.phone) }}</p>
      <div><strong>Питання допомоги:</strong>
        <p>
          {{ appeal.assistans_type }}
        </p>
      </div>
      <p><strong>Дата звернення:</strong> {{ dateTime }}</p>
    </div>

    <h1
      v-if="reports.length"
      class="font-semibold uppercase text-xl text-center py-4"
    >Звіти про виконання</h1>
    <h1
      v-if="reports.length < 1"
      class="font-semibold uppercase text-xl text-center py-4"
    >Немає доступних звітів</h1>

    <ul
      v-if="reports.length"
      class="flex-1"
    >
      <li
        v-for="(report, index) in reports"
        :key="index"
      >
        <p class="p-4 md:py-8 md:pl-20 md:pr-8">{{ report.content }}</p>
        <div class="flex justify-around my-4">
          <div>
            <p><strong>Виконавець:</strong> {{ report.author }}</p>
            <p class="text-[11px]">{{ report.responsability }}</p>
          </div>
          <p><strong>Дата:</strong> {{ report.date }}</p>
        </div>

        <hr
          class=""
          v-if="reports.length - 1"
        >
      </li>
    </ul>

    <p
      v-else
      class="mt-auto text-center py-4"
    > </p>
  </div>
</template>
