import Vue from 'vue';
import App from './App.vue';
import Router from 'vue-router';
import VueMask from 'v-mask';
import VueMeta from 'vue-meta';
import routes from './routes/routes.js';
import admin from "@/middleware/admin.js";
import user from "@/middleware/user.js";
import auth from "@/middleware/auth.js";
import store from './store'

import '@/assets/css/main.css'
// Vue.config.productionTip = false

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { ...savedPosition, behavior: 'smooth' };
    } else if (to.hash) {
      return { selector: to.hash, behavior: 'smooth' };
    } else {
      return { x: 0, y: 0, behavior: 'smooth' };
    }
  },
});

Vue.use(VueMask);
Vue.use(Router);
Vue.use(VueMeta, {
  keyName: 'head'
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app');

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.admin)) {
    auth(to, from, next);
  } else if (to.matched.some((record) => record.meta.user)) {
    admin(to, from, next);
  } else if (to.matched.some((record) => record.meta.auth)) {
    user(to, from, next);
  } else {
    next();
  }
});
