const formatPhoneNumber = (phoneNumber) => {
  // Убедимся, что номер начинается с '380'
  if (!phoneNumber.startsWith('380')) {
    throw new Error('Phone number must start with "380".');
  }
  if (phoneNumber.length < 12) {
    throw new Error('Phone number must have at least 12 digits.');
  }

  // Разделяем номер
  const countryCode = '+38';
  const areaCode = phoneNumber.slice(2, 5);
  const part1 = phoneNumber.slice(5, 8);
  const part2 = phoneNumber.slice(8, 10);
  const part3 = phoneNumber.slice(10, 12);

  return `${countryCode}(${areaCode}) ${part1}-${part2}-${part3}`;
};

module.exports = formatPhoneNumber;