<template>
  <div class="footer w-full px-8 py-6 lg:flex justify-center items-center">
    <p class="mb-2 lg:mb-0">© Copyright 2024</p>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>