
<script>
import AppealsListByRegion from '../components/appeal/AppealsListByRegion.vue'
export default {
  name: 'Appeal',
  components: { AppealsListByRegion }
}
</script>

<template>
  <div class="overflow-auto">
    <AppealsListByRegion />
  </div>
</template>