
<script>
import AppealHistoryEdit from '../components/appealHistory/_edit.vue'
export default {
  name: 'appealHistoryEdit',
  components: { AppealHistoryEdit }
}
</script>

<template>
  <div class="overflow-auto">
    <AppealHistoryEdit />
  </div>
</template>