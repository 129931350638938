
<script>
import Community from '../components/communities/CommunityList.vue'
export default {
  components: { Community }
}
</script>

<template>
  <div class="overflow-auto">
    <Community />
  </div>
</template>