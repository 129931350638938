<script>
export default {
  data() {
    return {
      isActive: true
    }

  }, methods: {
    onClick(isActive) {
      this.isActive = isActive;
    }
  }
}
</script>
<template>
  <div
    class="flex-grow-0 flex-shrink-0"
    id="activities"
  >
    <p class="text-[40px] font-bold text-center text-[#36393f] py-4">
      Заходи
    </p>
    <div class="flex flex-wrap justify-center items-start space-x-[-20px]">
      <div class="flex relative after:absolute after:bottom-0 after:left-[20px] after:h-[2px] after:w-[90%] after:bg-[#b20000]">
        <div
          :class="!isActive ? '' : 'bg-[#b20000] text-[#fbfbfb]'"
          class="flex cursor-pointer justify-center items-center mr-1 overflow-hidden px-8 py-2 rounded-[90px]"
          @click="onClick(!isActive)"
        >
          <p class="text-2xl font-bold">Анонси</p>
        </div>
        <div
          :class="isActive ? ']' : 'bg-[#b20000] text-[#fbfbfb]'"
          class="flex cursor-pointer justify-center items-center ml-1  overflow-hidden px-8 py-2 rounded-[90px]"
          @click="onClick(!isActive)"
        >
          <p class="text-2xl font-bold">Ініціативи</p>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-10 py-20">
        <div class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative overflow-hidden gap-5 pl-4">
          <img :src="isActive ? require('../../assets/about/activity-1.png'): require('../../assets/about/activity-4.png')" />
          <div class="flex flex-wrap md:flex-nowrap justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
            <p class="pb-4 text-xl font-bold text-left text-gray-500">
              Скажи #ДОСИТЬ! Зустрічу Градизькій громаді
            </p>
            <button class="btn-default flex justify-center items-center flex-grow-0 
          flex-shrink-0 relative gap-2.5 px-2 md:px-9 md:py-4 rounded-[100px]">
              Читати далі
            </button>
          </div>
        </div>
        <div
          class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative overflow-hidden gap-5 pl-4"
          pl-4
        >
          <img :src="isActive ? require('../../assets/about/activity-2.png'): require('../../assets/about/activity-5.png')" />
          <div class="flex flex-wrap md:flex-nowrap justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
            <p class="pb-4 text-xl font-bold text-left text-gray-500">
              Скажи #ДОСИТЬ! Зустріч у Пришибській громаді
            </p>
            <button class="btn-default flex justify-center items-center flex-grow-0 
          flex-shrink-0 relative gap-2.5 px-2 md:px-9 md:py-4 rounded-[100px]">
              Читати далі
            </button>
          </div>
        </div>
        <div class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative overflow-hidden gap-5 pl-4">
          <img :src="isActive ? require('../../assets/about/activity-3.png'): require('../../assets/about/activity-6.png')" />
          <div class="flex flex-wrap md:flex-nowrap justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
            <p class="pb-4 text-xl font-bold text-left text-gray-500">
              Скажи #ДОСИТЬ! Зустріч у Недогарській громаді
            </p>
            <button class="btn-default flex justify-center items-center flex-grow-0 
          flex-shrink-0 relative gap-2.5 px-2 md:px-9 md:py-4 rounded-[100px]">
              Читати далі
            </button>
          </div>
        </div>
      </div>
      <button class="btn-primery block mx-auto px-9 max-w-[315px] py-4 rounded-[100px] bg-[#b20000]">
        Переглянути більше
      </button>

    </div>
  </div>
</template>

<style scoped>
img {
  width: 100%;
}
</style>