<script>
import refresh from "../../lib/refreshTokin";
import fetchWithAuth from "@/lib/fetchWithAuth";
import Capitalize from "@/lib/capitalize.js";
import { jwtDecode } from "jwt-decode";

export default {
  data() {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const token = sessionStorage.getItem('token');
    const decodedToken = jwtDecode(token);

    const days = [
      "Неділя",
      "Понеділок",
      "Вівторок",
      "Середа",
      "Четверг",
      "Пятниця",
      "Субота",
    ].map((day) => ({
      fullName: day,
      shortName: day.slice(0, 3),
    }));

    return {
      currentMonth,
      currentYear,
      userRole: decodedToken.roles[0].name,
      days,
      events: [],
      calendar: [],
      appeals: [],
      regionId: decodedToken.regionId,
    };
  },
  methods: {
    generateCalendar(year, month) {
      const firstDayOfMonth = new Date(year, month, 1).getDay();
      const lastDateOfMonth = new Date(year, month + 1, 0).getDate();

      const calendar = [];
      let week = [];

      for (let i = 0; i < firstDayOfMonth; i++) {
        week.push({ date: null, events: [] });
      }

      for (let date = 1; date <= lastDateOfMonth; date++) {
        const dayEvents = this.events.filter(
          (event) =>
            event.date.getFullYear() === year &&
            event.date.getMonth() === month &&
            event.date.getDate() === date
        );
        week.push({ date, events: dayEvents });

        if (week.length === 7) {
          calendar.push(week);
          week = [];
        }
      }

      if (week.length > 0) {
        while (week.length < 7) {
          week.push({ date: null, events: [] });
        }
        calendar.push(week);
      }

      return calendar;
    },
    async prevMonth() {
      if (this.currentMonth === 0) {
        this.currentMonth = 11;
        this.currentYear--;
      } else {
        this.currentMonth--;
      }
      await this.updateCalendar();
    },
    async nextMonth() {
      if (this.currentMonth === 11) {
        this.currentMonth = 0;
        this.currentYear++;
      } else {
        this.currentMonth++;
      }
      await this.updateCalendar();
    },
    getMonthDateRange(year, month) {
      const startDate = new Date(year, month, 1);
      const endDate = new Date(year, month + 1, 0);
      return {
        startDate: startDate.toISOString().split("T")[0],
        endDate: endDate.toISOString().split("T")[0],
      };
    },
    async updateCalendar() {
      const { startDate, endDate } = this.getMonthDateRange(
        this.currentYear,
        this.currentMonth
      );
      if (this.userRole === 'USER') {
        await this.getAppealsByStatusByMonthByUser(startDate, endDate);
      } else {
        await this.getAppealsByStatusByMonth(startDate, endDate);
      }

      this.calendar = this.generateCalendar(this.currentYear, this.currentMonth);
    },
    async getAppealsByStatusByMonth(startDate, endDate) {
      try {
        refresh().then(newToken => {
          sessionStorage.setItem('token', newToken);
        });

        const res = await fetchWithAuth(
          `/api/appeals/grouped/${startDate}/${endDate}`,
          { method: "GET" }
        );
        if (res.ok) {
          const data = await res.json();
          this.events = data.map((appeal) => ({
            date: new Date(appeal.day),
            title: appeal.title,
            count: appeal.count,
            status_id: appeal.status_id,
          }));
        }
      } catch (err) {
        console.error("Error fetching appeals:", err);
      }
    },

    async getAppealsByStatusByMonthByUser(startDate, endDate) {
      try {
        const userId = jwtDecode(sessionStorage.getItem('token')).id;

        refresh().then(newToken => {
          sessionStorage.setItem('token', newToken);
        });
        jwtDecode(sessionStorage.getItem('token')).id;
        const res = await fetchWithAuth(
          `/api/appeal-history/grouped/${userId}/${startDate}/${endDate}`,
          { method: "GET" }
        );
        if (res.ok) {
          const data = await res.json();
          this.events = data.map((appeal) => ({
            date: new Date(appeal.day),
            title: this.setStatusName(appeal.appealStatusId),
            count: appeal.count,
            status_id: appeal.appealStatusId
          }));
        }
      } catch (err) {
        console.error("Error fetching appeals:", err);
      }
    },
    setStatusName(status_id) {
      const statuses = ['Нове', 'Перенаправлено', 'В роботі', 'Виконано'];
      let statusName = '';
      statuses.forEach((status, key) => {
        if (key === status_id - 1) {
          statusName = `${status}`
        }
      });
      return statusName;
    },
    getMonthName(monthIndex) {
      return new Date(this.currentYear, monthIndex).toLocaleString("Uk-uk", {
        month: "long",
      });
    },
    onCapitalize(text) {
      return Capitalize(text);
    },
    addEvent(day) {
      console.log(new Date(this.currentYear, this.currentMonth, day).toDateString());
    },
    setBadge(status_id) {
      const statuses = ['new', 'forward', 'work', 'finish'];
      let badges = '';
      statuses.forEach((status, key) => {
        if (key === status_id - 1) {
          badges = `badge-${status}`
        }
      });
      return badges;
    }
  },

  mounted() {
    this.updateCalendar();
  },
};
</script>

<template>
  <div class="pb-4 pt-6 min-h-[75dvh] mx-auto">
    <div class="header flex justify-center border-b p-2">

      <div class="flex justify-center items-center pb-4">
        <button
          class="p-1"
          @click="prevMonth"
        >
          <img
            src="../../assets/admin-menu/chevron-l.svg"
            alt=""
          >
        </button>
        <span class="flex-grow-0 flex-shrink-0 px-3 text-2xl font-bold text-left text-[#252525]">
          {{ onCapitalize(getMonthName(currentMonth)) }} {{ currentYear }}
        </span>
        <button
          class="p-1"
          @click="nextMonth"
        >
          <img
            src="../../assets/admin-menu/chevron-r.svg"
            alt=""
          >
        </button>
      </div>
    </div>
    <div class="relative overflow-x-auto bg-[#FAFAFA] rounded shadow">

      <table class="w-full">
        <thead>
          <tr>
            <th
              v-for="(day, index) in days"
              :key="index"
              class="p-2 border-r min-h-20 xl:w-40 lg:w-30 md:w-30 w-20 min-w-20 flex-grow-0 flex-shrink-0 text-base font-bold text-left text-black bg-[#FAFAFA]"
            >
              <span class="xl:block lg:block md:block sm:block hidden">
                {{ day.fullName }}
              </span>
              <span class="xl:hidden lg:hidden md:hidden sm:hidden block">
                {{ day.shortName }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="h-20 pl-2"
            v-for="week in calendar"
            :key="week[0]?.date"
          >
            <td
              v-for="day in week"
              :key="day.date"
              :class="new Date(currentYear, currentMonth, day.date).toDateString() == new Date().toDateString() ? 'bg-sky-200':''"
              class="border p-1 min-h-40 xl:w-40 lg:w-30 md:w-30 w-20 min-w-20 overflow-auto transition cursor-pointer duration-500 ease hover:bg-[#E8E8E8]"
            >
              <div class="flex flex-col justify-between  min-h-40 xl:w-40 lg:w-30 md:w-30 sm:w-full min-w-20 overflow-hidden ">
                <div class="top h-5 w-full hover:text-white">
                  <span
                    class=""
                    :class="new Date(currentYear, currentMonth, day.date).toDateString() == new Date().toDateString() ?'':'text-gray-500'"
                  >{{ day.date }}</span>

                </div>
                <div
                  class="
  
                    h-30
                    py-1
                    w-full
                    cursor-pointer"
                  @click="addEvent(day.date)"
                >

                  <div
                    v-for="(appeal,index) in day.events"
                    :key="index"
                  >
                    <p
                      class="my-1"
                      :class="setBadge(appeal.status_id)"
                    >
                      {{ appeal.title }}: {{ appeal.count }}</p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<style scoped>
* {
  font-family: 'Liberation', sans-serif;
}
.wrapper-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}
</style>
