<script>
import { mapState } from 'vuex'

import Sidebar from './Sidebar'
import Navbar from './Navbar'
import Footer from './Footer'

export default {
  name: 'Dashboard',
  computed: {
    ...mapState(['sideBarOpen']),
  },
  components: {
    Sidebar,
    Navbar,
    Footer
  }
}
</script>

<template>
  <div
    class="leading-normal tracking-normal"
    id="main-body"
  >
    <div class="flex flex-wrap">
      <Sidebar />
      <div
        class="w-full pl-0 lg:pl-48 min-h-screen overflow-auto"
        :class="sideBarOpen ? 'overlay' : ''"
        id="main-content"
      >
        <Navbar />
        <div class="dashboard px-8 pt-2 pb-6 rounded-b-[25px] mr-0 md:mr-8">
          <transition name="fade">
            <router-view />
          </transition>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>
<style scoped>
.dashboard {
  background-color: rgba(245, 245, 245, 1);
}
</style>