<template>
  <div class="flex mt-2 justify-end">
    <nav
      v-show="pages > 1"
      aria-label="Page navigation example"
    >
      <ul class="flex items-center -space-x-px h-8 text-sm">
        <li class=" text-[#252525]/50 mr-2">{{ currentPage }} з {{ pages }}</li>
        <li>
          <a
            href="#"
            @click.prevent="previusPage()"
            class="flex items-center justify-center h-6 w-6 rounded-sm border border-[#252525]/10 text-xs text-[#252525]/50 mx-1"
          >
            <span class="sr-only">Previous</span>
            <svg
              class="w-2.5 h-2.5 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
          </a>
        </li>

        <li
          v-for="page of arr"
          :key="page"
        >
          <a
            href="#"
            @click.prevent="onClickPage(page)"
            :class="currentPage === page ? 'z-10 flex items-center justify-center h-6 w-6 relative overflow-hidden gap-2.5 rounded-sm bg-[#b20000] ctext-xs text-[#fbfbfb] mx-1':'z-10 flex items-center justify-center h-6 w-6 rounded-sm border border-[#252525]/10 text-xs text-[#252525]/50 mx-1'"
          >{{ page}}</a>
        </li>
        <li>
          <a
            href="#"
            @click.prevent="nextPage()"
            class="flex items-center justify-center h-6 w-6 rounded-sm border border-[#252525]/10 text-xs text-[#252525]/50 mx-1"
            :class="currentPage === pages ? 'disabled':''"
          >
            <span class="sr-only">Next</span>
            <svg
              class="w-2.5 h-2.5 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    'pages': {
      type: Number,
      default: 1
    },
    'maxShow': {
      type: Number,
      default: 7
    }
  },
  data() {
    return {
      arr: [],
      currentPage: 1,
      lastPage: '',
      firstPage: 1
    }
  },
  methods: {
    onClickPage(n) {
      if (n > 0 && n <= this.pages) this.currentPage = n;
      this.$emit('click', n);
      return this.currentPage
    },
    nextPage() {
      if (this.lastPage <= this.pages - 1) {
        this.lastPage = this.arr[this.arr.length - 1];
        this.currentPage = this.currentPage + 1
        this.arr.push(this.lastPage + 1); // Добавляем новый элемент
        this.arr.shift(); // Удаляем первый элемент
        this.$emit('click', this.currentPage);
        this.lastPage = this.lastPage + 1
      }

    },
    previusPage() {
      if (this.arr[0] >= 2) {
        this.firstPage = this.arr[0];
        this.lastPage = this.arr[this.maxShow - 2];
        this.arr.unshift(this.firstPage - 1); // Добавляем новый элемент в начало
        this.arr.pop(); // Удаляем последний элемент
        this.currentPage = this.currentPage - 1
        this.$emit('click', this.currentPage);
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.pages < this.maxShow) {
        for (let i = 1; i <= this.pages; i++) {
          this.arr.push(i)
        }
      } else {
        for (let i = 1; i <= this.maxShow; i++) {
          this.arr.push(i)
        }
      }
    }, 500);
  }
}
</script>>
<style>
a.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
</style>