
<script >
import Capitalize from '../../lib/capitalize'
import Communities from '../appeal/_community.vue';
import Assistance from '../appeal/_assistance.vue';
import ModalInfo from '../ModalInfo.vue';
import CildrenAges from '../appeal/components/_children_ages.vue';
export default {
  components: { Communities, Assistance, ModalInfo, CildrenAges },
  data() {
    return {
      name: '',
      last_name: '',
      community_id: '',
      region_id: '',
      regions: [],
      phone: '',
      communiction: '',
      assistans_type: '',
      description: '',
      children_help: false,
      children_count: 0,
      children_ages: [],
      // date_communication: '',
      creator: '',
      errNumberPhone: false,
      status_id: 1,
      message: '',
      logo: require('@/assets/error.svg'),
      event: false,
      isAnonymous: false,
      isCallCenter: true
    }
  },
  methods: {
    async submit() {
      // Создаем объект данных
      this.data = {
        creator: this.creator,
        date_communication: this.date_communication,
        name: this.name,
        last_name: this.last_name,
        community_id: this.communityId,
        region_id: this.region_id,
        phone: this.formatPhoneNumber(this.phone),
        communiction: this.communiction || 'Телефон',
        assistans_type: this.assistans_type,
        description: this.description,
        children_help: this.children_help,
        children_count: this.children_count,
        children_ages: this.children_help ? this.children_ages : [],
        status_id: this.status_id,
      };

      // Маппинг полей для отображения

      const fieldNamesMap = {
        creator: 'ПІБ працівника кол центру',
        date_communication: 'Дата/час',
        name: 'Ім\'я',
        last_name: 'Прізвище',
        community_id: 'Територіальна громада',
        phone: 'Телефон',
        // communiction: 'Спосіб зв\'язку',
        assistans_type: 'З чим потрібна допомога',
        description: 'Опис проблеми',
        children_count: 'Кількість дітей',
        children_ages: 'Вік дітей',
      };

      if (this.isAnonymous) {
        this.data.name = 'Анонімний';
        this.data.creator = 'Анонімний';
        delete this.data.last_name;
        delete fieldNamesMap.name;
        delete fieldNamesMap.last_name;
      }
      if (!this.children_help) {
        delete this.data.children_count;
        delete this.data.children_ages;
        delete fieldNamesMap.children_count;
        delete fieldNamesMap.children_ages;
      }
      if (!this.isCallCenter) {
        delete fieldNamesMap.date_communication;
        delete fieldNamesMap.creator;
      }
      if (this.isCallCenter && this.isAnonymous) {
        this.data.creator = this.creator;
      }

      // Проверка на заполненность полей
      const missingFields = Object.entries(this.data)
        .filter(([key, value]) => (value === undefined || value === '') && fieldNamesMap[key] !== undefined)
        .map(([key]) => fieldNamesMap[key]);

      if (missingFields.length) {
        this.message = `<span class="red">Помилка.</span> Не заповнені наступні поля:<br> ${missingFields.join(', ')}.`;
        this.logo = require('@/assets/error.svg');
        this.showModal();
        return false;
      }

      if (this.children_count < 0 || this.children_count > 15) {
        this.message = '<span class="red">Помилка.</span> Кількість дітей від 0 до 15';
        this.logo = require('@/assets/error.svg');
        this.showModal();
        return;
      }

      // Проверка номера телефона
      this.onValidatePhoneNumber(this.phone);
      if (this.errNumberPhone) {
        this.message = '<span class="red">Помилка.</span> Невірний номер телефону';
        this.logo = require('@/assets/error.svg');
        this.showModal();
        return;
      }

      // Отправка данных
      await this.addAppeal(this.data);

    },
    async addAppeal(data) {
      const response = await fetch(`/api/appeals`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      try {
        if (response.ok) {
          this.appeal = await response.json();
          this.logo = require('@/assets/check.svg');
          this.message = 'Звернення успішно збережено\n';
          this.event = true;
          this.showModal();
          setTimeout(() => {
            this.$router.push('/admin/appeals');
          }, 2500);
        } else {
          this.appeal = await response.json();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getRegions() {
      const response = await fetch(`/api/communities/regions/active`);
      try {
        if (response.ok) {
          this.regions = await response.json();
        }
      } catch (err) {
        this.message = `${err}`;
        this.logo = require('@/assets/error.svg');
        this.showModal();
      }
    },
    filterInput(input) {
      let filteredInput = '';
      const regex = new RegExp(/^[а-яА-ЯёЁіїґєЄ'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredInput += char;
        }
      }
      this.name = Capitalize(filteredInput);
    },

    filterName(input, keyName) {
      let filteredName = '';
      const regex = new RegExp(/^[а-яА-ЯёЁіїґєЄ'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredName += char;
        }
      }
      this[keyName] = filteredName.toUpperCase();
    },

    filterText(input) {
      let filteredText = '';
      const regex = new RegExp(/^[!?.,а-яА-ЯёЁіїґє'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredText += char;
        }
      }
      this.description = Capitalize(filteredText);
    },

    formatPhoneNumber(phone) {

      const cleanedPhone = phone.replace(/[^\d]/g, '');
      return cleanedPhone;
    },

    onValidatePhoneNumber(phoneNumber) {
      // Обновленное регулярное выражение для проверки полного формата
      // const regex = /^38\(0[3-9]\d\)\d{3}-\d{2}-\d{2}$/;
      const regex = /^38\((039|067|068|096|097|098|050|066|095|099|063|073|077|093|091|092|089|094)\)\d{3}-\d{2}-\d{2}$/;


      // Проверяем строгое соответствие формату
      if (regex.test(phoneNumber)) {
        this.errNumberPhone = false; // Номер корректный
      } else {
        this.errNumberPhone = true; // Номер некорректный
      }

      return this.errNumberPhone;
    },

    onMessangerSelected(value) {

      this.communiction = value;
    },
    onAssistanceSelected(value) {

      this.assistans_type = value;
    },
    onCommunitySelected(value) {

      this.region_id = value;
    },
    onAgeChecked(value) {
      this.children_ages = value;
    },
    onDateTimeSelected(value) {

      this.date_communication = value;
    },
    showModal() {
      this.$refs.modalInfo.onOpen();
    },
    hideModal(event) {
      if (event) {
        this.clearData();
      }
    },
    clearForm() {
      this.name = '';
      this.last_name = '';
      this.phone = '';
      this.communication = null;
      this.assistance_type = null;
      this.description = '';
      this.children_help = false;
      this.children_count = false;
      this.date_communication = null;
    },
    clearData() {
      window.location.reload();
    },
    hideListCommunity() {
      this.$refs.community.onClose();
    },
    autoResize() {
      const textarea = this.$refs.textarea;
      textarea.style.height = "auto"; // Сбросить высоту, чтобы пересчитать ее
      textarea.style.height = textarea.scrollHeight + "px"; // Установить новую высоту
    }
  },
  watch: {
  },
  mounted() {
    const href = new URL(window.location.href);
    this.getRegions();
    if (href.pathname === '/call-center/') this.isCallCenter = true;
    else this.isCallCenter = false;
  }
}
</script>

<template>
  <div class="">
    <ModalInfo
      ref="modalInfo"
      :infoText="message"
      @close="hideModal(event, action)"
      :logo="logo"
    />

    <div class="">
      <form
        action="#"
        method="POST"
        class="mx-auto mt-3"
      >
        <div class="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-3">
          <div class="">
            <label class="block">
              <span class="block text-sm font-medium text-slate-700">
                Ім'я
              </span>
            </label>
            <div class="mt-2.5">
              <input
                type="text"
                v-model="name"
                @keyup="filterName(name, 'name')"
                placeholder="Ім'я"
                :required="isAnonymous"
                :disabled="isAnonymous"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label class="block">
              <span class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Призвіще
              </span>
            </label>
            <div class="mt-2.5">
              <input
                type="text"
                v-model="last_name"
                @keyup="filterName(last_name, 'last_name')"
                placeholder="Призвіще"
                :required="isAnonymous"
                :disabled="isAnonymous"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div class="col-span-1">
            <label
              for="isAnonim"
              class="block"
            >
              <span class="block text-sm font-medium text-slate-700">
                Анонімне звернення</span></label>
            <div class="mt-2.5">
              <input
                id="isAnonim"
                type="checkbox"
                @change="isAnonymous = !isAnonymous;name=''; last_name = ''"
                class="cursor-pointer rounded h-5 w-5 border-gray-400 focus:none"
              >
            </div>
          </div>

          <div class="">
            <label class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Область
              </span>
            </label>
            <div class="mt-2.5">
              <select
                id="region"
                v-if="!region_id"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
              >
                <option selected>Область</option>
                <option
                  v-for="{ region, region_id } in regions"
                  :key="region_id"
                  :value="region_id"
                >{{ region }}</option>
              </select>
              <select
                id="region"
                v-else
                v-model="region_id"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
              >
                <option selected>Область</option>
                <option
                  v-for="{ region, region_id } in regions"
                  :key="region_id"
                  :value="region_id"
                >{{ region }}</option>
              </select>
            </div>
          </div>

          <div class="">
            <Communities
              v-model="communityId"
              @selected="onCommunitySelected"
            />
          </div>

          <div class="">
            <label class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Телефон
              </span>
            </label>
            <div class="mt-2.5">
              <input
                v-mask="'38(###)###-##-##'"
                placeholder="Телефон"
                v-model="phone"
                id="phone"
                required
                type="tel"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
                :class="errNumberPhone ?'border-2 border-rose-500 text-red-600':''"
              />
              <p
                v-if="errNumberPhone"
                class="text-red-500 text-[12px] transition-all duration-200"
              >Невірний номер*</p>
            </div>
          </div>

        </div>
        <div class="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-3">

          <div class="col-span-1 mt-4">
            <legend class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                З чим потрібна допомога
              </span>
            </legend>
            <Assistance
              @checked="onAssistanceSelected"
              titleClass="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-black"
              mainClass=" bg-transparent border-none"
              checkClass="custom-checkbox cursor-pointer h-5 w-5 rounded border-gray-400 focus:none"
            />
          </div>
          <div class="col-span-1 mt-4">
            <legend class="block">
              <div class="after:content-['*'] after:ml-0.5 text-sm after:text-[#B20000] block opacity-70">
                Допомога дітям
              </div>
            </legend>
            <div class="px-0 py-1.5 mt-2">
              <div class="flex items-center gap-3 mb-4">
                <input
                  id="no"
                  name="children_help"
                  v-model="children_help"
                  @change="!children_help ? [children_count =0, children_ages =[]] : children_count"
                  type="radio"
                  :value="false"
                  class="cursor-pointer rounded h-5 w-5 border-gray-400 focus:none"
                >
                <label
                  for="no"
                  class=" cursor-pointer block leading-6 text-sm"
                >Ні</label>
              </div>
              <div class="flex items-center gap-3 mb-4">
                <input
                  id="yes"
                  name="children_help"
                  v-model="children_help"
                  type="radio"
                  :value="true"
                  class="h-5 w-5 cursor-pointer  rounded"
                >
                <label
                  for="yes"
                  class="cursor-pointer block leading-6 text-sm"
                >Так</label>
              </div>
            </div>
            <div v-if="children_help">
              <div class="col-span-1 relative z-0 w-full mt-4 mb-5 group">
                <input
                  type="number"
                  min="0"
                  max="15"
                  v-model="children_count"
                  id="children_count"
                  class="block py-2.5 pl-2 w-full  rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400"
                  placeholder="Кількість дітей"
                />
                <legend
                  for="children_count"
                  class=" absolute duration-300 transform -translate-y-6 scale-75 top-0.5 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >Кількість дітей*</legend>
              </div>
              <CildrenAges
                v-model="children_ages"
                @checked="onAgeChecked"
                mainClass=" bg-transparent border-none"
                checkClass="custom-checkbox cursor-pointer h-5 w-5 rounded border-gray-400 focus:none"
              />
            </div>
          </div>
          <div class="sm:col-span-1 mt-4">
            <label class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Опис проблеми
              </span>
            </label>
            <div class="mt-2.5">
              <textarea
                rows="10"
                v-model="description"
                required
                @keyup="filterText(description)"
                placeholder=""
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

        </div>
      </form>
      <div class="flex justify-end py-4">
        <button
          class="flex justify-center items-center flex-grow-0 flex-shrink-0 h-12 relative gap-2.5 px-9 py-4 rounded-[100px] bg-[#b20000] font-bold text-center text-[#fbfbfb]"
          @click.prevent="submit"
        >Зберегти</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
[type='checkbox']:checked,
[type='radio']:checked {
  background-image: url('../../assets/admin-menu/check-yes.svg');
}

[type='checkbox']:focus,
[type='radio']:focus {
  outline: none;
  box-shadow: none;
  background-color: white;
}
.warning {
  /* border: 1px solid #d7d3d3; */
  padding: 20px 10px;
  border-radius: 8px;
}
.err {
  border: 1px solid #f27272;
}
.enough {
  position: absolute;
  font-size: 275px;
  transform: rotate(-45deg);
  left: 8%;
  top: 40dvh;
  opacity: 0.07;
  letter-spacing: 1rem;
  z-index: 1;
  color: #f27272;
  pointer-events: none;
}
</style>