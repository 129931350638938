<script>

export default {
  props: {
    mainClass: { dataType: String, default: 'border-t-2 border-gray-300 px-0 py-3.5 mt-2 bg-white' },
    checkClass: { dataType: String, default: 'cursor-pointer h-5 w-5 rounded border-[#B20000] text-[#B20000] focus:ring-white' },
    legendClass: { dataType: String, default: 'after:content-[' * '] after:ml-0.5 after:text-[#B20000] block' },
    title: { dataType: String, default: 'Вік дітей' },
    disabled: { dataType: Boolean, default: false }
  },
  data() {
    return {
      data: [
        { id: '0_3', label: 'До 3 років' },
        { id: '3_7', label: '3-7 років' },
        { id: '7_14', label: '7-14 років' },
        { id: '14_17', label: '14-17 років' },
      ],
      ages: [],
      age: '',
      children_ages: []
    }
  },
  methods: {
    onChecked(id) {
      // Обновляем массив ages
      if (this.ages.includes(id)) {
        const index = this.ages.indexOf(id);
        this.ages.splice(index, 1);
      } else {
        this.ages.push(id);
      }

      // Обновляем children_ages
      this.children_ages = this.ages.map((item) => {
        // Создаем объект с ключом item.id и значением 1 или 0
        return { [item]: this.ages.includes(item) ? 1 : 0 };
      });
      // Отправляем обновленный массив через событие
      this.$emit('checked', this.children_ages);
    }
  },
}
</script>

<template>
  <div class="col-span-2 mt-4">
    <legend
      class="block"
      v-if="title"
    >
      <span :class="legendClass">
        {{ title }}
      </span>
    </legend>
    <div :class="mainClass">

      <div
        v-for="item of this.data"
        :key="item.id"
        class="py-2"
      >
        <div class="relative flex gap-x-3">
          <div class="flex h-6 items-center">
            <input
              :id="item.id"
              v-model="children_ages"
              :value="item.id"
              type="checkbox"
              @change="onChecked(item.id)"
              :class="checkClass"
              :disabled="disabled"
            />
          </div>
          <div class="text-sm leading-6">
            <label
              :for="item.id"
              class="cursor-pointer"
            >{{ item.label }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
p,
ul > li,
h2,
legend > span,
label,
span {
  opacity: 0.8;
  font-size: 16px;
  color: #6b7280;
}
.custom-checkbox:checked {
  background-image: url('../../../assets/admin-menu/check-yes.svg') !important;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
}
.custom-checkbox:focus {
  outline: none;
  box-shadow: none;
}
.custom-checkbox {
  /* border-color: none !important; */
  background-color: white;
}
</style>