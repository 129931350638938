<script>
import Capitalize from '../lib/capitalize.js';
import ModalInfo from './ModalInfo.vue';
import Communities from './registration/community.vue';
// import MultiSelect from './MultiSelect.vue';
import Assistance from './appeal/components/_assistance.vue'

export default {
  name: 'RegisterForm',
  components: { Communities, ModalInfo, Assistance },
  data() {
    return {
      name: '',
      last_name: '',
      email: '',
      phone: '',
      responsability: '',
      institution: '',
      password: '',
      repeat_password: '',
      showPassword: false,
      showRepeatPassword: false,
      isActive: false,
      assistance: [],
      regions: [],
      assistance_id: [],
      region_id: '',
      community_id: '',
      message: '',
      logo: require('../assets/check.svg'),
      errNumberPhone: false,
      event: false,
      action: '',
      isVisible: false,
      options: [
      ],
    };
  },
  methods: {
    async submit() {
      if (!this.isValid()) return;

      const data = {
        name: this.name,
        last_name: this.last_name,
        email: this.email,
        phone: this.formatPhoneNumber(this.phone),
        responsability: this.responsability,
        institution: this.institution,
        password: this.password,
        isActive: false,
        assistance_id: this.assistance_id,
        region_id: this.region_id,
        community_id: this.communityId,
      };

      // Проверка номера телефона
      this.onValidatePhoneNumber(this.phone);
      if (this.errNumberPhone) {
        this.message = '<span class="red">Помилка.</span> Невірний номер телефону';
        this.logo = require('@/assets/error.svg');
        this.showModal();
        return;
      }
      if (this.onAssistanceValidate(this.assistance_id)) {
        this.isVisible = true;
        await this.onRegister(data);
      }
    },

    async getRegions() {
      const response = await fetch(`/api/communities/regions/active`);
      try {
        if (response.ok) {
          this.regions = await response.json();
        }
      } catch (err) {
        this.message = `${err}`;
        this.logo = require('@/assets/error.svg');
        this.showModal();
      }
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleRepeatPasswordVisibility() {
      this.showRepeatPassword = !this.showRepeatPassword;
    },
    showModal() {
      this.$refs.modalInfo.onOpen();
    },
    hideModal(event) {
      if (event)
        // window.location.assign('https://t.me/appealProjectBot');
        console.log(event);

    },
    isValid() {
      return this.passwordsMatch() &&
        this.validatePassword(this.password) &&

        this.validatePhoneNumber(this.phone) &&
        this.validateEmail(this.email);
    },

    passwordsMatch() {
      if (this.password !== this.repeat_password) {
        // alert('Паролі не співпадають');
        this.message = 'Паролі не співпадають'
        this.logo = require('../assets/error.svg');
        this.showModal();
        return false;
      }
      return true;
    },

    validatePassword(password) {
      // Регулярное выражение с поддержкой кириллицы и длиной от 8 до 10 символов
      const regex = /^(?=.*[A-ZА-ЯЁ])(?=.*[a-zа-яё])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-zА-Яа-яЁё\d!@#$%^&*]{8,10}$/u;

      if (!regex.test(password)) {
        this.event = false;
        this.message = 'Пароль має містити від 8 до 10 символів і включати принаймні одну велику літеру (латиниця або кирилиця), одну малу літеру, одну цифру і один спеціальний символ: !@#$%^&*';
        this.logo = require('../assets/error.svg');
        this.showModal();
        return false;
      }
      return true;
    },

    validatePhoneNumber(phone) {
      const regex = /^\d{2}\(0[5-9]\d\)\d{3}-\d{2}-\d{2}$/;
      if (!regex.test(phone)) {
        this.event = false;
        this.message = 'Некоректний номер телефону'
        this.logo = require('../assets/error.svg');
        this.showModal();
        return false;
      }
      return true;
    },

    validateEmail(email) {
      const regex = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;
      if (!regex.test(email)) {
        this.event = false;
        this.message = 'Некоректний email'
        this.logo = require('../assets/error.svg');
        this.showModal();
        return false;
      }
      return true;
    },
    onAssistanceValidate(assistance_id) {
      if (assistance_id && assistance_id.length < 1) {
        this.message = '<span class="red">Помилка.</span> Необрано функціонал';
        this.logo = require('@/assets/error.svg');
        this.showModal();
        return;
      } else {
        return true;
      }
    },
    async onRegister(data) {
      try {
        const response = await fetch(`/api/auth/registration`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          this.user = await response.json();
          await this.sendMail(data.email, data.name, 'https://t.me/appealProjectBot');
        } else {
          const errorData = await response.json();
          // alert(errorData.message);
          this.event = false;
          this.isVisible = false;
          this.message = errorData.message;
          this.logo = require('../assets/error.svg');
          this.showModal();
          return false;
        }
      } catch (err) {
        console.log(err);
      }
    },

    resetForm() {
      setTimeout(() => {
        this.name = '';
        this.last_name = '';
        this.email = '';
        this.phone = '';
        this.responsability = '';
        this.institution = '';
        this.password = '';
        this.repeat_password = '';
        this.assistance_id = '';
        this.region_id = 'Область';
        this.community_id = '';
        this.community = [];
        this.assistance = '',
          this.regions = ''
      }, 1000);
    },
    clearData() {
      window.location.reload();
    },

    async getAssistance() {
      try {
        const res = await fetch(`/api/assistance/all`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        if (res.ok) {
          this.assistance = await res.json();
          return this.assistance;
        } else {
          this.event = false;
          this.message = 'Помилка'
          this.logo = require('../assets/error.svg');
          this.showModal();
        }
      } catch (err) {
        console.error(err);
      }
    },
    async sendMail(to, name, url) {
      try {
        const response = await fetch(`/api/email/registration`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ to, name, verificationUrl: url }),
        });
        if (response.ok) {
          this.isVisible = false;
          this.resetForm();
          this.message = `Інструкція по активаціі направлена на ${this.email}`
          this.logo = require('../assets/check.svg');
          this.event = false;
          this.showModal();
        }
      } catch (err) {
        this.message = 'Помилка відправки Email\n'
        this.logo = require('../assets/error.svg');
        this.event = false;
        this.showModal();
      }
    },
    filterInput(input, keyName) {
      const regex = /^[а-яА-ЯёЁІі\s]+$/;
      this[keyName] = Capitalize(input.split('').filter(char => regex.test(char)).join(''));
    },

    filterName(input, keyName) {
      let filteredName = '';
      const regex = new RegExp(/^[а-яА-ЯёЁіїґєЄ'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredName += char;
        }
      }
      this[keyName] = filteredName.toUpperCase();
    },
    onAssistanceSelected(value) {
      this.assistance_id = value;
    },

    filterText(input, keyName) {
      const regex = /^[!"?.,а-яА-ЯёЁІі\s]+$/;
      this[keyName] = Capitalize(input.split('').filter(char => regex.test(char)).join(''));
    },

    onValidatePhoneNumber(phoneNumber) {
      // Обновленное регулярное выражение для проверки полного формата
      // const regex = /^38\(0[3-9]\d\)\d{3}-\d{2}-\d{2}$/;
      const regex = /^38\((039|067|068|096|097|098|050|066|095|099|063|073|077|093|091|092|089|094)\)\d{3}-\d{2}-\d{2}$/;

      // Проверяем строгое соответствие формату
      if (regex.test(phoneNumber)) {
        this.errNumberPhone = false; // Номер корректный
      } else {
        this.errNumberPhone = true; // Номер некорректный
      }

      return this.errNumberPhone;
    },
    formatPhoneNumber(phone) {
      const cleanedPhone = phone.replace(/[^\d]/g, '');
      return cleanedPhone;
    },
    onCommunitySelected(value) {
      this.region_id = value;
    },
  },
  mounted() {
    // this.filterInput = this.filterInput.bind(this);
    this.getRegions();
    this.getAssistance().then(() => {
      this.assistance.forEach((item) => {
        this.options.push({ id: item.id, description: item.description })
      });
    });

  }
};
</script>

<template>
  <div class="p-3 md:p-10">
    <div class="flex justify-center items-center sm:mx-auto sm:w-full sm:max-w-sm mb-4">
      <router-link to="/">
        <img
          src="../assets/about/logo.svg"
          alt="Enough"
          height="56"
          width="66"
        />
      </router-link>
      <h2 class="pl-6 text-3xl font-bold text-[#6B7280] sm:text-4xl tracking-widest">Реєстрація</h2>
    </div>
    <form
      class="max-w-2xl mx-auto"
      @submit.prevent="submit"
    >
      <div class="grid md:grid-cols-2 gap-x-6 gap-y-1.5">
        <div class="col-span-2 md:col-span-1 relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="floating_first_name"
            v-model="name"
            @keyup="filterName(name, 'name')"
            id="floating_first_name"
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
            placeholder=" "
            required
          />
          <label
            for="floating_first_name"
            class="peer-focus:font-medium absolute text-sm text-gray-600  duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#B20000]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Ім`я*</label>
        </div>
        <div class="col-span-2 md:col-span-1 relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="last_name"
            v-model="last_name"
            @keyup="filterName(last_name, 'last_name')"
            id="last_name"
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
            placeholder=" "
            required
          />
          <label
            for="last_name"
            class="peer-focus:font-medium absolute text-sm text-gray-600  duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#B20000]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Призвіще*</label>
        </div>

        <div class="col-span-2 md:col-span-2 relative z-0 w-full mt-4 mb-5 group">
          <select
            id="region"
            v-if="!region_id"
            class="block py-2.5 pl-2 w-full text-[14px] opacity-80 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
          >
            <option selected>Область</option>
            <option
              v-for="{ region, region_id } in regions"
              :key="region_id"
              :value="region_id"
            >{{ region }}</option>
          </select>
          <select
            id="region"
            v-else
            v-model="region_id"
            class="block py-2.5 pl-2 w-full opacity-80 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
          >
            <option selected>Область</option>
            <option
              v-for="{ region, region_id } in regions"
              :key="region_id"
              :value="region_id"
            >{{ region }}</option>
          </select>
          <label
            for="region"
            class="peer-focus:font-medium opacity-70 text-sm absolute duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#B20000]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Область</label>
        </div>

        <Communities
          v-model="communityId"
          @selected="onCommunitySelected"
        />

        <div class="col-span-2 md:col-span-2 relative z-0 w-full mb-5 group">
          <input
            type="tel"
            v-mask="'38(###)###-##-##'"
            placeholder=" "
            @blur="onValidatePhoneNumber(phone)"
            required
            v-model="phone"
            id="floating_telephone"
            :class="errNumberPhone ?'errNumberPhone':''"
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
          />
          <label
            for="floating_telephone"
            v-if="!errNumberPhone"
            class="peer-focus:font-medium absolute text-sm text-gray-600  duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#B20000] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Телефон*</label>
          <label
            for="floating_telephone"
            v-else
            class="peer-focus:font-medium absolute text-sm text-[#B20000]  duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#B20000] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Не вірний номер</label>
        </div>
        <div class="col-span-2 md:col-span-2 relative z-0 w-full mb-5 group">
          <input
            type="email"
            name="floating_email"
            v-model="email"
            id="floating_email"
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
            placeholder=" "
            required
          />
          <label
            for="floating_email"
            class="peer-focus:font-medium absolute text-sm text-gray-600 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#B20000] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Email*</label>
        </div>
        <div class="col-span-2 relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="responsability"
            v-model="responsability"
            @keyup="filterText(responsability,'responsability')"
            id="responsability"
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
            placeholder=" "
            required
          />
          <label
            for="responsability"
            class="peer-focus:font-medium absolute text-sm text-gray-600  duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#B20000]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Посада*</label>
        </div>

        <div class="col-span-2 relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="responsability"
            v-model="institution"
            @keyup="filterText(institution,'institution')"
            id="responsability"
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
            placeholder=" "
            required
          />
          <label
            for="responsability"
            class="peer-focus:font-medium absolute text-sm text-gray-600  duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#B20000]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Установа*</label>
        </div>

        <div class="col-span-2 relative z-10 w-full mb-5 group">
          <!-- <MultiSelect
            :options="options"
            v-model="assistance_id"
          /> -->
          <Assistance
            @checked="onAssistanceSelected"
            :options="options"
            title="Функціонал"
            v-model="assistance_id"
            isDescription="true"
          />
        </div>
        <div class="col-span-2 md:col-span-1 relative z-0 w-full mb-5 group">
          <input
            :type="showPassword ? 'text' : 'password'"
            name="floating_password"
            v-model="password"
            id="floating_password"
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
            placeholder=" "
            required
          />
          <label
            for="floating_password"
            class="peer-focus:font-medium absolute text-sm text-gray-600 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-[#B20000] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Пароль*</label>
          <!-- Кнопка для показа/скрытия пароля -->
          <button
            type="button"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            @click="togglePasswordVisibility"
          >
            <img
              v-if="showPassword"
              src="../assets/close-eye.svg"
              alt=""
            >
            <img
              v-else
              src="../assets/open_eye.svg"
              alt=""
            >
          </button>
        </div>

        <div class="col-span-2 md:col-span-1 relative z-0 w-full mb-5 group">
          <input
            :type="showRepeatPassword ? 'text' : 'password'"
            name="repeat_password"
            v-model="repeat_password"
            id="floating_repeat_password"
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer"
            placeholder=" "
            required
          />
          <label
            for="floating_repeat_password"
            class="peer-focus:font-medium absolute text-sm text-gray-600 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-[#B20000] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Підтвердіть пароль*</label>
          <!-- Кнопка для показа/скрытия подтверждения пароля -->
          <button
            type="button"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            @click="toggleRepeatPasswordVisibility"
          >
            <img
              v-if="showRepeatPassword"
              src="../assets/close-eye.svg"
              alt=""
            >
            <img
              v-else
              src="../assets/open_eye.svg"
              alt=""
            >
          </button>
        </div>
      </div>
      <div class="grid grid-cols-3 items-center md:gap-4 md:pt-6">
        <div class="col-span-3 md:col-span-1 relative w-full">
          <button
            type="button"
            @click="clearData"
            class="w-full hover:underline py-2.5 md:text-left  opacity-80"
          >Очистити форму</button>
        </div>
        <div class="col-span-3 md:col-span-2 relative w-full">
          <button
            type="submit"
            class="btn flex w-full justify-center rounded-md px-3 p-3 text-sm font-semibold"
          >Зареєструватися</button>
        </div>
      </div>
    </form>
    <dir
      v-if="isVisible"
      class="fixed transition-opacity bg-[#6966666e] top-[-15px] right-0 left-0 justify-center items-center w-full  h-[100dvh]"
    ></dir>
    <span
      v-if="isVisible"
      class="loader z-50"
    ></span>
    <ModalInfo
      ref="modalInfo"
      :infoText="message"
      @close="hideModal(event, action)"
      :logo="logo"
    />
  </div>
</template>

<style scoped>
* {
  font-family: 'Liberation Sans', sans-serif;
}
label {
  opacity: 0.6;
}
/* input {
  font-size: 14px !important;
} */
p {
  font-family: 'Liberation Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(107, 114, 128, 0.6);
  line-height: 20px;
}
.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: absolute;
  bottom: 50dvh;
  left: 49dvw;
  background: #9a9898;
  box-shadow: -24px 0 #fff, 24px 0 #fff;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}
.errNumberPhone {
  color: coral !important;
}
.btn:hover {
  color: #b20000;
  border: 2px solid #b20000;
  background: #fbfbfb;
  transition: all 0.5s ease-in-out;
}
.btn {
  color: #fbfbfb;
  border: 2px solid #b20000;
  background: #b20000;
  transition: all 0.5s ease-in-out;
}
</style>
