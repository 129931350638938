<script>
export default {
  data() {
    return {
      isActive: true
    }

  }, methods: {
    onClick(isActive) {
      this.isActive = isActive;
    }
  }
}
</script>

<template>
  <div
    class="py-8"
    id="media"
  >
    <div class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
      <p class="flex-grow-0 flex-shrink-0 text-[40px] py-2 font-bold text-center text-[#36393f]">Медіа</p>
    </div>
    <div class="flex flex-wrap justify-center space-x-[-20px] py-4">
      <div class="flex relative after:absolute after:bottom-0 after:left-[20px] after:h-[2px] after:w-[90%] after:bg-[#b20000]">
        <div
          :class="!isActive ? '' : 'bg-[#b20000] text-[#fbfbfb]'"
          class="flex cursor-pointer justify-center items-center mr-1 overflow-hidden px-8 py-2 rounded-[90px]"
          @click="onClick(!isActive)"
        >
          <p class="text-2xl font-bold">Ми в змі</p>
        </div>
        <div
          :class="isActive ? ']' : 'bg-[#b20000] text-[#fbfbfb]'"
          class="flex cursor-pointer justify-center items-center ml-1  overflow-hidden px-8 py-2 rounded-[90px]"
          @click="onClick(!isActive)"
        >
          <p class="text-2xl font-bold">Блог</p>
        </div>
      </div>
    </div>
    <div class="md:grid grid-cols-1 md:grid-cols-3 gap-10 py-6">
      <div class="self-stretch flex-grow-0 flex-shrink-0 relative overflow-hidden">

        <img :src="isActive ? require('../../assets/about/media-1.png'): require('../../assets/about/media-blog-1.png')" />
        <div class="flex flex-col justify-start items-start gap-5 py-4">
          <p class="self-stretch flex-grow-0 flex-shrink-0 text-xl font-bold text-left text-gray-500">
            На Полтавщині та Харківщині триває проєкт з фінансової незалежності жінок, які
            постраждали від насильства
          </p>
          <button class="btn-default flex justify-center items-center flex-grow-0 
          flex-shrink-0 relative gap-2.5 px-2 md:px-9 md:py-4 rounded-[100px]">
            Читати далі
          </button>
        </div>
      </div>
      <div class="self-stretch flex-grow-0 flex-shrink-0 relative overflow-hidden">
        <img :src="isActive ? require('../../assets/about/media-2.jpeg'): require('../../assets/about/media-blog-2.png')" />
        <svg
          v-show="isActive"
          width="119"
          height="119"
          viewBox="0 0 119 119"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="w-[119px] h-[119px] absolute left-[30dvw] md:left-[157px] top-[10dvh] md:top-[157px]"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            opacity="0.9"
            clip-path="url(#clip0_2189_226)"
          >
            <g filter="url(#filter0_d_2189_226)">
              <path
                d="M59.5 7.4375C49.203 7.4375 39.1372 10.4909 30.5756 16.2116C22.014 21.9323 15.341 30.0634 11.4005 39.5765C7.46003 49.0897 6.42902 59.5577 8.43786 69.6569C10.4467 79.756 15.4052 89.0327 22.6862 96.3137C29.9673 103.595 39.244 108.553 49.3431 110.562C59.4422 112.571 69.9103 111.54 79.4234 107.599C88.9366 103.659 97.0677 96.986 102.788 88.4244C108.509 79.8627 111.562 69.797 111.562 59.5C111.562 45.6922 106.077 32.4499 96.3137 22.6863C86.5501 12.9226 73.3078 7.4375 59.5 7.4375ZM87.1935 62.8283L42.5685 85.1408C42.0014 85.4241 41.3713 85.5578 40.738 85.5292C40.1047 85.5005 39.4892 85.3104 38.9501 84.9769C38.4109 84.6434 37.966 84.1776 37.6575 83.6238C37.349 83.07 37.1872 82.4464 37.1875 81.8125V37.1875C37.1878 36.5539 37.3501 35.9308 37.6588 35.3775C37.9676 34.8241 38.4126 34.3589 38.9516 34.0258C39.4907 33.6928 40.1059 33.503 40.7389 33.4745C41.3719 33.446 42.0017 33.5797 42.5685 33.8629L87.1935 56.1754C87.8104 56.4846 88.3291 56.9593 88.6916 57.5464C89.054 58.1336 89.246 58.81 89.246 59.5C89.246 60.19 89.054 60.8664 88.6916 61.4535C88.3291 62.0407 87.8104 62.5154 87.1935 62.8246"
                fill="#FBFBFB"
              ></path>
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_2189_226"
              x="-16.5625"
              y="-12.5625"
              width="152.125"
              height="152.125"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood
                flood-opacity="0"
                result="BackgroundImageFix"
              ></feFlood>
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              ></feColorMatrix>
              <feOffset dy="4"></feOffset>
              <feGaussianBlur stdDeviation="12"></feGaussianBlur>
              <feComposite
                in2="hardAlpha"
                operator="out"
              ></feComposite>
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0941176 0 0 0 0 0.0941176 0 0 0 0 0.0941176 0 0 0 0.2 0"
              ></feColorMatrix>
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2189_226"
              ></feBlend>
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2189_226"
                result="shape"
              ></feBlend>
            </filter>
            <clipPath id="clip0_2189_226">
              <rect
                width="119"
                height="119"
                fill="white"
              ></rect>
            </clipPath>
          </defs>
        </svg>
        <div class="flex flex-col justify-start items-start gap-5 py-4">
          <p class="self-stretch flex-grow-0 flex-shrink-0 text-xl font-bold text-left text-gray-500">
            Протидія домашньому насильству на Полтавщині
          </p>
          <button class="btn-default flex justify-center items-center flex-grow-0 
          flex-shrink-0 relative gap-2.5 px-2 md:px-9 md:py-4 rounded-[100px]">
            Читати далі
          </button>
        </div>
      </div>
      <div class="self-stretch flex-grow-0 flex-shrink-0 relative overflow-hidden">
        <img :src="isActive ? require('../../assets/about/media-3.png'): require('../../assets/about/media-blog-3.png')" />
        <svg
          v-show="isActive"
          width="119"
          height="119"
          viewBox="0 0 119 119"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="w-[119px] h-[119px] absolute left-[30dvw] md:left-[157px] top-[15dvh] md:top-[157px]"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            opacity="0.9"
            clip-path="url(#clip0_2189_235)"
          >
            <g filter="url(#filter0_d_2189_235)">
              <path
                d="M59.5 7.4375C49.203 7.4375 39.1373 10.4909 30.5756 16.2116C22.014 21.9323 15.341 30.0634 11.4005 39.5765C7.46006 49.0897 6.42905 59.5577 8.43789 69.6569C10.4467 79.756 15.4052 89.0327 22.6863 96.3137C29.9673 103.595 39.244 108.553 49.3431 110.562C59.4423 112.571 69.9103 111.54 79.4235 107.599C88.9366 103.659 97.0677 96.986 102.788 88.4244C108.509 79.8627 111.563 69.797 111.563 59.5C111.563 45.6922 106.077 32.4499 96.3138 22.6863C86.5501 12.9226 73.3078 7.4375 59.5 7.4375ZM87.1935 62.8283L42.5686 85.1408C42.0015 85.4241 41.3713 85.5578 40.738 85.5292C40.1047 85.5005 39.4893 85.3104 38.9501 84.9769C38.4109 84.6434 37.966 84.1776 37.6575 83.6238C37.349 83.07 37.1872 82.4464 37.1875 81.8125V37.1875C37.1879 36.5539 37.3501 35.9308 37.6589 35.3775C37.9676 34.8241 38.4126 34.3589 38.9517 34.0258C39.4907 33.6928 40.1059 33.503 40.739 33.4745C41.372 33.446 42.0017 33.5797 42.5686 33.8629L87.1935 56.1754C87.8104 56.4846 88.3291 56.9593 88.6916 57.5464C89.0541 58.1336 89.246 58.81 89.246 59.5C89.246 60.19 89.0541 60.8664 88.6916 61.4535C88.3291 62.0407 87.8104 62.5154 87.1935 62.8246"
                fill="#FBFBFB"
              ></path>
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_2189_235"
              x="-16.5625"
              y="-12.5625"
              width="152.125"
              height="152.125"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood
                flood-opacity="0"
                result="BackgroundImageFix"
              ></feFlood>
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              ></feColorMatrix>
              <feOffset dy="4"></feOffset>
              <feGaussianBlur stdDeviation="12"></feGaussianBlur>
              <feComposite
                in2="hardAlpha"
                operator="out"
              ></feComposite>
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0941176 0 0 0 0 0.0941176 0 0 0 0 0.0941176 0 0 0 0.2 0"
              ></feColorMatrix>
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2189_235"
              ></feBlend>
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2189_235"
                result="shape"
              ></feBlend>
            </filter>
            <clipPath id="clip0_2189_235">
              <rect
                width="119"
                height="119"
                fill="white"
              ></rect>
            </clipPath>
          </defs>
        </svg>
        <div class="flex flex-col justify-start items-start gap-5 py-4">
          <p class="self-stretch flex-grow-0 flex-shrink-0 text-xl font-bold text-left text-gray-500">
            Скажи #ДОСИТЬ! Зустріч Скажи ДОСИТЬ!: на Полтавщині та Харківщині реалізують проєкт з
            фінансової незалежності жінок, які постраждали від насильствау Недогарській громаді
          </p>

          <button class="btn-default flex justify-center items-center flex-grow-0 
          flex-shrink-0 relative gap-2.5 px-2 md:px-9 md:py-4 rounded-[100px]">
            Читати далі
          </button>

        </div>
      </div>
    </div>
    <button class="btn-primery block mx-auto px-9 max-w-[315px] py-4 rounded-[100px] bg-[#b20000]">
      Переглянути більше
    </button>

  </div>
</template>
<style scoped>
@media (min-width: 315px) and (max-width: 767px) {
}
img {
  width: 100%;
  height: 100%;
  max-height: 420px;
  object-fit: cover;
}
</style>