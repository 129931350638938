<script>
import PublicUsers from '../components/users/PublicUserListByRegion.vue';

export default {
  components: { PublicUsers },
};
</script>

<template>
  <div id="users">
    <PublicUsers />
  </div>

</template>
