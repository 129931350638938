<script>

import { mapState } from 'vuex';
import Capitalize from '@/lib/capitalize.js';
import { jwtDecode } from 'jwt-decode';
import fetchWithAuth from '@/lib/fetchWithAuth';

export default {
  name: 'Navbar',
  computed: {
    ...mapState(['sideBarOpen']),
  },
  data() {
    return {
      dropDownOpen: false,
      user: {},
      userId: '',
      userName: '',
      totals: '',
      new_appeal: '',
      completed: '',
      in_work: '',
    }
  },
  methods: {
    // Для обращений 
    async getAppealsByStatuses() {
      try {

        const response = await fetchWithAuth(`/api/appeals/totals-by-status`, {
          method: 'POST',
        });
        if (response.ok) {
          this.appeals = await response.json();
          this.totals = this.appeals.totals;
          this.new_appeal = this.appeals.new;
          this.completed = this.appeals.completed;
          this.in_work = this.appeals.in_work;

        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    async getAppealsHistoryByStatusesByUser(userId) {
      try {

        const response = await fetchWithAuth(`/api/appeal-history/totals/user/${userId}`, {
          method: 'POST',
        });
        if (response.ok) {
          this.appeals = await response.json();
          this.totals = this.appeals.totals;
          this.new_appeal = this.appeals.new;
          this.completed = this.appeals.completed;
          this.in_work = this.appeals.in_work;

        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    toggleSidebar() {
      this.$store.dispatch('toggleSidebar')
    },

    async logout() {
      try {
        await fetch(`/api/auth/logout`, {
          method: 'POST',
        });
        sessionStorage.removeItem('token');
        location.assign('/login')
      } catch (error) {
        console.log(error);
        sessionStorage.removeItem('token');
        location.assign('/login')
      }
    }
  },
  async mounted() {
    const token = sessionStorage.getItem('token');
    if (!token || !jwtDecode(token)) {
      window.location.assign('/login');
      return;
    }
    const { roles } = jwtDecode(token);

    this.userId = jwtDecode(token).id;
    this.name = jwtDecode(token).name;
    this.userName = Capitalize(this.name);

    if (!roles || !Array.isArray(roles)) {
      console.error('Роли отсутствуют или имеют некорректный формат');
      return;
    }
    if (roles.some(role => role.name === 'USER')) {
      await this.getAppealsHistoryByStatusesByUser(this.userId)
    } else {
      await this.getAppealsByStatuses();
    }
  }
}
</script>

<template>
  <div class="sticky top-0 z-40 bg-[#F5F5F5] mt-4 rounded-t-[25px] md:mr-8">
    <div class="w-full h-20 px-6 flex items-center justify-between">

      <!-- left navbar -->
      <div class="flex">

        <!-- mobile hamburger -->
        <div class="flex lg:hidden  items-center mr-4">
          <button
            class="hover:text-blue-500 hover:border-white focus:outline-none navbar-burger"
            @click="toggleSidebar()"
          >
            <svg
              class="h-5 w-5"
              v-bind:style="{ fill: 'black' }"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        <!-- search bar -->
        <div class="hidden md:block relative">
          <input
            type="search"
            name="serch"
            placeholder="Пошук..."
            class="bg-[#f4f1f1] h-10 w-full xl:w-96 px-5 text-gray-600 rounded-[60px] border-[#252525]/[0.01] text-sm focus:outline-none"
            style="filter: drop-shadow(0px 2px 10px rgba(0,0,0,0.25));opacity: 0.5;"
          >
          <button
            type="submit"
            class="absolute right-0 top-0 mt-3 mr-4"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="flex-grow-0 flex-shrink-0 w-6 h-6 relative bottom-[3px]"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                opacity="0.3"
                clip-path="url(#clip0_2249_617)"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.6611 15.6633C15.8004 15.5239 15.9658 15.4132 16.1479 15.3377C16.33 15.2622 16.5252 15.2234 16.7223 15.2234C16.9195 15.2234 17.1146 15.2622 17.2967 15.3377C17.4788 15.4132 17.6443 15.5239 17.7836 15.6633L23.5586 21.4383C23.84 21.7196 23.9982 22.1011 23.9984 22.499C23.9985 22.8969 23.8406 23.2786 23.5593 23.5601C23.2781 23.8415 22.8965 23.9997 22.4986 23.9999C22.1007 24 21.719 23.8421 21.4376 23.5608L15.6626 17.7858C15.5231 17.6465 15.4125 17.4811 15.337 17.299C15.2615 17.1169 15.2227 16.9217 15.2227 16.7246C15.2227 16.5274 15.2615 16.3323 15.337 16.1502C15.4125 15.9681 15.5231 15.8026 15.6626 15.6633H15.6611Z"
                  fill="#252525"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.75 18C10.8334 18 11.9062 17.7866 12.9071 17.372C13.9081 16.9574 14.8175 16.3497 15.5836 15.5836C16.3497 14.8175 16.9574 13.9081 17.372 12.9071C17.7866 11.9062 18 10.8334 18 9.75C18 8.66659 17.7866 7.5938 17.372 6.59286C16.9574 5.59193 16.3497 4.68245 15.5836 3.91637C14.8175 3.15029 13.9081 2.5426 12.9071 2.12799C11.9062 1.71339 10.8334 1.5 9.75 1.5C7.56196 1.5 5.46354 2.36919 3.91637 3.91637C2.36919 5.46354 1.5 7.56196 1.5 9.75C1.5 11.938 2.36919 14.0365 3.91637 15.5836C5.46354 17.1308 7.56196 18 9.75 18V18ZM19.5 9.75C19.5 12.3359 18.4728 14.8158 16.6443 16.6443C14.8158 18.4728 12.3359 19.5 9.75 19.5C7.16414 19.5 4.68419 18.4728 2.85571 16.6443C1.02723 14.8158 0 12.3359 0 9.75C0 7.16414 1.02723 4.68419 2.85571 2.85571C4.68419 1.02723 7.16414 0 9.75 0C12.3359 0 14.8158 1.02723 16.6443 2.85571C18.4728 4.68419 19.5 7.16414 19.5 9.75V9.75Z"
                  fill="#252525"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_2249_617">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>

      <!-- right navbar -->
      <div class="cursor-pointer">
        <div class="flex items-center relative">
          <img
            src="../assets/admin-menu/person-circle.svg"
            @click="dropDownOpen = !dropDownOpen"
          >
          <div class="flex justify-end text-xs px-2">{{userName}}</div>
          <div class="px-2">
            <img
              src="../assets/admin-menu/ball-black.svg"
              alt=""
            >
          </div>
          <div class="flex justify-end text-xs px-2">
            <a
              href="#"
              @click.prevent="logout()"
              class="block py-2 hover:bg-gray-200"
            >
              <img
                src="../assets/admin-menu/box-arrow-right.svg"
                alt=""
              >
            </a>
          </div>
        </div>
        <!-- <div class="flex justify-end text-xs p-2">{{userName}}</div> -->
      </div>

    </div>

    <!-- dropdown menu -->
    <div
      class="absolute bg-gray-100 border border-t-0 shadow-xl text-gray-700 rounded-b-lg w-48 right-0 mr-6"
      :class="dropDownOpen ? '' : 'hidden'"
    >
      <a
        href="#"
        class="block px-4 py-2 hover:bg-gray-200"
      >Акаунт</a>
      <hr>
      <a
        href="#"
        @click.prevent="logout()"
        class="block px-4 py-2 hover:bg-gray-200"
      >Вихід</a>
    </div>
    <!-- dropdown menu end -->
    <div class="font-semibold text-xl px-8 pt-2 pb-6"> Процес звернення</div>
    <div class="flex flex-wrap -mx-3 px-8">

      <div class="w-1/2 xl:w-1/4 px-3 py-2">
        <div class="flex flex-col justify-between items-start flex-grow h-20 overflow-hidden px-4 py-6 rounded-lg bg-[#36f]/5 border-t-0 border-r-0 border-b-0 border-l-4 border-[#36f]">
          <div class="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
            <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="flex-grow-0 flex-shrink-0 w-8 h-8 relative"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  d="M28.0799 15.76L27.8666 15.4667C27.4933 15.0134 27.0533 14.6534 26.5466 14.3867C25.8666 14 25.0933 13.8 24.2933 13.8H7.69327C6.89327 13.8 6.13327 14 5.43994 14.3867C4.91994 14.6667 4.45327 15.0534 4.0666 15.5334C3.3066 16.5067 2.9466 17.7067 3.0666 18.9067L3.55994 25.1334C3.73327 27.0134 3.95994 29.3334 8.1866 29.3334H23.8133C28.0399 29.3334 28.2533 27.0134 28.4399 25.12L28.9333 18.92C29.0533 17.8 28.7599 16.68 28.0799 15.76ZM19.1866 23.12H12.7999C12.2799 23.12 11.8666 22.6934 11.8666 22.1867C11.8666 21.68 12.2799 21.2534 12.7999 21.2534H19.1866C19.7066 21.2534 20.1199 21.68 20.1199 22.1867C20.1199 22.7067 19.7066 23.12 19.1866 23.12Z"
                  fill="#3366FF"
                ></path>
                <path
                  opacity="0.4"
                  d="M4.50659 15.0801C4.79993 14.8134 5.09326 14.5734 5.43992 14.3867C6.11992 14.0001 6.89326 13.8001 7.69326 13.8001H24.3066C25.1066 13.8001 25.8666 14.0001 26.5599 14.3867C26.9066 14.5734 27.2133 14.8134 27.4933 15.0934V14.3867V13.0934C27.4933 8.33341 26.0399 6.88008 21.2799 6.88008H18.1066C17.5199 6.88008 17.5066 6.86675 17.1599 6.41341L15.5599 4.26675C14.7999 3.28008 14.1999 2.66675 12.2933 2.66675H10.7199C5.95993 2.66675 4.50659 4.12008 4.50659 8.88008V14.4001V15.0801Z"
                  fill="#3366FF"
                ></path>
              </svg>
              <p class="flex-grow-0 flex-shrink-0 text-sm font-bold text-left text-[#36f]">Завершені ({{ completed }})</p>
            </div>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
              preserveAspectRatio="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.25 17C10.994 17 10.738 16.902 10.543 16.707C10.152 16.316 10.152 15.684 10.543 15.293L13.848 11.988L10.668 8.695C10.285 8.297 10.296 7.664 10.693 7.281C11.091 6.898 11.724 6.909 12.107 7.305L15.969 11.305C16.348 11.698 16.343 12.321 15.957 12.707L11.957 16.707C11.762 16.902 11.506 17 11.25 17"
                fill="#252525"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="w-1/2 xl:w-1/4 px-3 py-2">
        <div class="flex flex-col justify-between items-start flex-grow h-20 overflow-hidden px-4 py-6 rounded-lg bg-[#ff3336]/5 border-t-0 border-r-0 border-b-0 border-l-4 border-[#b20000]">
          <div class="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
            <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
              <svg
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="flex-grow-0 flex-shrink-0 w-8 h-8 relative"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  d="M28.8299 15.76L28.6166 15.4667C28.2433 15.0134 27.8033 14.6534 27.2966 14.3867C26.6166 14 25.8433 13.8 25.0433 13.8H8.44327C7.64327 13.8 6.88327 14 6.18994 14.3867C5.66994 14.6667 5.20327 15.0534 4.8166 15.5334C4.0566 16.5067 3.6966 17.7067 3.8166 18.9067L4.30994 25.1334C4.48327 27.0134 4.70994 29.3334 8.9366 29.3334H24.5633C28.7899 29.3334 29.0033 27.0134 29.1899 25.12L29.6833 18.92C29.8033 17.8 29.5099 16.68 28.8299 15.76ZM19.9366 23.12H13.5499C13.0299 23.12 12.6166 22.6934 12.6166 22.1867C12.6166 21.68 13.0299 21.2534 13.5499 21.2534H19.9366C20.4566 21.2534 20.8699 21.68 20.8699 22.1867C20.8699 22.7067 20.4566 23.12 19.9366 23.12Z"
                  fill="#B20000"
                ></path>
                <path
                  opacity="0.4"
                  d="M5.25659 15.0801C5.54993 14.8134 5.84326 14.5734 6.18992 14.3867C6.86992 14.0001 7.64326 13.8001 8.44326 13.8001H25.0566C25.8566 13.8001 26.6166 14.0001 27.3099 14.3867C27.6566 14.5734 27.9633 14.8134 28.2433 15.0934V14.3867V13.0934C28.2433 8.33341 26.7899 6.88008 22.0299 6.88008H18.8566C18.2699 6.88008 18.2566 6.86675 17.9099 6.41341L16.3099 4.26675C15.5499 3.28008 14.9499 2.66675 13.0433 2.66675H11.4699C6.70993 2.66675 5.25659 4.12008 5.25659 8.88008V14.4001V15.0801Z"
                  fill="#B20000"
                ></path>
              </svg>
              <p class="flex-grow-0 flex-shrink-0 text-sm font-bold text-left text-[#b20000]">
                В роботі ({{ in_work }})
              </p>
            </div>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
              preserveAspectRatio="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11 17C10.744 17 10.488 16.902 10.293 16.707C9.90201 16.316 9.90201 15.684 10.293 15.293L13.598 11.988L10.418 8.695C10.035 8.297 10.046 7.664 10.443 7.281C10.841 6.898 11.474 6.909 11.857 7.305L15.719 11.305C16.098 11.698 16.093 12.321 15.707 12.707L11.707 16.707C11.512 16.902 11.256 17 11 17"
                fill="#252525"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="w-1/2 xl:w-1/4 px-3 py-2">
        <div class="flex flex-col justify-between items-start flex-grow h-20 overflow-hidden px-4 py-6 rounded-lg bg-[#ff9533]/5 border-t-0 border-r-0 border-b-0 border-l-4 border-[#ff9533]">
          <div class="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
            <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
              <svg
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="flex-grow-0 flex-shrink-0 w-8 h-8 relative"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  d="M28.5801 15.76L28.3667 15.4667C27.9934 15.0134 27.5534 14.6534 27.0467 14.3867C26.3667 14 25.5934 13.8 24.7934 13.8H8.19339C7.39339 13.8 6.63339 14 5.94006 14.3867C5.42006 14.6667 4.95339 15.0534 4.56673 15.5334C3.80673 16.5067 3.44673 17.7067 3.56673 18.9067L4.06006 25.1334C4.23339 27.0134 4.46006 29.3334 8.68673 29.3334H24.3134C28.5401 29.3334 28.7534 27.0134 28.9401 25.12L29.4334 18.92C29.5534 17.8 29.2601 16.68 28.5801 15.76ZM19.6867 23.12H13.3001C12.7801 23.12 12.3667 22.6934 12.3667 22.1867C12.3667 21.68 12.7801 21.2534 13.3001 21.2534H19.6867C20.2067 21.2534 20.6201 21.68 20.6201 22.1867C20.6201 22.7067 20.2067 23.12 19.6867 23.12Z"
                  fill="#FF9533"
                ></path>
                <path
                  opacity="0.4"
                  d="M5.00671 15.0801C5.30005 14.8134 5.59338 14.5734 5.94005 14.3867C6.62005 14.0001 7.39338 13.8001 8.19338 13.8001H24.8067C25.6067 13.8001 26.3667 14.0001 27.06 14.3867C27.4067 14.5734 27.7134 14.8134 27.9934 15.0934V14.3867V13.0934C27.9934 8.33341 26.54 6.88008 21.78 6.88008H18.6067C18.02 6.88008 18.0067 6.86675 17.66 6.41341L16.06 4.26675C15.3 3.28008 14.7 2.66675 12.7934 2.66675H11.22C6.46005 2.66675 5.00671 4.12008 5.00671 8.88008V14.4001V15.0801Z"
                  fill="#FF9533"
                ></path>
              </svg>
              <p class="flex-grow-0 flex-shrink-0 text-sm font-bold text-left text-[#ff9533]">Нові ({{ new_appeal }})</p>
            </div>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
              preserveAspectRatio="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.75 17C10.494 17 10.238 16.902 10.043 16.707C9.65201 16.316 9.65201 15.684 10.043 15.293L13.348 11.988L10.168 8.695C9.78501 8.297 9.79601 7.664 10.193 7.281C10.591 6.898 11.224 6.909 11.607 7.305L15.469 11.305C15.848 11.698 15.843 12.321 15.457 12.707L11.457 16.707C11.262 16.902 11.006 17 10.75 17"
                fill="#252525"
              ></path>
            </svg>
          </div>
        </div>
      </div>

      <div class="w-1/2 xl:w-1/4 px-3 py-2">
        <div class="flex flex-col justify-between items-start flex-grow h-20 overflow-hidden px-4 py-6 rounded-lg bg-[#12c521]/5 border-t-0 border-r-0 border-b-0 border-l-4 border-[#12c521]">
          <div class="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
            <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
              <svg
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="flex-grow-0 flex-shrink-0 w-8 h-8 relative"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  d="M28.3301 15.76L28.1167 15.4667C27.7434 15.0134 27.3034 14.6534 26.7967 14.3867C26.1167 14 25.3434 13.8 24.5434 13.8H7.94339C7.14339 13.8 6.38339 14 5.69006 14.3867C5.17006 14.6667 4.70339 15.0534 4.31673 15.5334C3.55673 16.5067 3.19673 17.7067 3.31673 18.9067L3.81006 25.1334C3.98339 27.0134 4.21006 29.3334 8.43673 29.3334H24.0634C28.2901 29.3334 28.5034 27.0134 28.6901 25.12L29.1834 18.92C29.3034 17.8 29.0101 16.68 28.3301 15.76ZM19.4367 23.12H13.0501C12.5301 23.12 12.1167 22.6934 12.1167 22.1867C12.1167 21.68 12.5301 21.2534 13.0501 21.2534H19.4367C19.9567 21.2534 20.3701 21.68 20.3701 22.1867C20.3701 22.7067 19.9567 23.12 19.4367 23.12Z"
                  fill="#12C521"
                ></path>
                <path
                  opacity="0.4"
                  d="M4.75671 15.0801C5.05005 14.8134 5.34338 14.5734 5.69005 14.3867C6.37005 14.0001 7.14338 13.8001 7.94338 13.8001H24.5567C25.3567 13.8001 26.1167 14.0001 26.81 14.3867C27.1567 14.5734 27.4634 14.8134 27.7434 15.0934V14.3867V13.0934C27.7434 8.33341 26.29 6.88008 21.53 6.88008H18.3567C17.77 6.88008 17.7567 6.86675 17.41 6.41341L15.81 4.26675C15.05 3.28008 14.45 2.66675 12.5434 2.66675H10.97C6.21005 2.66675 4.75671 4.12008 4.75671 8.88008V14.4001V15.0801Z"
                  fill="#12C521"
                ></path>
              </svg>
              <p class="flex-grow-0 flex-shrink-0 text-sm font-bold text-left text-[#12c521]">Всі ({{totals}})</p>
            </div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
              preserveAspectRatio="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.5 17C10.244 17 9.98801 16.902 9.79301 16.707C9.40201 16.316 9.40201 15.684 9.79301 15.293L13.098 11.988L9.91801 8.695C9.53501 8.297 9.54601 7.664 9.94301 7.281C10.341 6.898 10.974 6.909 11.357 7.305L15.219 11.305C15.598 11.698 15.593 12.321 15.207 12.707L11.207 16.707C11.012 16.902 10.756 17 10.5 17"
                fill="#252525"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
