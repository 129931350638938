
<script>
import AppealEdit from '../components/appeal/_edit.vue'
export default {
  name: 'appealHistoryEdit',
  components: { AppealEdit }
}
</script>

<template>
  <div class="overflow-auto">
    <AppealEdit />
  </div>
</template>