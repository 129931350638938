<script>

export default {
  props: {
    title: String,
    disabled: { dataType: Boolean, default: false },
    readonly: { dataType: Boolean, default: false },
    titleClass: { dataType: String, default: 'after:content-[' * '] after:ml-0.5 after:text-[#B20000] block opacity-70' },
    mainClass: { dataType: String, default: 'border-t-2 border-gray-300 px-0 py-3.5 mt-2 bg-white' },
    checkClass: { dataType: String, default: 'cursor-pointer h-5 w-5 rounded border-[#B20000] text-[#B20000] focus:ring-white' }
  },
  data() {
    return {
      assistans: [],
      description: '',
      assistans_type: []
    }
  },
  methods: {
    async getAssistans() {
      const response = await fetch('/api/assistance', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      try {
        if (response.ok) {
          this.assistans = await response.json();
        }
      } catch (err) {
        console.log(err);

      }
    },
    onChecked() {
      this.$emit('checked', this.assistans_type.join('\n'));
    }
  },
  mounted() {
    this.getAssistans();
  }
}
</script>

<template>
  <div class="col-span-2 mt-4">
    <legend
      class="block"
      v-if="title"
    >
      <span :class="titleClass">
        {{ title }}
      </span>
    </legend>
    <div :class="mainClass">

      <div
        v-for="item of assistans"
        :key="item.id"
      >
        <div class="relative flex gap-x-3 py-2">
          <div class="flex h-6 items-center">
            <input
              :id="item.description"
              v-model="assistans_type"
              :value="item.description"
              type="checkbox"
              :disabled="disabled"
              :readonly="readonly"
              @change="onChecked"
              :class="checkClass"
            />
          </div>
          <div class="text-sm leading-6">
            <label
              :for="item.description"
              class="cursor-pointer"
            >{{ item.description }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
p,
ul > li,
h2,
legend > span,
label {
  opacity: 0.8;
  font-family: 'Liberation Sans', sans-serif;
  font-size: 16px;
  color: #6b7280;
}
.custom-checkbox:checked {
  background-image: url('../../assets/admin-menu/check-yes.svg') !important;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
}
.custom-checkbox:focus {
  outline: none;
  box-shadow: none;
}
.custom-checkbox {
  /* border-color: none !important; */
  background-color: white;
}
</style>