<script>
import Capitalize from '../../lib/capitalize'
export default {
  name: 'Communities',
  props: {
    value: { dataType: String },
    disabled: { dataType: Boolean, default: false },
    readonly: { dataType: Boolean, default: false },
  },
  data() {
    return {
      name: '',
      region_id: '',
      region: '',
      adminCenter: '',
      isVisible: false,
      communities: []
    }
  },
  methods: {
    async getCommunities(name) {
      if (this.disabled === false) {
        const response = await fetch(`/api/communities/name`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          },
          body: JSON.stringify({ name: name }),
        });

        try {
          if (response.ok) {
            this.communities = await response.json();
          }
        } catch (err) {
          console.err(err);

        }
      }
    },
    filterInput(input) {
      let filteredInput = '';
      const regex = new RegExp(/^[а-яА-ЯёЁіїґєЄʼ\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredInput += char;
        }
      }
      this.name = Capitalize(filteredInput);
    },
    onCapitalize(word) {
      return Capitalize(word);
    },
    onClose() {
      this.isVisible = false;
    },
    onSelected(id, name, region_id, region, adminCenter) {
      this.isVisible = false;
      this.$emit('input', id);
      this.name = name;
      this.$emit('selected', region_id, name, adminCenter, region);
      this.region = region;
      this.adminCenter = adminCenter;
    },
    setFocus() {
      this.$refs.communityList.focus()
    }
  },
  mounted() {
  }
}
</script>

<template>
  <div class="sm:col-span-2">
    <div class="">
      <label class="block">
        <span class="after:content-['*'] after:ml-0.5 after:text-[#B20000] block text-sm font-medium text-slate-700">
          Територіальна громада
        </span>
      </label>
      <div class="relative mt-2.5">
        <input
          id="community"
          ref="community"
          @focus="isVisible = true"
          @keyup="filterInput(name);getCommunities(onCapitalize(name))"
          @click="isVisible = true"
          @change="name=='' ? region='': region"
          @keydown.down="setFocus"
          placeholder="Почніть ввод"
          :disabled="disabled"
          :readonly="readonly"
          type="text"
          v-model="name"
          class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
        />
        <button
          type="button"
          class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
          :disabled="disabled"
          @click="isVisible = !isVisible; getCommunities(name)"
        >
          <i
            :class="isVisible ? 'fa fa-angle-up text-[#B20000]':'fa fa-angle-down text-gray-400 hover:text-[#B20000]'"
            aria-hidden="true"
          ></i>
        </button>
        <div
          class="list absolute z-[1] max-h-[300px] max-w-[84dvw] md:max-w-[550px] bg-white overflow-auto"
          ref="communityList"
        >
          <div
            v-for="item of communities"
            :key="item.id"
            class="flex  w-full bg-white focus:bg-gray-100"
            @mouseenter="isVisible = true"
            @keydown.enter="onSelected(item.id, item.name, item.region_id, item.region)"
            tabindex="0"
          >
            <p
              class="text-gray-400 leading-tight cursor-pointer px-3 py-2 w-full hover:bg-gray-100 md:max-w-[670px]"
              :class="isVisible ? '' : 'hidden'"
              @click="onSelected(item.id, item.name,item.region_id, item.region, item.adminCenter)"
            >{{ `${item.name}  ${item.adminCenter} ${item.region} область ${item.district} р-н`}} </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <span class="text-xs text-sky-600 pl-3">{{ region ? `${region} обл.   ${adminCenter}` :''}}</span> -->
    <span class="text-xs text-sky-600 p-3"></span>
  </div>
</template>

<style scoped>
.list {
  font-size: 13px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>