<script>
import UsersList from '../components/users/UsersList.vue';

export default {
  components: { UsersList },
};
</script>

<template>
  <div id="users">
    <UsersList />
  </div>

</template>
