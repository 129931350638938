
<script>
import Regions from '../components/communities/Regions.vue'
export default {
  components: { Regions }
}
</script>

<template>
  <div class="overflow-auto">
    <Regions />
  </div>
</template>