<script>
import Notification from '../components/Notification.vue';

export default {
  components: { Notification },
};
</script>

<template>
  <div id="notification">
    <Notification />
  </div>

</template>
