<script>
export default {
  props: {
    title: String,
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    options: { type: Array, default: () => [] },
    isDescription: { type: Boolean, default: false },
    class: String,
  },
  data() {
    return {
      assistans_type: [], // Массив объектов { id, description }
    };
  },
  methods: {
    onChecked(id, description, isChecked) {
      if (isChecked) {
        // Добавляем объект в массив
        this.assistans_type.push({ id, description });
      } else {
        // Удаляем объект из массива
        this.assistans_type = this.assistans_type.filter(
          (item) => item.id !== id
        );
      }

      // Эмитим данные в родительский компонент
      this.$emit("checked", this.assistans_type);
    },
  },
};
</script>

<template>
  <div class="sm:col-span-2 mt-4">
    <legend
      class="block"
      v-if="title"
    >
      <span
        class="after:content-['*'] after:ml-0.5 after:text-[#b20000] block text-sm font-medium text-slate-700"
        :class="isDescription ? 'block pb-2.5 w-full opacity-80 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#B20000] peer' : ''"
      >
        {{ title }}
      </span>
      <p
        v-if="isDescription"
        class="pt-1 opacity-35"
      >Прохання обрати напрямки, за якими ви та ваша установа, організація чи ви самостійно
        надаєте допомогу. <span class="font-bold">Можна обрати декілька зі списку</span>, звернення автоматично
        розподілятимуться на підставі співпадінь запиту та вашого функціоналу.</p>
    </legend>
    <div
      :class="this.class"
      class="py-3.5 opacity-65"
    >
      <div
        v-for="item of options"
        :key="item.id"
        class="py-1.5"
      >
        <div class="relative flex gap-x-3">
          <div class="flex h-6 items-center">
            <input
              :id="item.description"
              type="checkbox"
              :disabled="disabled"
              :readonly="readonly"
              @change="onChecked(item.id, item.description, $event.target.checked)"
              class="cursor-pointer h-5 w-5 rounded border-[#b20000] text-[#b20000] focus:ring-white"
            />
          </div>
          <div class="text-sm leading-6">
            <label
              :for="item.description"
              class="cursor-pointer font-medium text-gray-900"
            >
              {{ item.description }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
