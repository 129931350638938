<script>

import Header from '../components/about/Header.vue';
import Footer from '../components/about/Footer.vue';
import AboutProject from '../components/about/AboutProject.vue';
import Partners from '../components/about/Partners.vue';
import Problems from '../components/about/Problems.vue';
import SoMath from '../components/about/SoMath.vue';
import Help from '../components/about/Help.vue';
import Activities from '../components/about/Activities.vue';
import WeReport from '../components/about/WeReport.vue';
import Media from '../components/about/Media.vue';
import Team from '../components/about/Team.vue';
import SearchVolonter from '../components/about/SearchVolonter.vue';
import Contacts from '../components/about/Contacts.vue';
import ContactsPoltava from '../components/about/ContactsPoltava.vue';
import Coordiners from '../components/about/Coordiners.vue';

export default {

  components: {
    Header, Problems, SoMath, WeReport, Media, Team,
    Footer, AboutProject, Partners, Help, Activities,
    SearchVolonter, Contacts, ContactsPoltava, Coordiners
  },

}
</script>
<template>
  <div class="">
    <Header />
    <main class="page container mx-auto max-w-[1512px] px-2.5">
      <AboutProject />
      <Partners />
      <Problems />
      <Help />
      <Activities />
      <WeReport />
      <Media />
    </main>
    <Team />
    <main class="container mx-auto max-w-[1512px] px-2.5">
      <Coordiners />
      <SearchVolonter />
      <Contacts />
      <ContactsPoltava />
    </main>
    <main class="container mx-auto max-w-[1512px] px-2.5">
      <AboutProject />
      <SoMath />
    </main>
    <Footer />
  </div>
</template>
<style scoped>
* {
  font-family: 'Liberaton Sans', sans-serif;
  color: #36393f;
  background-color: #fbfbfb;
}
@media (max-width: 767px) {
  .page {
    padding-top: 50px;
  }
}
</style>