
<script>
import CommunityAll from '../components/communities/CommunityAll.vue'
export default {
  components: { CommunityAll }
}
</script>

<template>
  <div class="overflow-auto">
    <CommunityAll />
  </div>
</template>