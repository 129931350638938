
<script>
import AppealAdd from '../components/appeal/_add.vue'
export default {
  name: 'appealHistoryEdit',
  components: { AppealAdd }
}
</script>

<template>
  <div class="overflow-auto">
    <AppealAdd />
  </div>
</template>